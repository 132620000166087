import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Position } from '../../types/entities';

import { PositionState, initialPositionState } from './state';

export interface PositionCompleteAction {
  coveredOptionId: string;
  optionRealizedPnl: number;
  coverRealizedPnl: number;
}

const positionSlice = createSlice({
  name: 'position',
  initialState: initialPositionState,
  reducers: {
    reset: () => initialPositionState,
    loading: (state: PositionState, action: PayloadAction<boolean>) => {
      state.error = undefined;
      state.loading = action.payload;
      state.loaded = !action.payload;
    },
    success: (state: PositionState, action: PayloadAction<Position[]>) => {
      state.error = undefined;
      state.loading = false;
      state.loaded = true;
      state.positions = action.payload;
    },
    fail: (state: PositionState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.loaded = true;
      state.positions = [];
    },
    update: (state: PositionState, action: PayloadAction<Position>) => {
      const dbEntry = state.positions.find((x) => x.id === action.payload.id);
      if (!!dbEntry) {
        const index = state.positions.indexOf(dbEntry);
        state.positions[index] = action.payload;
      }

      state.loading = false;
      state.loaded = true;
    }
  }
});

const { actions, reducer } = positionSlice;
export const { reset, loading, success, fail, update } = actions;
export default reducer;
