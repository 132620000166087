import { FunctionComponent } from 'react';

import { Box, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import dayjs from 'dayjs';

import { GRAY_COLOR, INDICATOR_GREEN_COLOR, INDICATOR_RED_COLOR } from '../../../assets/colors';
import { PositionGroupLog } from '../../../types/entities';

interface Props {
  logs: PositionGroupLog[];
}

const PositionLogTable: FunctionComponent<Props> = ({ logs }: Props) => {
  const header = (
    <TableHead>
      <TableRow>
        <TableCell title="Time">Logged</TableCell>
        <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
          Size
        </TableCell>
        <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
          Avg. Price
        </TableCell>
        <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
          Avg +/-
        </TableCell>
        <TableCell align="right">Unrlz PnL</TableCell>
        <TableCell align="right">Rlz PnL</TableCell>
        <TableCell align="right">MKTV</TableCell>
        <TableCell>Message</TableCell>
      </TableRow>
    </TableHead>
  );

  const rows = logs.map((x, i) => {
    const loggedDt = new Date(x.logged);
    const formattedLoggedDt = dayjs(loggedDt).format('MM/DD HH:mm A');

    let unrealizedPnlColor = 'inherit';
    if (x.unrealizedPnl > 0) {
      unrealizedPnlColor = INDICATOR_GREEN_COLOR;
    } else if (x.unrealizedPnl < 0) {
      unrealizedPnlColor = INDICATOR_RED_COLOR;
    }

    let realizedPnlColor = 'inherit';
    if (x.realizedPnl > 0) {
      realizedPnlColor = INDICATOR_GREEN_COLOR;
    } else if (x.realizedPnl < 0) {
      realizedPnlColor = INDICATOR_RED_COLOR;
    }

    const message = x.error ? `${x.message}: ${x.error}` : x.message;
    const msgColor = x.error ? INDICATOR_RED_COLOR : 'inherit';

    const avgPriceChangePct = x.avgPriceChangePct ? `${x.avgPriceChangePct.toFixed(2)}%` : '-';
    const avgPriceChangeColor = x.avgPriceChangePct ? (x.avgPriceChangePct > 0 ? INDICATOR_GREEN_COLOR : INDICATOR_RED_COLOR) : 'inherit';

    return (
      <TableRow key={i}>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{formattedLoggedDt}</TableCell>
        <TableCell align="center">{x.sumSize ?? '-'}</TableCell>
        <TableCell align="right">{x.averagePrice ? x.averagePrice.toFixed(2) : '-'}</TableCell>
        <TableCell align="right" sx={{ color: avgPriceChangeColor }}>
          {avgPriceChangePct}
        </TableCell>
        <TableCell align="right" sx={{ color: unrealizedPnlColor }}>
          ${x.unrealizedPnl.toFixed(0)}
        </TableCell>
        <TableCell align="right" sx={{ color: realizedPnlColor }}>
          ${x.realizedPnl.toFixed(0)}
        </TableCell>
        <TableCell align="right" sx={{ color: GRAY_COLOR }}>
          ${x.marketValue.toFixed(0)}
        </TableCell>
        <TableCell align="left" title="Message" sx={{ color: msgColor, whiteSpace: 'nowrap' }}>
          {message}
        </TableCell>
      </TableRow>
    );
  });

  if (logs.length === 0) {
    return (
      <Box>
        <Typography variant="body2" component="div" sx={{ m: 2 }}>
          No log records found..
        </Typography>
        <Divider />
      </Box>
    );
  }

  return (
    <TableContainer>
      <Table size="small">
        {header}
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default PositionLogTable;
