import { Position } from '../../types/entities';

export interface DashboardState {
  loading: boolean;
  loaded: boolean;
  error?: string;
  selected?: Position | undefined;
}

export const initialDashboardState: DashboardState = {
  loading: false,
  loaded: false,
  error: undefined
};
