import { FunctionComponent, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { createPositionGroupAsync, removePositionGroupAsync, updatePositionGroupAsync } from '../../store/position-group/service';
import { PositionGroup } from '../../types/entities';
import { PositionGroupType } from '../../types/enums';
import DialogCloseIconButton from '../ui/DialogCloseIconButton';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  group?: PositionGroup;
}

const PositionGroupDialog: FunctionComponent<Props> = ({ isOpen, onClose, group }: Props) => {
  const { loaded, error } = useAppSelector((gs) => gs.positionGroupState);
  const [actionPressed, setActionPressed] = useState(false);
  const isNewGroup = !group;
  const now = new Date();

  const groupTemplate: PositionGroup = {
    title: '',
    type: PositionGroupType.General,
    created: now,
    unrealizedPnl: 0,
    realizedPnl: 0,
    sortOrder: 0,
    inactive: false,
    expanded: true,
    useSmallProfit: false,
    smallProfitDelayHours: 0,
    smallProfit: 0,
    useTimeOut: false,
    timeOutDelayHours: 0,
    logs: [],
    useAveraging: false,
    maxSize: 10,
    avgUpChangePct: 1,
    avgDownChangePct: 1,
    avgSizeChange: 1,
    initialSize: 4,
    initialMarketPrice: 0,
    trendBiasPct: 50,
    volatilityPct: 1,
    avgMultiplier: 1,
    avgMaxTurns: 0
  };

  const [groupType, setGroupType] = useState<PositionGroupType>(group?.type || groupTemplate.type);
  const [title, setTitle] = useState<string>(group?.title || groupTemplate.title);
  const [description, setDescription] = useState<string>(group?.description || groupTemplate.description || '');

  useEffect(() => {
    if (isOpen && group) {
      setTitle(group.title);
      setDescription(group.description || '');
    }
  }, [group, isOpen]);

  const handleCloseDialog = () => {
    setActionPressed(false);
    onClose();
  };

  // auto-close on save successfully
  useEffect(() => {
    if (actionPressed && loaded && !error) {
      handleCloseDialog();
    }
  }, [actionPressed, loaded, error]);

  const dispatch = useAppDispatch();

  const handleSaveGroup = () => {
    setActionPressed(true);
    if (isNewGroup) {
      const copy = {
        ...groupTemplate,
        type: groupType,
        title,
        description
      };
      dispatch(createPositionGroupAsync(copy));
    } else {
      const copy = {
        ...group,
        type: groupType,
        title,
        description
      };
      dispatch(updatePositionGroupAsync(copy));
    }
  };

  const handleRemoveGroup = () => {
    setActionPressed(true);
    if (isNewGroup) {
      handleCloseDialog();
    } else {
      dispatch(removePositionGroupAsync(group));
    }
  };

  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm" onClose={onClose}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Box>
          <Typography variant="h6">Position Group</Typography>
          <Typography>{title ? <span>{title}</span> : <i>Title Placeholder</i>}</Typography>
        </Box>
      </DialogTitle>
      <DialogCloseIconButton onClose={onClose} />
      <DialogContent sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
        <FormControl fullWidth>
          <InputLabel id="month-label" size="small">
            Type
          </InputLabel>
          <Select
            name="group-label"
            labelId="group-label"
            value={groupType}
            label="Type"
            onChange={(e) => setGroupType(Number(e.target.value))}
            size="small"
          >
            <MenuItem value={PositionGroupType.General}>General</MenuItem>
            <MenuItem value={PositionGroupType.Averaging}>Averaging</MenuItem>
          </Select>
        </FormControl>

        <TextField
          name="title"
          size="small"
          label="Title"
          variant="outlined"
          type="text"
          defaultValue={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          name="description"
          size="small"
          label="Description"
          variant="outlined"
          type="text"
          multiline
          rows={2}
          defaultValue={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        {!isNewGroup && (
          <Box sx={{ display: 'flex', gap: 1, flexGrow: 1, justifyContent: 'flex-start' }}>
            <Button variant="outlined" color="error" onClick={handleRemoveGroup}>
              Remove
            </Button>
          </Box>
        )}
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary" onClick={handleSaveGroup}>
            Save
          </Button>
          <Button onClick={handleCloseDialog} variant="outlined">
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default PositionGroupDialog;
