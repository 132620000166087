import { Portfolio } from '../../types/entities';

export interface PortfolioState {
  loading: boolean;
  initialLoaded: boolean;
  loaded: boolean;
  error?: string | undefined;
  portfolios: Portfolio[];
}

export const initialState: PortfolioState = {
  loading: false,
  initialLoaded: false,
  loaded: false,
  portfolios: []
};
