import { FunctionComponent, useEffect, useState } from 'react';

import { Box, Button, Dialog, DialogActions, DialogTitle, Divider, Typography } from '@mui/material';

import ContractDetailsPanel from '../../../../components/panels/ContractDetailsPicker/ContractDetailsPanel';
import DialogCloseIconButton from '../../../../components/ui/DialogCloseIconButton';
import Spinner from '../../../../components/ui/Spinner';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { addPortfolioContractAsync } from '../../../../store/portfolio/service';
import { ContractDetails, Portfolio } from '../../../../types/entities';
import { mapToDto } from '../../../../utils/contract-details-utils';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  portfolio: Portfolio;
}

const AddPortfolioContractDialog: FunctionComponent<Props> = ({ isOpen, onClose, portfolio }: Props) => {
  const { loading } = useAppSelector((gs) => gs.portfolioState);
  const [action, setAction] = useState(false);
  const dispatch = useAppDispatch();

  const handleContractSelected = (details: ContractDetails | undefined) => {
    if (details) {
      const exists = portfolio.contracts.find((x) => x.contract.conId === details.contract.conId);
      if (exists) {
        onClose(); // ignore contract which already exists
      } else {
        const dtoReady = mapToDto(details);
        dispatch(addPortfolioContractAsync(portfolio, dtoReady));
        setAction(true);
      }
    }
  };

  useEffect(() => {
    if (action && !loading) {
      onClose();
    }
  }, [loading, action]);

  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm" onClose={onClose}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Box>
          <Typography variant="h6">{portfolio.name}</Typography>
          <Typography>Add Contract</Typography>
        </Box>
      </DialogTitle>
      <DialogCloseIconButton onClose={onClose} />

      <ContractDetailsPanel onSelectionChanged={handleContractSelected} as="box" />

      <Divider />

      <DialogActions sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
          <Button onClick={onClose} variant="contained">
            Cancel
          </Button>
        </Box>
      </DialogActions>

      <Spinner loading={loading} />
    </Dialog>
  );
};

export default AddPortfolioContractDialog;
