import { useMemo } from 'react';

import { useAppSelector } from '../../store/hooks';
import { Security } from '../../types/entities';
import { SecurityCategory } from '../../types/enums';

const useCategorySecurities = () => {
  const { securities } = useAppSelector((gs) => gs.securityState);
  const { selectedSecurityCategory: selectedSecCategory } = useAppSelector((gs) => gs.userSettingsState);

  const sortByName = (a: Security, b: Security) => (a.name > b.name ? 1 : -1);
  const orderedSecurities = useMemo(() => {
    return securities.slice().sort(sortByName);
  }, [securities]);

  const filteredSecurities = useMemo(() => {
    switch (selectedSecCategory) {
      case SecurityCategory.Futures:
        return orderedSecurities.filter((x) => x.category === SecurityCategory.Futures);
      case SecurityCategory.MicroFutures:
        return orderedSecurities.filter((x) => x.category === SecurityCategory.MicroFutures);
      case SecurityCategory.Stock:
        return orderedSecurities.filter((x) => x.category === SecurityCategory.Stock);
      default:
        return [];
    }
  }, [orderedSecurities, selectedSecCategory]);

  return filteredSecurities;
};

export default useCategorySecurities;
