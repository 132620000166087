import { FunctionComponent, MutableRefObject, forwardRef, useCallback, useRef, useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface ConfirmOptions {
  title?: string;
  description?: string;
  yesButtonText?: string;
  noButtonText?: string;
}

export interface ConfirmProps {
  open?: boolean;
  setOpen?: ((val: boolean) => void) | undefined;
  openFnRef?: MutableRefObject<((value: boolean) => void) | undefined>;
  onResultRef?: MutableRefObject<((value: boolean) => void) | undefined>;
}

type Props = ConfirmProps & ConfirmOptions;

export const Confirm: FunctionComponent<Props> = ({
  open = false,
  setOpen,
  title,
  description,
  yesButtonText,
  noButtonText,
  onResultRef
}: Props) => {
  const yes = useCallback(() => {
    if (onResultRef?.current) {
      onResultRef.current(true);
      handleClose();
    }
  }, [onResultRef]);

  const no = useCallback(() => {
    if (onResultRef?.current) {
      onResultRef.current(false);
      handleClose();
    }
  }, [onResultRef]);

  const handleClose = () => {
    if (setOpen) {
      setOpen(false);
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title || 'Please Confirm'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{description || 'Are you sure?'}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={yes}>
          {yesButtonText || 'Yes'}
        </Button>
        <Button variant="outlined" onClick={no}>
          {noButtonText || 'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const useConfirm = (opts?: ConfirmOptions): [(question?: string) => Promise<boolean>, typeof Confirm] => {
  const promiseRef = useRef<Promise<boolean>>();
  const [open, setOpen] = useState(false);
  const [description, setDescripion] = useState<string | undefined>();
  const resolveRef = useRef<(value: boolean) => void>();

  const resetPromise = () => {
    promiseRef.current = new Promise((res) => {
      resolveRef.current = res;
    });
  };

  const confirm = (question?: string) => {
    resetPromise();
    if (question) {
      setDescripion(question);
    }
    setOpen(true);
    return promiseRef.current as Promise<boolean>;
  };

  const ConfirmDialog = () => <Confirm {...opts} description={description} open={open} setOpen={setOpen} onResultRef={resolveRef} />;

  return [confirm, ConfirmDialog];
};
