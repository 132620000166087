import { FunctionComponent } from 'react';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import { useAppSelector } from '../../../store/hooks';
import { Position } from '../../../types/entities';
import { setThousenSeparator } from '../../../utils/currency-utils';

interface Props {
  positions: Position[];
}
export const CashOverviewPanel: FunctionComponent<Props> = ({ positions }: Props) => {
  const accountInfoState = useAppSelector((gs) => gs.accountInfoState);
  const cashBalance = accountInfoState.accountInfo?.cashBalanceUsd;

  const getMarketValueGroupAmount = () => {
    const filteredList = positions.filter((x) => x.secType !== 'FUT' && x.secType !== 'STK' && x.size !== 0);
    if (filteredList.length === 0) {
      return 0;
    }
    return filteredList
      .map((x) => x.marketValue)
      .reduce((total: number, marketValue: number) => {
        return total + marketValue;
      });
  };

  const totalBalance = (cashBalance ?? 0) + getMarketValueGroupAmount();

  const formatedCashBalance = totalBalance ? `$${setThousenSeparator(Math.round(totalBalance))}` : '-';
  const availableFunds = accountInfoState.accountInfo?.availableFundsUsd;
  const formatedAvailableFunds = availableFunds ? `$${setThousenSeparator(Math.round(availableFunds))}` : '-';

  const theme = useTheme();
  const isXsViewPort = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <Box sx={{ textAlign: 'right' }}>
      <Typography variant={isXsViewPort ? 'body1' : 'h6'}>
        Cash Balance: <span>{formatedCashBalance}</span>
      </Typography>
      <Typography variant="caption">
        Available Funds: <span>{formatedAvailableFunds}</span>
      </Typography>
    </Box>
  );
};
