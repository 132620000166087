import { FunctionComponent, useEffect } from 'react';

import { Box, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import ContractDetailsPicker from '../../../components/panels/ContractDetailsPicker';
import WatchBoard from '../../../components/panels/Watchboard';
import GutterBox from '../../../components/ui/GutterBox';
import Spinner from '../../../components/ui/Spinner';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useSecurityContractStore } from '../../../store/security-contract/hooks';
import { setFavoritesPanelCollapsed, setSecurityPanelCollapsed, setSelectedContractDetails } from '../../../store/user-settings/reducer';
import { ContractDetails } from '../../../types/entities';

const BrowseTab: FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const symbolParams = searchParams.get('symbol') ?? '';
  const conIdParams = searchParams.get('conid') ?? '';

  const { securities } = useAppSelector((gs) => gs.securityState);
  const security = securities.find((x) => x.symbol === symbolParams);

  const { contracts, loaded } = useSecurityContractStore(security, true);
  const selectedContractFromSearchParams = contracts.find((x) => x.contract.conId === Number(conIdParams));
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (selectedContractFromSearchParams) {
      dispatch(setSecurityPanelCollapsed(false));
      dispatch(setFavoritesPanelCollapsed(true));
      dispatch(setSelectedContractDetails(selectedContractFromSearchParams));
    }
  }, [selectedContractFromSearchParams]);

  const { selectedContractDetails } = useAppSelector((gs) => gs.userSettingsState);

  const handleSelectionChanged = (details: ContractDetails | undefined) => {
    dispatch(setSelectedContractDetails(details));
  };

  if (security && conIdParams && !loaded) {
    return <Spinner loading={true} />;
  }

  return (
    <div>
      <GutterBox sx={{ my: 3 }}>
        <Typography variant="h5">Browse</Typography>
      </GutterBox>
      <ContractDetailsPicker selected={selectedContractDetails} onSelectionChanged={handleSelectionChanged} />
      {selectedContractDetails && (
        <Box sx={{ mt: 2 }}>
          <WatchBoard details={selectedContractDetails} />
        </Box>
      )}
    </div>
  );
};

export default BrowseTab;
