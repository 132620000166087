import React, { FunctionComponent } from 'react';

import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { INDICATOR_GREEN_COLOR, INDICATOR_RED_COLOR } from '../../../../../../assets/colors';
import raceCarGreenIcon from '../../../../../../assets/svg/race-car-green.svg';
import raceCarRedIcon from '../../../../../../assets/svg/race-car-red.svg';
import { useConfirm } from '../../../../../../components/hooks/useConfirm';
import { useAppDispatch } from '../../../../../../store/hooks';
import { placeOptionExitOrderAsync } from '../../../../../../store/order-option/service';
import { Position } from '../../../../../../types/entities';
import { formatNum, setDecimalSpaces, setThousenSeparator } from '../../../../../../utils/currency-utils';
import { createOptionExitOrder } from '../../../../../../utils/position-utils';

interface Props {
  position: Position;
}

const OptionPositionDetails: FunctionComponent<Props> = ({ position }: Props) => {
  const [confirm, ConfirmDialog] = useConfirm();
  const coveredOption = position;
  // const isSellOption = coveredOption.orderAction !== 'BUY';
  const { right: optionRight, orderAction: optionAction, localSymbol: optionSymbol } = coveredOption;
  let optionIcon: string;
  if (optionAction === 'BUY') {
    optionIcon = optionRight === 'CALL' ? raceCarGreenIcon : raceCarRedIcon;
  } else {
    optionIcon = optionRight === 'PUT' ? raceCarGreenIcon : raceCarRedIcon;
  }

  const optionSize = position.size ?? 0;
  //const coverSize = position.coverPosition?.positionSize ?? 0;
  // if (optionSize === 0 && coverSize === 0) {
  //   return (
  //     <Typography sx={{ p: 1 }}>
  //       <small>No Positions..</small>
  //     </Typography>
  //   );
  // }

  // const coverPrice = position.coverPosition?.marketPrice;
  // const coverPriceFormatted = coverPrice ? `$${formatNum(coverPrice)}` : '-';

  const optionMarketValue = position.marketValue;
  const optionMarketValueFormatted = optionMarketValue ? `$${setThousenSeparator(optionMarketValue.toFixed(0))}` : '-';
  let optionMarketValueColor = 'Inherit';
  if (optionMarketValue && optionMarketValue !== 0) {
    optionMarketValueColor = optionMarketValue > 0 ? INDICATOR_GREEN_COLOR : INDICATOR_RED_COLOR;
  }

  const optionUnrealizedPnl = position.unrealizedPnl;
  const optionUnrealizedPnlFormatted = optionUnrealizedPnl ? `$${setThousenSeparator(optionUnrealizedPnl.toFixed(0))}` : '-';
  let optionUnrealizedPnlColor = 'Inherit';
  if (optionUnrealizedPnl && optionUnrealizedPnl !== 0) {
    optionUnrealizedPnlColor = optionUnrealizedPnl > 0 ? INDICATOR_GREEN_COLOR : INDICATOR_RED_COLOR;
  }

  let optionPremium: number | undefined;
  if (!!position) {
    optionPremium = position.marketValue - position.unrealizedPnl;
    optionPremium *= -1;
  }
  const optionPremiumFormatted = optionPremium ? `$${setThousenSeparator(optionPremium.toFixed(0))}` : '-';
  let optionPremiumColor = 'Inherit';
  if (optionPremium && optionPremium !== 0) {
    optionPremiumColor = optionPremium > 0 ? INDICATOR_GREEN_COLOR : INDICATOR_RED_COLOR;
  }

  const optionRealizedPnl = position?.realizedPnl;
  const optionRealizedPnlFormatted = optionRealizedPnl ? `$${setThousenSeparator(optionRealizedPnl.toFixed(0))}` : '-';
  let optionRealizedPnlColor = 'Inherit';
  if (optionRealizedPnl && optionRealizedPnl !== 0) {
    optionRealizedPnlColor = optionRealizedPnl > 0 ? INDICATOR_GREEN_COLOR : INDICATOR_RED_COLOR;
  }

  let optPnlPctFormatted = '-';
  let optPnlPctColor = 'Inherit';
  const optProfitPct = position.unrealizedProfitPct;
  const optLossPct = position.unrealizedLossPct;
  if (optProfitPct && optProfitPct > 0) {
    optPnlPctFormatted = `${setDecimalSpaces(optProfitPct, 0)}%`;
    optPnlPctColor = INDICATOR_GREEN_COLOR;
  } else if (optLossPct && optLossPct > 0) {
    optPnlPctFormatted = `${setDecimalSpaces(optLossPct, 0)}%`;
    optPnlPctColor = INDICATOR_RED_COLOR;
  }

  const currentPriceFormated = !!position.marketPrice ? `${formatNum(position.marketPrice)}` : '-';

  const dispatch = useAppDispatch();

  const handleCloseClick = async () => {
    if (await confirm('Are you sure you want to close this position?')) {
      const exitOrder = createOptionExitOrder(position, 'MKT');
      dispatch(placeOptionExitOrderAsync(exitOrder));
    }
  };

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ whiteSpace: 'nowrap' }}>
              <TableCell align="center" sx={{ width: '32px' }}>
                Vehicle
              </TableCell>
              <TableCell>Local Symbol</TableCell>
              <TableCell align="center">Action</TableCell>
              <TableCell align="center">Right</TableCell>
              <TableCell align="right">Size</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Premium</TableCell>
              <TableCell align="right">Unrlz. PnL</TableCell>
              <TableCell align="right">Market Value</TableCell>
              <TableCell align="right">PnL %</TableCell>
              <TableCell align="right">Rlz. PnL</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ whiteSpace: 'nowrap' }}>
              <TableCell align="center">
                <img src={optionIcon} width="24px" title={optionSymbol} />
              </TableCell>
              <TableCell>{optionSymbol}</TableCell>
              <TableCell align="center">{coveredOption.orderAction}</TableCell>
              <TableCell align="center">{optionRight}</TableCell>
              <TableCell align="right">{optionSize}</TableCell>
              <TableCell align="right">{currentPriceFormated}</TableCell>
              <TableCell align="right" sx={{ color: optionPremiumColor }}>
                {optionPremiumFormatted}
              </TableCell>
              <TableCell align="right" sx={{ color: optionUnrealizedPnlColor }}>
                {optionUnrealizedPnlFormatted}
              </TableCell>
              <TableCell align="right" sx={{ color: optionMarketValueColor }}>
                {optionMarketValueFormatted}
              </TableCell>
              <TableCell align="right" sx={{ color: optPnlPctColor }}>
                {optPnlPctFormatted}
              </TableCell>
              <TableCell align="right" valign="middle" sx={{ color: optionRealizedPnlColor }}>
                <span>{optionRealizedPnlFormatted}</span>
              </TableCell>
              <TableCell align="right">
                <Button size="small" color="error" variant="contained" onClick={handleCloseClick} disabled={optionSize === 0}>
                  Close Position
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmDialog />
    </>
  );
};

export default OptionPositionDetails;
