import { FunctionComponent } from 'react';

import { Box } from '@mui/material';

import { RESEARCH_PAGE_DAILY_CHART_ID, RESEARCH_PAGE_HOUR_CHART_ID } from '../../../assets/constants';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  setDailyGraphCollapsed,
  setHourGraphCollapsed,
  setIndicatorPanelCollapsed,
  setTrendLinesCollapsed
} from '../../../store/user-settings/reducer';
import { ContractDetails, OrderAction } from '../../../types/entities';
import { BarSize } from '../../../types/enums';
import BarChartPanel from '../../panels/BarChartPanel';
import MarketDataPanel from '../../panels/MarketDataPanel';
import TrendLinePanel from '../../panels/TrendLinePanel';
import AccordionWrapper from '../../ui/AccordionWrapper';
import NewsPanel from '../NewsPanel';

import AddToPortfolioButton from './AddToPortfolioButton';
import GotoOrderButton from './GotoOrderButton';
import WatchBoardHeader from './WatchBoardHeader';

interface Props {
  details: ContractDetails;
  entryPrice?: number | undefined;
  strikePrice?: number | undefined;
  action?: OrderAction | undefined;
}
const WatchBoard: FunctionComponent<Props> = ({ details, entryPrice, strikePrice, action }: Props) => {
  const dispatch = useAppDispatch();
  const userSettingsState = useAppSelector((gs) => gs.userSettingsState);
  const { indicatorPanelCollapsed, hourGraphCollapsed, dailyGraphCollapsed } = userSettingsState;
  const toggleIndicatorPanelCollapsed = (_e: React.SyntheticEvent<Element, Event>, expanded: boolean) =>
    dispatch(setIndicatorPanelCollapsed(!expanded));
  const toggleDailyGraphCollapsed = (_e: React.SyntheticEvent<Element, Event>, expanded: boolean) =>
    dispatch(setDailyGraphCollapsed(!expanded));
  const toggleHourGraphCollapsed = (_e: React.SyntheticEvent<Element, Event>, expanded: boolean) =>
    dispatch(setHourGraphCollapsed(!expanded));
  const { trendLinesCollapsed } = useAppSelector((gs) => gs.userSettingsState);
  const toggleTrendLinesCollapsed = () => dispatch(setTrendLinesCollapsed(!trendLinesCollapsed));

  const contract = details?.contract;
  const securityState = useAppSelector((gs) => gs.securityState);
  const securities = securityState.securities;
  const security = securities.find((x) => x.symbol === contract.symbol);

  return (
    <Box>
      {security && <WatchBoardHeader security={security} details={details} />}
      <NewsPanel security={security} showTitle={false} />
      <AccordionWrapper title="Indicators" collapsed={indicatorPanelCollapsed} onChange={toggleIndicatorPanelCollapsed}>
        <Box sx={{ mx: 2 }}>
          <MarketDataPanel contract={contract} />
        </Box>
      </AccordionWrapper>
      <AccordionWrapper title="Daily Barchart" collapsed={dailyGraphCollapsed} onChange={toggleDailyGraphCollapsed}>
        <BarChartPanel
          barChartId={RESEARCH_PAGE_DAILY_CHART_ID}
          conId={contract.conId}
          title={contract.localSymbol}
          exchange={contract.exchange}
          initialBarSize={BarSize._1_DAY}
          initialDuration={100}
          entryPrice={entryPrice}
          strikePrice={strikePrice}
          action={action}
        />
      </AccordionWrapper>
      <AccordionWrapper title="Hour Barchart" collapsed={hourGraphCollapsed} onChange={toggleHourGraphCollapsed}>
        <BarChartPanel
          barChartId={RESEARCH_PAGE_HOUR_CHART_ID}
          conId={contract.conId}
          title={contract.localSymbol}
          exchange={contract.exchange}
          initialBarSize={BarSize._1_HR}
          initialDuration={5}
          entryPrice={entryPrice}
          strikePrice={strikePrice}
          action={action}
        />
      </AccordionWrapper>
      <AccordionWrapper title="Trend Lines" collapsed={trendLinesCollapsed} onChange={toggleTrendLinesCollapsed}>
        <TrendLinePanel conId={contract.conId} />
      </AccordionWrapper>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
        <AddToPortfolioButton details={details} />
        <GotoOrderButton symbol={details.contract.symbol} />
      </Box>
    </Box>
  );
};

export default WatchBoard;
