import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { Box, Skeleton, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { fetchScheduleLog } from '../../../api';
import { INDICATOR_YELLOW_COLOR } from '../../../assets/colors';
import { Position, ScheduleLog } from '../../../types/entities';
import { getDaysAppart, getFormattedExpireDateWithDuration } from '../../../utils/datetime-utils';

import PositionLogTable from './PositionLogTable';

interface Props {
  position: Position;
}

const TimelinePanel: FunctionComponent<Props> = ({ position }: Props) => {
  const [loading, setLoading] = useState(false);
  const [scheduleLog, setScheduleLog] = useState<ScheduleLog[]>([]);

  const fetchScheduleLogAsync = async () => {
    setLoading(true);
    try {
      const data = (await fetchScheduleLog(position.id)).data;
      setScheduleLog(data);
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || error.message;
      toast.error(msg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchScheduleLogAsync();
  }, [position]);

  const subTitle = getFormattedExpireDateWithDuration(position.expirationDate);

  const subTitleColor = useMemo(() => {
    const isOption = position.secType === 'FOP' || position.secType === 'OPT';
    if (position.expirationDate && isOption) {
      const expires = new Date(position.expirationDate);
      const daysAppart = getDaysAppart(expires, new Date());
      if (daysAppart < 10) {
        return INDICATOR_YELLOW_COLOR;
      }
    }
    return 'Inherit';
  }, [position.expirationDate]);

  return (
    <Box>
      {subTitle && (
        <Box sx={{ p: 2 }}>
          <Typography component="div" color={subTitleColor} sx={{ mt: 1, typography: { sm: 'body1', xs: 'body2' } }}>
            {subTitle}
          </Typography>
        </Box>
      )}

      <Box sx={{ maxHeight: '50vh' }}>
        {loading && <Skeleton sx={{ mx: 2 }} variant="rounded" animation="wave" height={80} />}
        {!loading && <PositionLogTable events={scheduleLog} />}
      </Box>
    </Box>
  );
};

export default TimelinePanel;
