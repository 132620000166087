import { OptionRight } from '../../types/entities';

import { OptionChainFailedAction, OptionChainLoadingAction, OptionChainSuccessAction } from './actions';

export const getOptionChainId = (
  payload:
    | OptionChainSuccessAction
    | OptionChainFailedAction
    | OptionChainLoadingAction
    | { conId: number; lastTradeDate: string; right: OptionRight }
) => {
  const conId = payload.conId;
  const lastTradeDate = payload.lastTradeDate;
  const right = payload.right;
  return `${conId}-${lastTradeDate}-${right}`;
};
