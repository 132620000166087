import { FunctionComponent, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getTrendLineStateByConId } from '../../../store/trend-line/selectors';
import { removeTrendLineAsync, updateTrendLineAsync } from '../../../store/trend-line/service';
import { ChartTrendLine } from '../../../types/entities';
import { BarSize } from '../../../types/enums';
import { useConfirm } from '../../hooks/useConfirm';

import TrendLineDialog from './TrendLineDialog';

interface Props {
  conId: number;
}

const TrendLinePanel: FunctionComponent<Props> = ({ conId }: Props) => {
  const [selecteTradeLine, setSelectedTradeLine] = useState<ChartTrendLine | undefined>();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const closeDialog = () => setShowEditDialog(false);

  const trendLineState = useAppSelector((gs) => gs.trendLineState);
  const { trendLines } = getTrendLineStateByConId(trendLineState, conId);

  const dispatch = useAppDispatch();

  const [confirm, ConfirmDialog] = useConfirm();
  const handleRemoveClick = async (tradeLine: ChartTrendLine) => {
    if (await confirm('Are you sure you want to delete this trade-line?')) {
      dispatch(removeTrendLineAsync(tradeLine));
    }
  };

  const handleOpenTrendLineDialog = (trendLine?: ChartTrendLine | undefined) => {
    setSelectedTradeLine(trendLine);
    setShowEditDialog(true);
  };

  const handleToggleHideTrendLine = (trendLine: ChartTrendLine) => {
    const payload = {
      ...trendLine,
      hidden: !trendLine.hidden
    };
    dispatch(updateTrendLineAsync(payload));
  };

  const addTrendLinePanel = (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 2 }}>
      <Tooltip title="Add Trend Line" placement="left-start">
        <Button size="small" color="primary" variant="outlined" onClick={() => handleOpenTrendLineDialog()}>
          <AddIcon sx={{ fontSize: '1.25rem' }} />
        </Button>
      </Tooltip>
    </Box>
  );

  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableCell width="70px" align="center">
          Color
        </TableCell>
        <TableCell>Price</TableCell>
        <TableCell>Comment</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>Bar Size</TableCell>
        <TableCell width="70px"></TableCell>
        <TableCell width="70px"></TableCell>
        <TableCell width="70px"></TableCell>
      </TableRow>
    </TableHead>
  );

  const getType = (trendLine: ChartTrendLine) => {
    if (!trendLine.specificOnly) {
      return 'All Sizes';
    }
    switch (trendLine.specificBarSize) {
      case BarSize._15_MIN:
        return '15M';
      case BarSize._1_HR:
        return 'Hour';
      case BarSize._1_DAY:
        return 'Daily';
      default:
        return 'N/A';
    }
  };

  const tableRows = trendLines
    .slice()
    .sort((a, b) => (a.price > b.price ? -1 : 1))
    .map((trendLine) => {
      return (
        <TableRow key={trendLine.id}>
          <TableCell align="center">
            <IconButton
              sx={{
                backgroundColor: trendLine.color,
                height: '16px',
                width: '16px',
                outline: '1px solid white',
                '&:hover': {
                  backgroundColor: trendLine.color
                }
              }}
              aria-label="Trend Line Color"
            />
          </TableCell>
          <TableCell>{trendLine.price}</TableCell>
          <TableCell>{trendLine.name}</TableCell>
          <TableCell>{getType(trendLine)}</TableCell>
          <TableCell align="right">
            <Tooltip title="Toggle Hide/Show Trend Line">
              <IconButton size="small" onClick={() => handleToggleHideTrendLine(trendLine)}>
                {trendLine.hidden === true ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell align="right" sx={{ width: '50px', px: 1 }}>
            <Button size="small" onClick={() => handleOpenTrendLineDialog(trendLine)}>
              Edit
            </Button>
          </TableCell>
          <TableCell align="right" sx={{ px: 1 }}>
            <Button size="small" color="error" onClick={() => handleRemoveClick(trendLine)}>
              Remove
            </Button>
          </TableCell>
        </TableRow>
      );
    });

  const table = (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        {tableHeader}
        <TableBody>{tableRows}</TableBody>
      </Table>
    </TableContainer>
  );

  const showTrendLinesTable = trendLines.length > 0;

  return (
    <>
      <Box sx={{ flexGrow: 1, mb: 1.5 }}>
        {!showTrendLinesTable ? (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography sx={{ px: 2 }} fontSize="smaller" component="div">
              No trend-lines exists..
            </Typography>
            {addTrendLinePanel}
          </Box>
        ) : (
          <>
            {table}
            {addTrendLinePanel}
          </>
        )}
      </Box>
      <TrendLineDialog conId={conId} tradeLine={selecteTradeLine} isOpen={showEditDialog} onClose={closeDialog} />
      <ConfirmDialog />
    </>
  );
};

export default TrendLinePanel;
