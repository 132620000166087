import { FunctionComponent, useEffect, useState } from 'react';

import { Box, Button, Dialog, DialogActions, DialogContent, Tab, Tabs, tabsClasses, Typography } from '@mui/material';

import { useAppDispatch } from '../../../store/hooks';
import { updatePositionGroupAsync } from '../../../store/position-group/service';
import { Position, PositionGroup } from '../../../types/entities';
import { PositionGroupType } from '../../../types/enums';
import DialogCloseIconButton from '../../ui/DialogCloseIconButton';

import AveragingForm from './AveragingForm';
import GroupLogTable from './GroupLogTable';
import GroupScheduleForm from './GroupScheduleForm';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  group: PositionGroup;
  groupPositions?: Position[] | undefined;
}

const GroupScheduleDialog: FunctionComponent<Props> = ({ isOpen, onClose, group, groupPositions }: Props) => {
  const [groupForm, setGroupForm] = useState(group);

  useEffect(() => {
    const copy = {
      ...group,
      logs: [...(group.logs ?? [])]
    };
    setGroupForm(copy);
  }, [group]);

  const dispatch = useAppDispatch();

  const savePositionGroup = () => {
    dispatch(updatePositionGroupAsync(groupForm));
  };

  const handleSaveBtnClick = () => {
    savePositionGroup();
    onClose();
  };

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (_e: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const title = group.type === PositionGroupType.Averaging ? 'Averaging Group' : 'General Group';

  return (
    <Dialog fullWidth open={isOpen} maxWidth="md" onClose={onClose} classes={{ paper: 'group-schedule-dialog__paper' }}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="caption" component="div" sx={{ mt: 0.5 }}>
          {group.title}
        </Typography>
        <DialogCloseIconButton onClose={onClose} />
      </Box>

      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          mx: 1,
          mb: 1,
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 }
          }
        }}
      >
        {group.type === PositionGroupType.Averaging && <Tab label="Averaging" />}
        {group.type === PositionGroupType.General && <Tab label="Time Schedule" />}
        <Tab label="History" />
      </Tabs>

      <DialogContent sx={{ p: 0, maxHeight: '50vh' }}>
        {selectedTab === 0 && group.type === PositionGroupType.Averaging && (
          <AveragingForm p={2} group={groupForm} groupPositions={groupPositions} onChange={setGroupForm} saveGroup={savePositionGroup} />
        )}
        {selectedTab === 0 && group.type === PositionGroupType.General && (
          <GroupScheduleForm p={2} group={groupForm} onChange={setGroupForm} />
        )}
        {selectedTab === 1 && <GroupLogTable logs={group.logs ?? []} />}
      </DialogContent>

      <DialogActions>
        <Button size="small" variant="contained" color="primary" onClick={handleSaveBtnClick}>
          Save
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupScheduleDialog;
