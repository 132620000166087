import React, { FunctionComponent } from 'react';

import { Alert, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { OrderState } from '../../types/entities';
import { setThousenSeparator } from '../../utils/currency-utils';

interface Props {
  symbol: string;
  margin: OrderState;
}

const MarginPanel: FunctionComponent<Props> = ({ symbol, margin }: Props) => {
  const { commission, initMarginChange, maintMarginChange } = margin;
  const commissionFormatted = commission === Number.MAX_VALUE ? '-' : `$${commission}`;
  const initMarginChangeNum = Number(initMarginChange) || 0;
  const initMarginReqFormatted = setThousenSeparator(initMarginChangeNum.toFixed(0));
  const maintMarginChangeNum = Number(maintMarginChange) || 0;
  const maintMarginReqFormatted = setThousenSeparator(maintMarginChangeNum.toFixed(0));

  const capitalNumBefore = Number(margin.equityWithLoanBefore) || 0;
  const capitalFormattedBefore = setThousenSeparator(capitalNumBefore.toFixed(0));

  const initMarginNumBefore = Number(margin.initMarginBefore) || 0;
  const initMarginFormattedBefore = setThousenSeparator(initMarginNumBefore.toFixed(0));

  const availableFundsNumBefore = capitalNumBefore - initMarginNumBefore;
  const availableFundsFormattedBefore = setThousenSeparator(availableFundsNumBefore.toFixed(0));
  const marginPctBefore = (availableFundsNumBefore / capitalNumBefore) * 100;
  let marginPctColorBefore = 'Inherit';
  if (marginPctBefore <= 30) {
    marginPctColorBefore = '#ffbf00';
  }

  const capitalNumAfter = Number(margin.equityWithLoanAfter) || 0;
  const capitalFormatedAfter = setThousenSeparator(capitalNumAfter.toFixed(0));

  const initMarginNumAfter = Number(margin.initMarginAfter) || 0;
  const initMarginFormatedAfter = setThousenSeparator(initMarginNumAfter.toFixed(0));

  const availableFundsNumAfter = capitalNumAfter - initMarginNumAfter;
  const availableFundsFormattedAfter = setThousenSeparator(availableFundsNumAfter.toFixed(0));
  const marginPctAfter = (availableFundsNumAfter / capitalNumAfter) * 100;
  let marginPctColorAfter = 'Inherit';
  if (marginPctAfter <= 30) {
    marginPctColorAfter = '#ffbf00';
  }

  let marginWarningPanel: JSX.Element | undefined;
  if (margin?.warningText) {
    marginWarningPanel = (
      <Alert sx={{ my: 1 }} severity="warning">
        {margin.warningText}
      </Alert>
    );
  }

  return (
    <Box>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">Commission</TableCell>
              <TableCell align="right">Init. Margin</TableCell>
              <TableCell align="right">Maint. Margin</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ whiteSpace: 'nowrap' }}>
              <TableCell>{symbol}</TableCell>
              <TableCell align="right">{commissionFormatted}</TableCell>
              <TableCell align="right">${initMarginReqFormatted}</TableCell>
              <TableCell align="right">${maintMarginReqFormatted}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {marginWarningPanel}

      <Table sx={{ mt: 3 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">Cash</TableCell>
            <TableCell align="right">Req. Margin</TableCell>
            <TableCell align="right">Avail. Funds</TableCell>
            <TableCell align="right">%</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ whiteSpace: 'nowrap' }}>
            <TableCell>Before</TableCell>
            <TableCell align="right">${capitalFormattedBefore}</TableCell>
            <TableCell align="right">${initMarginFormattedBefore}</TableCell>
            <TableCell align="right">${availableFundsFormattedBefore}</TableCell>
            <TableCell align="right">
              <span style={{ color: marginPctColorBefore }}>{marginPctBefore.toFixed(0)}%</span>
            </TableCell>
          </TableRow>
          <TableRow sx={{ whiteSpace: 'nowrap' }}>
            <TableCell>After</TableCell>
            <TableCell align="right">${capitalFormatedAfter}</TableCell>
            <TableCell align="right">${initMarginFormatedAfter}</TableCell>
            <TableCell align="right">${availableFundsFormattedAfter}</TableCell>
            <TableCell align="right">
              <span style={{ color: marginPctColorAfter }}>{marginPctAfter.toFixed(0)}%</span>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default MarginPanel;
