import { FunctionComponent, useMemo } from 'react';

import { Button, Card } from '@mui/material';

import { useAppSelector } from '../../store/hooks';
import { Security } from '../../types/entities';
import { SecurityCategory } from '../../types/enums';
import SecurityCategoryTabs from '../ui/SecurityCategoryTabs';

interface Props {
  securities: Security[];
  selected: Security | undefined;
  onSelected: (security: Security | undefined) => void;
}

const SelectSecurityPanel: FunctionComponent<Props> = ({ securities, selected, onSelected }: Props) => {
  const selectedSymbol = selected?.symbol;

  const { selectedSecurityCategory } = useAppSelector((gs) => gs.userSettingsState);

  const handleClick = (sec: Security) => {
    if (sec.symbol === selectedSymbol) {
      onSelected(undefined);
    } else {
      onSelected(sec);
    }
  };

  const filteredSecurities = useMemo(() => {
    switch (selectedSecurityCategory) {
      case SecurityCategory.Futures:
        return securities.filter((x) => x.category === SecurityCategory.Futures);
      case SecurityCategory.MicroFutures:
        return securities.filter((x) => x.category === SecurityCategory.MicroFutures);
      case SecurityCategory.Stock:
        return securities.filter((x) => x.category === SecurityCategory.Stock);
      default:
        return [];
    }
  }, [securities, selectedSecurityCategory]);

  return (
    <>
      <SecurityCategoryTabs />
      <Card sx={{ display: 'flex', flexWrap: 'wrap', p: 2, gap: 2 }}>
        {filteredSecurities.map((x) => (
          <Button key={x.symbol} onClick={() => handleClick(x)} variant={x.symbol === selectedSymbol ? 'contained' : 'outlined'}>
            {x.name}
          </Button>
        ))}
      </Card>
    </>
  );
};

export default SelectSecurityPanel;
