import React, { FunctionComponent } from 'react';

import { Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface Props {
  sx?: object;
  children?: React.ReactNode;
}

const ResponsiveContainer: FunctionComponent<Props> = ({ sx, children }: Props) => {
  const theme = useTheme();
  return (
    <Container sx={sx} disableGutters={useMediaQuery(theme.breakpoints.only('xs'))}>
      {children}
    </Container>
  );
};

export default ResponsiveContainer;
