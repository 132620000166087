import { FunctionComponent, useEffect, useState } from 'react';

import { Box, Button, Skeleton, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getPortfoliosAsync } from '../../../store/portfolio/service';
import { setSelectedPortfolio } from '../../../store/user-settings/reducer';
import { Portfolio } from '../../../types/entities';

interface Props {
  onEditBtnClick?: (portfolio: Portfolio | undefined) => void;
  readOnly?: boolean;
}

const PortfolioTable: FunctionComponent<Props> = ({ onEditBtnClick, readOnly = false }: Props) => {
  const { loading, loaded, portfolios } = useAppSelector((gs) => gs.portfolioState);
  const { selectedPortfolio } = useAppSelector((gs) => gs.userSettingsState);

  const dispatch = useAppDispatch();

  const [initialLoaded, setInitialLoaded] = useState(false);
  useEffect(() => {
    setInitialLoaded(true);
  }, []);

  useEffect(() => {
    if (!loading && !loaded) {
      dispatch(getPortfoliosAsync());
    }
  }, [loading, loaded]);

  const handleRowClick = (portfolio: Portfolio) => {
    if (portfolio.id === selectedPortfolio?.id) {
      dispatch(setSelectedPortfolio(undefined));
    } else {
      dispatch(setSelectedPortfolio(portfolio));
    }
  };

  const handleEditBtnClick = (event: React.MouseEvent<HTMLElement>, p: Portfolio) => {
    event.stopPropagation();
    if (onEditBtnClick) {
      onEditBtnClick(p);
    }
  };

  if (!initialLoaded && loading) {
    return <Skeleton sx={{ m: 2 }} variant="rounded" animation="wave" height={50} />;
  }

  const sortByName = (a: Portfolio, b: Portfolio) => {
    return a.name > b.name ? 1 : -1;
  };

  const tableBody = (
    <TableBody>
      {portfolios
        .slice()
        .sort(sortByName)
        .map((x) => (
          <TableRow
            key={x.id}
            sx={{
              cursor: 'pointer'
              // borderBottom: (theme) => (x.id === selectedPortfolio?.id ? `3px solid ${theme.palette.primary.main}` : 'inherit')
            }}
            onClick={() => handleRowClick(x)}
            hover
          >
            <TableCell sx={{ width: '100%' }}>
              <Typography
                variant="body2"
                sx={{
                  color: (theme) => (x.id === selectedPortfolio?.id ? theme.palette.info.light : 'inherit'),
                  fontWeight: x.id === selectedPortfolio?.id ? 'bold' : 'normal'
                }}
              >
                {x.name.toUpperCase()}
              </Typography>
              <Typography variant="caption" sx={{ fontStyle: 'plain', color: '#ffg' }}>
                {x.comment}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={{ width: '50px', px: 2 }}>
              {!readOnly && (
                <Button size="small" variant="outlined" onClick={(e) => handleEditBtnClick(e, x)}>
                  Edit
                </Button>
              )}
            </TableCell>
          </TableRow>
        ))}
    </TableBody>
  );

  const table = (
    <TableContainer>
      <Table size="small">{tableBody}</Table>
    </TableContainer>
  );

  return (
    <Box sx={{ flexGrow: 1, mb: 1.5 }}>
      {portfolios.length === 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ px: 2 }} fontSize="smaller" component="div">
            No portfolios exists..
          </Typography>
        </Box>
      ) : (
        table
      )}
    </Box>
  );
};

export default PortfolioTable;
