import { createTheme } from '@mui/material';

/**
 * overskrive default theme:
 * https://mui.com/material-ui/customization/default-theme/
 */
// const headerFont = 'Contrail One';
const headerFont = 'Oxanium';

export const theme = createTheme({
  palette: {
    mode: 'dark'
  },
  status: {
    danger: 'red'
  },
  typography: {
    h1: {
      fontFamily: headerFont
    },
    h2: {
      fontFamily: headerFont
    },
    h3: {
      fontFamily: headerFont
    },
    h4: {
      fontFamily: headerFont
    },
    h5: {
      fontFamily: headerFont
    },
    h6: {
      fontFamily: headerFont
    }
  }
});
