export const formatNum = (num: number) => Math.round(num * 100) / 100;

export const setDecimalSpaces = (num: number | undefined, spaces: number) => {
  if (num === undefined) {
    return num;
  }
  if (!num || !Number(num)) {
    return num;
  }
  return num.toFixed(spaces).replace(/\./g, ',');
};

export const setThousenSeparator = (amount: string | number | undefined, separator = ' '): string => {
  if (amount === undefined) {
    return '';
  }
  if (typeof amount === 'number') {
    amount = amount.toString();
  }
  const isNegativeNumber = amount.startsWith('-');
  if (isNegativeNumber) {
    amount = amount.substring(1);
  }

  let partial = '';
  const commaIndex = amount.indexOf(',');
  if (commaIndex > 0) {
    partial = amount.substring(commaIndex, amount.length);
    amount = amount.substring(0, commaIndex);
  }
  if (amount.length >= 7) {
    amount = amount.substring(0, amount.length - 6) + separator + amount.substring(amount.length - 6, amount.length);
  }
  if (amount.length >= 4) {
    amount = amount.substring(0, amount.length - 3) + separator + amount.substring(amount.length - 3, amount.length);
  }

  return (isNegativeNumber ? '-' : '') + amount + partial;
};

export const formatUsd = (num: number | undefined, spaces: number): string => {
  if (num === undefined || num === null) {
    return '';
  }
  const isNeg = num < 0;
  const posNum = Math.abs(num);
  let fixed = setDecimalSpaces(posNum, spaces);
  fixed = setThousenSeparator(fixed, '.');
  return (isNeg ? '-' : '') + '$' + fixed;
};
