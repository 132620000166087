import { FunctionComponent, useEffect, useState } from 'react';

import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { Box, Button, DialogActions, DialogContent, FormControl, IconButton, TextField, Tooltip } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { completePositionAsync, updatePositionAsync } from '../../../store/position/service';
import { Position } from '../../../types/entities';
import SelectDateTimeControl from '../../form-controls/SelectDateTimeControl';
import { useConfirm } from '../../hooks/useConfirm';

interface Props {
  position: Position;
  onClose: () => void;
}

const ClosePositionPanel: FunctionComponent<Props> = ({ onClose, position }: Props) => {
  const [confirm, ConfirmDialog] = useConfirm();
  const { loaded, error } = useAppSelector((gs) => gs.positionState);
  const [actionPressed, setActionPressed] = useState(false);

  const [portfolioRlzPnl, setPortfolioRlzPnl] = useState<number>(position.portfolioRlzPnl);
  const [realizedPnl, setRealizedPnl] = useState<number>(position.realizedPnl);
  const [exitDate, setExitDate] = useState<Date | undefined>(position.exitDate ? new Date(position.exitDate) : undefined);

  const openOrderState = useAppSelector((gs) => gs.openOrderState);
  const openOrder = openOrderState.orders.find((x) => x.conId === position.conId);
  const canCompletePosition = !openOrder && position.size === 0 && !!exitDate;

  useEffect(() => {
    setPortfolioRlzPnl(position.portfolioRlzPnl);
    setRealizedPnl(position.realizedPnl);
    setExitDate(position.exitDate ? new Date(position.exitDate) : undefined);
  }, [position]);

  const handleCloseDialog = () => {
    setActionPressed(false);
    onClose();
  };

  // auto-close on save successfully
  useEffect(() => {
    if (actionPressed && loaded && !error) {
      handleCloseDialog();
    }
  });

  const dispatch = useAppDispatch();

  /**
   * removePosition sets the completed flag to true and exitDate to undefined,
   * which will remove the position, and not show the position in the Trade History.
   */
  const removePosition = async () => {
    setActionPressed(true);
    const pos = {
      ...position,
      completed: true,
      exitDate: undefined
    };
    dispatch(updatePositionAsync(pos));
  };

  const handleRemoveBtnClick = async () => {
    if (await confirm('Are you sure you want to remove the position?')) {
      removePosition();
    }
  };

  const handleCompleteBtnClick = async () => {
    setActionPressed(true);
    const pos = {
      ...position,
      realizedPnl,
      exitDate
    };
    dispatch(completePositionAsync(pos));
  };

  return (
    <Box>
      <DialogContent sx={{ p: 2 }}>
        <Box sx={{ mb: 3 }}>
          <SelectDateTimeControl label="Exit Timestamp" selectedValue={exitDate} onDateSelected={setExitDate} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
          <FormControl>
            <TextField
              name="name"
              size="small"
              label="Portfolio Rlz. PnL"
              variant="outlined"
              type="number"
              value={portfolioRlzPnl}
              onChange={(e) => setPortfolioRlzPnl(Number(e.target.value))}
            />
          </FormControl>
          <IconButton aria-label="Copy value" onClick={() => setRealizedPnl(portfolioRlzPnl)}>
            <Tooltip title="Copy Realized PnL Portfolio value to Position value">
              <DoubleArrowIcon />
            </Tooltip>
          </IconButton>
          <FormControl>
            <TextField
              name="name"
              size="small"
              label="Position Rlz. PnL"
              variant="outlined"
              type="number"
              value={realizedPnl}
              onChange={(e) => setRealizedPnl(Number(e.target.value))}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2, flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: 1 }}>
          <Button variant="outlined" color="success" onClick={handleCompleteBtnClick} disabled={!canCompletePosition} fullWidth>
            Complete
          </Button>
          <Button variant="outlined" color="error" onClick={handleRemoveBtnClick} fullWidth>
            Remove
          </Button>
        </Box>
      </DialogActions>
      <ConfirmDialog />
    </Box>
  );
};

export default ClosePositionPanel;
