import { FunctionComponent, useState } from 'react';

import { Box, Card, Container, Skeleton, Typography } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import ReloadDashboardFab from '../../components/ui/ReloadDashboardFab';
import ResponsiveContainer from '../../components/ui/ResponsiveContainer';
import SimpleWizardNav from '../../components/wizard/SimpleWizardNav';
import { useAppSelector } from '../../store/hooks';
import { getOrderSummaryBySymbolState } from '../../store/order-summary/selectors';
import { Position } from '../../types/entities';

import ExistingOrdersTable from './ExistingOrdersTable';
import ExistingPositionsTable from './ExistingPositionsTable';
import OrderSelectedPanel from './OrderSelectedPanel';
import OrderTable from './OrderTable/OrderTable';
import PositionGroupPicker from './PositionGroupPicker';

const OrderSummaryPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const params = useParams();
  const symbol = params.symbol || '';
  const [searchParams] = useSearchParams();
  const origin = searchParams.get('origin');
  const backNavigation = origin && origin === 'dashboard' ? '/dashboard' : '/order';

  const orderSummaryState = useAppSelector((x) => x.orderSummaryState);
  const { groupId, orders } = getOrderSummaryBySymbolState(orderSummaryState, symbol || '');

  const { loading } = useAppSelector((gs) => gs.dashboardState);

  const [positionSelected, setPositionSelected] = useState<Position | undefined>();

  return (
    <>
      <Container>
        <Typography variant="h4" sx={{ my: 3 }}>
          Order Summary
        </Typography>
      </Container>
      <ResponsiveContainer>
        <Card>
          <Typography variant="h6" component="div" m={2}>
            Prepared Orders
          </Typography>

          <Box sx={{ pb: 2, px: 2 }}>
            <PositionGroupPicker symbol={symbol} groupId={groupId} />
          </Box>
          {orders.length > 0 ? (
            <Box mb={1}>
              <OrderTable orders={orders} groupId={groupId} onRowClick={setPositionSelected} orderSelected={positionSelected} />
            </Box>
          ) : (
            <Box sx={{ ml: 2, mb: 2 }}>
              <small>No Orders..</small>
            </Box>
          )}
        </Card>
        <Card sx={{ mt: 2 }}>
          <Typography variant="h6" component="div" m={2}>
            Existing Orders
          </Typography>
          {loading && <Skeleton sx={{ m: 2 }} variant="rounded" animation="wave" height={80} />}
          {!loading && (
            <Box sx={{ mb: 1 }}>
              <ExistingOrdersTable symbol={symbol} onPositionSelected={setPositionSelected} positionSelected={positionSelected} />
            </Box>
          )}
        </Card>
        <Card sx={{ mt: 2 }}>
          <Typography variant="h6" component="div" m={2}>
            Existing Positions
          </Typography>
          {loading && <Skeleton sx={{ m: 2 }} variant="rounded" animation="wave" height={80} />}
          {!loading && (
            <Box sx={{ mb: 1 }}>
              <ExistingPositionsTable symbol={symbol} onPositionSelected={setPositionSelected} positionSelected={positionSelected} />
            </Box>
          )}
        </Card>
        <OrderSelectedPanel position={positionSelected} />
        <SimpleWizardNav activeStep={2} steps={3} previous={() => navigate(backNavigation)} />
      </ResponsiveContainer>

      <ReloadDashboardFab />
    </>
  );
};

export default OrderSummaryPage;
