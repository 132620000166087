import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { QuickNoteSuccessAction, QuickNoteFailedAction, QuickNoteLoadingAction, QuickNoteAction } from './actions';
import { QuickNoteState, initialQuickNoteState, initialQuickNoteStateBySymbol } from './state';

const QuickNoteSlice = createSlice({
  name: 'quick-note',
  initialState: initialQuickNoteState,
  reducers: {
    reset: () => initialQuickNoteState,
    loading: (state: QuickNoteState, action: PayloadAction<QuickNoteLoadingAction>) => {
      state[action.payload.symbol] = {
        error: undefined,
        loading: action.payload.loading,
        loaded: !action.payload.loading,
        notes: state[action.payload.symbol]?.notes ?? []
      };
    },
    success: (state: QuickNoteState, action: PayloadAction<QuickNoteSuccessAction>) => {
      state[action.payload.symbol] = {
        error: undefined,
        loading: false,
        loaded: true,
        notes: action.payload.notes
      };
    },
    fail: (state: QuickNoteState, action: PayloadAction<QuickNoteFailedAction>) => {
      state[action.payload.symbol] = {
        error: action.payload.error,
        loading: false,
        loaded: true,
        notes: state[action.payload.symbol]?.notes ?? []
      };
    },
    add: (state: QuickNoteState, action: PayloadAction<QuickNoteAction>) => {
      const current = state[action.payload.symbol] ?? { ...initialQuickNoteStateBySymbol, symbol: action.payload.symbol };
      current.notes.push(action.payload.note);
      current.loaded = true;
      current.loading = false;
      current.error = undefined;
    },
    remove: (state: QuickNoteState, action: PayloadAction<QuickNoteAction>) => {
      const current = state[action.payload.symbol] ?? { ...initialQuickNoteStateBySymbol, symbol: action.payload.symbol };
      const note = action.payload.note;
      const item = current.notes.find((x) => x.id === note.id);
      const index = item ? current.notes.indexOf(item) : -1;
      current.notes.splice(index, 1);
      current.loaded = true;
      current.loading = false;
      current.error = undefined;
    }
  }
});

const { actions, reducer } = QuickNoteSlice;
export const { reset, loading, success, fail, add, remove } = actions;
export default reducer;
