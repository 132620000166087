import { Position } from '../../types/entities';

import { TradeHistoryState, TradeHistoryStateByDate, initialTradeHistoryStateByDate } from './state';

const getKey = (year: number, month: number) => `${year}-${month}`;

export const getTradeHistoryStateByDate = (tradeHistoryState: TradeHistoryState, year: number, month: number): TradeHistoryStateByDate => {
  return tradeHistoryState[getKey(year, month)] || { ...initialTradeHistoryStateByDate, year, month };
};

export const getPositions = (tradehistoryState: TradeHistoryState, year: number, month: number): Position[] => {
  return getTradeHistoryStateByDate(tradehistoryState, year, month).positions;
};

export const isLoaded = (tradehistoryState: TradeHistoryState, year: number, month: number): boolean => {
  return getTradeHistoryStateByDate(tradehistoryState, year, month).loaded;
};
