import { ContractMarketData } from '../../types/entities';

import { MarketDataState, MarketDataStateByConId, initialMarketDataByConId } from './state';

export const getMarketDataStateByConId = (marketDataState: MarketDataState, conId: number): MarketDataStateByConId => {
  return marketDataState[conId] || { ...initialMarketDataByConId };
};

export const getMarketData = (marketDataState: MarketDataState, conId: number): ContractMarketData | undefined => {
  return getMarketDataStateByConId(marketDataState, conId).marketData;
};

export const isMarketDataLoaded = (marketDataState: MarketDataState, conId: number): boolean => {
  return getMarketDataStateByConId(marketDataState, conId).loaded;
};

export const isMarketDataLoading = (marketDataState: MarketDataState, conId: number): boolean => {
  return getMarketDataStateByConId(marketDataState, conId).loading;
};

export const getMarketDataError = (marketDataState: MarketDataState, conId: number): string | undefined => {
  return getMarketDataStateByConId(marketDataState, conId).error;
};
