import { FunctionComponent } from 'react';

import { Box, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import dayjs from 'dayjs';

import { INDICATOR_GREEN_COLOR, INDICATOR_RED_COLOR } from '../../../assets/colors';
import { ScheduleLog } from '../../../types/entities';

interface Props {
  events: ScheduleLog[];
}

const PositionLogTable: FunctionComponent<Props> = ({ events }: Props) => {
  const header = (
    <TableHead>
      <TableRow>
        <TableCell title="Time">Logged</TableCell>
        <TableCell align="right">PnL</TableCell>
        <TableCell align="right">PnL %</TableCell>
        <TableCell align="right">VLT</TableCell>
        <TableCell title="Price">Message</TableCell>
      </TableRow>
    </TableHead>
  );

  const rows = events.map((x, i) => {
    const loggedDt = new Date(x.logged);
    const formattedLoggedDt = dayjs(loggedDt).format('MM/DD HH:mm A');

    let pnlColor = 'inherit';
    if (x.pnl > 0) {
      pnlColor = INDICATOR_GREEN_COLOR;
    } else if (x.pnl < 0) {
      pnlColor = INDICATOR_RED_COLOR;
    }

    let pnlPct = '-';
    let pnlPctColor = 'inherit';
    if (x.unrealizedProfitPct) {
      pnlPct = x.unrealizedProfitPct ? `${x.unrealizedProfitPct?.toFixed(0)}%` : '-';
      pnlPctColor = x.unrealizedProfitPct ? INDICATOR_GREEN_COLOR : 'inherit';
    } else if (x.unrealizedLossPct) {
      pnlPct = x.unrealizedLossPct ? `${x.unrealizedLossPct?.toFixed(0)}%` : '-';
      pnlPctColor = x.unrealizedLossPct ? INDICATOR_RED_COLOR : 'inherit';
    }

    const vltPct = x.volatilityPct ? `${x.volatilityPct.toFixed(2)}%` : '-';

    const msgColor = x.isError ? INDICATOR_RED_COLOR : 'inherit';
    return (
      <TableRow key={i}>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{formattedLoggedDt}</TableCell>
        <TableCell align="right" sx={{ color: pnlColor }}>
          ${x.pnl.toFixed(0)}
        </TableCell>
        <TableCell align="right" sx={{ color: pnlPctColor }}>
          {pnlPct}
        </TableCell>
        <TableCell align="right">{vltPct}</TableCell>
        <TableCell align="left" title="Message" sx={{ color: msgColor, whiteSpace: 'nowrap' }}>
          {x.message}
        </TableCell>
      </TableRow>
    );
  });

  if (events.length === 0) {
    return (
      <Box>
        <Typography variant="body2" component="div" sx={{ ml: 2, mb: 2 }}>
          No log records found..
        </Typography>
        <Divider />
      </Box>
    );
  }

  return (
    <TableContainer>
      <Table size="small">
        {header}
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default PositionLogTable;
