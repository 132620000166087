import React, { FunctionComponent, useMemo } from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material';

import { OrderAction, OrderType, Position } from '../../types/entities';

interface Props {
  orderType: OrderType;
  onOrderTypeChanged: (ot: OrderType) => void;
  orderAction: OrderAction;
  onOrderActionChanged: (oa: OrderAction) => void;
  marketPrice: number;
  triggerPrice: number;
  onTriggerPriceChanged: (e: number) => void;
  exitPosition?: Position | undefined;
}

const OrderTypePicker: FunctionComponent<Props> = ({
  orderType,
  onOrderTypeChanged,
  orderAction,
  onOrderActionChanged,
  marketPrice,
  triggerPrice,
  onTriggerPriceChanged,
  exitPosition
}) => {
  const isEntryOrder = !exitPosition;

  const handleTriggerPriceChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const price = Number(e.target.value);
    onTriggerPriceChanged(price);
  };

  const priceLabel = orderType === 'LMT' ? 'Limit Price' : orderType === 'STP' ? 'Stop Price' : 'Market Price';

  const compositeTypeHelperMessage = useMemo(() => {
    if (!marketPrice) {
      return undefined;
    }
    if (orderType === 'LMT') {
      if (orderAction === 'BUY') {
        return isEntryOrder ? 'Buy long position at lower price' : 'Flatten short position at lower price';
      } else if (orderAction === 'SELL') {
        return isEntryOrder ? 'Buy short position at higher price' : 'Flatten long position at higher price';
      }
    } else if (orderType === 'STP') {
      if (orderAction === 'BUY') {
        return isEntryOrder ? 'Buy long position at higher price' : 'Flatten short position at higher price';
      } else if (orderAction === 'SELL') {
        return isEntryOrder ? 'Buy short position at lower price' : 'Flatten long position at lower price';
      }
    }
  }, [isEntryOrder, marketPrice, triggerPrice, orderType, orderAction]);

  const compositeTypeErrorMessage = useMemo(() => {
    if (!marketPrice) {
      return undefined;
    }
    if (orderType === 'LMT') {
      if (orderAction === 'BUY' && triggerPrice > marketPrice) {
        return 'BUY-LMT above market price will trigger instantly.';
      } else if (orderAction === 'SELL' && triggerPrice < marketPrice) {
        return 'SELL-LMT below market price will trigger instantly.';
      }
    } else if (orderType === 'STP') {
      if (orderAction === 'BUY' && triggerPrice < marketPrice) {
        return 'BUY-STP below market price will trigger instantly.';
      } else if (orderAction === 'SELL' && triggerPrice > marketPrice) {
        return 'SELL-STP above market price will trigger instantly.';
      }
    }
  }, [exitPosition, marketPrice, triggerPrice, orderType, orderAction]);

  return (
    <Box sx={{ p: 2 }}>
      <Box>
        <Typography variant="h6" sx={{ mb: 2 }} component="div">
          Order Type
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={2} alignItems="flex-start">
          {!exitPosition && (
            <Grid item xs={12} md={6} lg={4}>
              <ButtonGroup variant="outlined" disableElevation fullWidth>
                <Button onClick={() => onOrderActionChanged('BUY')} variant={orderAction === 'BUY' ? 'contained' : 'outlined'}>
                  Buy
                </Button>
                <Button onClick={() => onOrderActionChanged('SELL')} variant={orderAction === 'SELL' ? 'contained' : 'outlined'}>
                  Sell
                </Button>
              </ButtonGroup>
            </Grid>
          )}
          <Grid item xs={12} md={6} lg={4}>
            <>
              <ButtonGroup variant="outlined" fullWidth>
                {!exitPosition && (
                  <Button onClick={() => onOrderTypeChanged('MKT')} variant={orderType === 'MKT' ? 'contained' : 'outlined'}>
                    Market
                  </Button>
                )}
                <Button onClick={() => onOrderTypeChanged('LMT')} variant={orderType === 'LMT' ? 'contained' : 'outlined'}>
                  Limit
                </Button>
                <Button onClick={() => onOrderTypeChanged('STP')} variant={orderType === 'STP' ? 'contained' : 'outlined'}>
                  Stop
                </Button>
              </ButtonGroup>
              {compositeTypeHelperMessage && (
                <Typography pt={0.5} px={1} variant="caption" component="div">
                  <i>Implies: {compositeTypeHelperMessage}</i>
                </Typography>
              )}
            </>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl sx={{ width: '100%' }} error={!!compositeTypeErrorMessage}>
              <InputLabel htmlFor="outlined-adornment-price">{priceLabel}</InputLabel>
              <OutlinedInput
                value={triggerPrice}
                onChange={handleTriggerPriceChanged}
                size="small"
                type="number"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                label={priceLabel}
                disabled={orderType === 'MKT'}
              />
              {compositeTypeErrorMessage && <FormHelperText>{compositeTypeErrorMessage}</FormHelperText>}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default OrderTypePicker;
