import { FunctionComponent } from 'react';

import { Box, Typography } from '@mui/material';

import { POSTION_ZEBRA_COLOR_EVEN, POSTION_ZEBRA_COLOR_ODD } from '../../../../../assets/constants';
import { useAppSelector } from '../../../../../store/hooks';
import { Position, PositionGroup } from '../../../../../types/entities';
import PositionPanel from '../../PositionPanel';
interface Props {
  group: PositionGroup;
}

const PositionGroupDetails: FunctionComponent<Props> = ({ group }) => {
  const { positions } = useAppSelector((gs) => gs.positionState);

  const positionsInGroup = positions.filter((x) => x.groupId === group.id);

  const sortPositions = (a: Position, b: Position) => {
    if (!a || !b) {
      return 0;
    }
    return a.sortOrder < b.sortOrder ? 1 : -1;
  };

  return (
    <Box>
      {positionsInGroup
        .filter((x) => !x.completed)
        .sort(sortPositions)
        .map((x, index) => (
          <PositionPanel key={x.id} position={x} bg={index % 2 === 0 ? POSTION_ZEBRA_COLOR_EVEN : POSTION_ZEBRA_COLOR_ODD} />
        ))}
      {positionsInGroup.length === 0 && (
        <Typography sx={{ p: 1 }} variant="body2">
          No positions in group..
        </Typography>
      )}
    </Box>
  );
};

export default PositionGroupDetails;
