import { TradingClass } from '../../types/entities';

import { TradingClassState, TradingClassStateByConId, initialTradingClassStateByConId } from './state';

export const getTradingClassByConId = (tradingClassState: TradingClassState, conId: number): TradingClassStateByConId => {
  return tradingClassState[conId] || { ...initialTradingClassStateByConId, conId };
};

export const getTradingClasses = (tradingClassState: TradingClassState, conId: number): TradingClass[] => {
  return getTradingClassByConId(tradingClassState, conId).tradingClasses;
};

export const isLoaded = (tradingClassState: TradingClassState, conId: number): boolean => {
  return getTradingClassByConId(tradingClassState, conId).loaded;
};
