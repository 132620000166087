import { FundamentalNote } from '../../types/entities';

export interface FundamentalNoteState {
  loading: boolean;
  loaded: boolean;
  error?: string;
  notes: FundamentalNote[];
}

export const initialState: FundamentalNoteState = {
  loading: false,
  loaded: false,
  error: undefined,
  notes: []
};
