import { FunctionComponent, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { TextField } from '@mui/material';

import DialogCloseIconButton from '../../../components/ui/DialogCloseIconButton';
import { Position } from '../../../types/entities';
import { getFormattedExpireDateWithDuration } from '../../../utils/datetime-utils';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  position: Position;
  onDelete: (pos: Position) => void;
  onChange: (pos: Position) => void;
}

const EditOrderDialog: FunctionComponent<Props> = ({ isOpen, onClose, position, onDelete, onChange }: Props) => {
  const [positionForm, setPositionForm] = useState(position);
  const subTitle = getFormattedExpireDateWithDuration(position.expirationDate);

  useEffect(() => {
    setPositionForm(positionForm);
  }, [position]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUpdateForm = (field: keyof Position, value: any) => {
    const copy = {
      ...positionForm,
      [field]: value !== '' ? value : undefined
    } as Position;
    setPositionForm(copy);
  };

  const handleSaveBtnClick = () => {
    onChange(positionForm);
    onClose();
  };

  const handleRemoveBtnClick = () => {
    onDelete(positionForm);
    onClose();
  };

  return (
    <Dialog fullWidth open={isOpen} maxWidth="xs" onClose={onClose}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h5">Edit Order</Typography>
        {subTitle && (
          <Typography variant="caption" component="div" sx={{ mt: 1 }}>
            <i>{subTitle}</i>
          </Typography>
        )}
        <DialogCloseIconButton onClose={onClose} />
      </Box>

      <DialogContent sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            sx={{ mt: 1 }}
            size="small"
            label="Order Size"
            variant="outlined"
            type="number"
            value={positionForm.orderSize}
            onChange={(e) => handleUpdateForm('orderSize', Number(e.target.value))}
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel id="month-label" size="small">
              Time In Force (TIF)
            </InputLabel>
            <Select
              name="time"
              labelId="group-label"
              value={positionForm.tif ?? ''}
              label="Time In Force (TIF)"
              onChange={(e) => handleUpdateForm('tif', e.target.value)}
              size="small"
            >
              <MenuItem key="" value="">
                Velg..
              </MenuItem>
              <MenuItem key="IOC" value="IOC">
                INSTANTLY OR CANCEL
              </MenuItem>
              <MenuItem key="GTC" value="GTC">
                GOOD TIL CANCEL
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogContent>

      <Divider />

      <DialogActions sx={{ p: 2, justifyContent: 'space-between' }}>
        <Button size="small" variant="contained" color="error" onClick={handleRemoveBtnClick}>
          Remove
        </Button>
        <Box>
          <Button sx={{ mr: 1 }} size="small" variant="contained" color="primary" onClick={handleSaveBtnClick}>
            Save
          </Button>
          <Button onClick={onClose}>Close</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EditOrderDialog;
