import React, { FunctionComponent } from 'react';

import { Box, Container, Tab, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CustomTabPanel } from '../../components/ui/CustomTabPanel';
import ResponsiveContainer from '../../components/ui/ResponsiveContainer';
import WrappingTabs from '../../components/ui/WrappingTabs';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setSelectedBackOfficeTab } from '../../store/user-settings/reducer';

import FundamentalsTab from './FundamentalsTab';
import PortfoliosTab from './ManagePortfoliosTab';
import SecuritiesTab from './SecuritiesTab';

const BackOfficePage: FunctionComponent = () => {
  const toTabIndex = (tabValue: string) => {
    switch (tabValue.toLowerCase()) {
      case 'fundamentals':
        return 1;
      case 'portfolios':
        return 2;
      default:
        return 0;
    }
  };

  const fromTabIndex = (index: number) => {
    switch (index) {
      case 1:
        return 'fundamentals';
      case 2:
        return 'portfolios';
      default:
        return 'securities';
    }
  };

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selectedParamsTab = searchParams.get('tab') ?? '';
  const { selectedBackOfficeTab: selectedStateTab } = useAppSelector((gs) => gs.userSettingsState);
  const selectedTab = selectedParamsTab || selectedStateTab;

  const tabIndex = toTabIndex(selectedTab);

  const dispatch = useAppDispatch();

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    const tabValue = fromTabIndex(newValue);
    dispatch(setSelectedBackOfficeTab(tabValue));
    navigate(`?tab=${tabValue}`);
  };

  return (
    <div>
      <Container>
        <Typography variant="h4" sx={{ my: 3 }}>
          Back Office
        </Typography>
      </Container>
      <ResponsiveContainer>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <WrappingTabs value={tabIndex} onChange={handleChange}>
              <Tab label="Securities" />
              <Tab label="Fundamentals" />
              <Tab label="Portfolios" />
            </WrappingTabs>
          </Box>
        </Box>
      </ResponsiveContainer>

      <Container sx={{ mt: 3, mb: 3 }}>
        <CustomTabPanel value={tabIndex} index={0}>
          <Typography variant="h5">Securities</Typography>
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={1}>
          <Typography variant="h5">Fundamentals</Typography>
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={2}>
          <Typography variant="h5">Portfolios</Typography>
        </CustomTabPanel>
      </Container>

      <ResponsiveContainer>
        <CustomTabPanel value={tabIndex} index={0}>
          <SecuritiesTab />
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={1}>
          <FundamentalsTab />
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={2}>
          <PortfoliosTab />
        </CustomTabPanel>
      </ResponsiveContainer>
    </div>
  );
};

export default BackOfficePage;
