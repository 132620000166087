import { toast } from 'react-toastify';

import { AppDispatch } from '..';
import { fetchMarketData } from '../../api';
import { MarketDataParams } from '../../types/entities';
import { Logger } from '../../utils/Logger';

import { success, fail, loading } from './reducer';

export const getMarketDataAsync = (params: MarketDataParams) => {
  return (dispatch: AppDispatch) => {
    const conId = params.conId;
    dispatch(loading({ conId, loading: true }));
    fetchMarketData(params)
      .then((response) => {
        const marketData = response.data;
        Logger.log(marketData);
        dispatch(success({ conId, marketData }));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail({ conId, error: msg }));
        toast.error(msg);
      });
  };
};
