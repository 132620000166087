import { toast } from 'react-toastify';

import { AppDispatch } from '..';
import { fetchFundamentalNotes, createFundamentalNote, updateFundamentalNote, removeFundamentalNote } from '../../api';
import { FundamentalNote } from '../../types/entities';
import { Logger } from '../../utils/Logger';

import { success, fail, loading, add, remove, update } from './reducer';

export const getFundamentalNotesAsync = () => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    fetchFundamentalNotes()
      .then((response) => {
        const notes = response.data;
        Logger.log(notes);
        dispatch(success(notes));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const createFundamentalNoteAsync = (note: FundamentalNote) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    createFundamentalNote(note)
      .then((response) => {
        const noteWithId = response.data;
        dispatch(add(noteWithId));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const updateFundamentalNoteAsync = (note: FundamentalNote) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    updateFundamentalNote(note)
      .then(() => {
        Logger.log(note);
        dispatch(update(note));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const removeFundamentalNoteAsync = (note: FundamentalNote) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    removeFundamentalNote(note)
      .then(() => {
        dispatch(remove(note));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};
