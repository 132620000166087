import { toast } from 'react-toastify';

import { updatePosition, completePosition } from '../../api';
import { AppDispatch } from '../../store';
import { Position } from '../../types/entities';
import { Logger } from '../../utils/Logger';

import { fail, loading, update } from './reducer';

export const updatePositionAsync = (pos: Position) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    updatePosition(pos)
      .then((response) => {
        Logger.log(response);
        dispatch(update(pos));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const completePositionAsync = (pos: Position) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    completePosition(pos)
      .then((response) => {
        Logger.log(response.data);
        dispatch(update(response.data));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};
