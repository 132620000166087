import {
  ContractDetails,
  ContractMarketData,
  OptionContract,
  OptionRight,
  OrderAction,
  OrderObjective,
  OrderState,
  OrderType,
  PlaceOrderReport,
  Security,
  TradingClass
} from '../../types/entities';

export interface OrderOptionState {
  loading: boolean;
  error: string | undefined;
  step: number;
  direction?: 'FORWARD' | 'BACKWARD';
  security?: Security;
  contractDetails?: ContractDetails;
  marketData?: ContractMarketData;
  tradingClass?: TradingClass;
  option?: OptionContract;
  margin?: OrderState;
  coverMargin?: OrderState;
  optionRight: OptionRight;
  orderType: OrderType;
  orderAction: OrderAction;
  orderObjective: OrderObjective;
  orderSize: number;
  marketPrice: number;
  entryPrice: number;
  entryOrderReport?: PlaceOrderReport;
  closeOrderReport?: PlaceOrderReport;
}

export const initialState: OrderOptionState = {
  loading: false,
  error: undefined,
  step: 0,
  optionRight: '',
  orderAction: 'BUY',
  orderType: 'MKT',
  orderObjective: '',
  orderSize: 1,
  marketPrice: 0,
  entryPrice: 0
};
