import { FunctionComponent } from 'react';

import { Box, Typography } from '@mui/material';

import { PositionGroup } from '../../../../../types/entities';

interface Props {
  group: PositionGroup;
}

const PositionGroupSummary: FunctionComponent<Props> = ({ group }) => {
  return (
    <Box width="100%" sx={{ p: 1 }}>
      <Typography sx={{ fontFamily: 'Oxanium' }}>{group.title}</Typography>
      <Typography variant="caption">{group.description}</Typography>
    </Box>
  );
};

export default PositionGroupSummary;
