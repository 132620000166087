import { FunctionComponent } from 'react';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';

interface Props {
  steps: number;
  activeStep: number;
  validateNext: (step: number) => boolean;
  next: () => void;
  previous: () => void;
  reset: () => void;
}

const WizardNav: FunctionComponent<Props> = ({ steps, activeStep, validateNext, next, previous, reset }) => {
  return (
    <>
      <Divider sx={{ mt: 2 }}>
        <Typography variant="caption">PROGRESS</Typography>
      </Divider>
      <Box sx={{ mt: 1, mb: 4, gap: 1, display: 'flex', justifyContent: 'stretch' }}>
        <IconButton size="small" onClick={reset} color="primary" disabled={activeStep === 0}>
          <KeyboardDoubleArrowLeft />
        </IconButton>
        <MobileStepper
          sx={{ p: 0, flexGrow: 1 }}
          variant="progress"
          steps={steps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button size="small" onClick={next} disabled={activeStep === steps - 1 || !validateNext(activeStep)}>
              Next
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button size="small" onClick={previous} disabled={activeStep === 0}>
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      </Box>
    </>
  );
};

export default WizardNav;
