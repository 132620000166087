import { toast } from 'react-toastify';

import { AppDispatch } from '..';
import { fetchTradingClasses } from '../../api';
import { TradingClassParams } from '../../types/entities';
import { Logger } from '../../utils/Logger';

import { success, fail, loading } from './reducer';

export const getTradingClassesAsync = (params: TradingClassParams) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading({ conId: params.conId, loading: true }));
    fetchTradingClasses(params)
      .then((response) => {
        const tradingClasses = response.data;
        Logger.log(tradingClasses);
        dispatch(success({ conId: params.conId, tradingClasses }));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail({ conId: params.conId, error: msg }));
        toast.error(msg);
      });
  };
};
