import { FunctionComponent, useEffect, useMemo } from 'react';

import { Box, Chip } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getQuickNoteBySymbol } from '../../../store/quick-note/selectors';
import { getQuickNotesAsync, removeQuickNoteAsync } from '../../../store/quick-note/service';
import { QuickNote } from '../../../types/entities';
import { getDurationString } from '../../../utils/datetime-utils';

interface Props {
  symbol: string;
}

const QuickNotePanel: FunctionComponent<Props> = ({ symbol }: Props) => {
  const quickNoteState = useAppSelector((gs) => gs.quickNoteState);
  const { loaded, notes } = getQuickNoteBySymbol(quickNoteState, symbol);

  const dispatch = useAppDispatch();

  const handleDeleteQuickNoteBtnClick = (qn: QuickNote) => {
    dispatch(removeQuickNoteAsync(qn));
  };

  useEffect(() => {
    if (!loaded) {
      dispatch(getQuickNotesAsync(symbol));
    }
  }, [symbol]);

  const getLabel = (qn: QuickNote) => {
    const createdDate = new Date(qn.created);
    const duration = getDurationString(createdDate, new Date());
    return `${duration}: ${qn.note}`;
  };

  const notesItems = useMemo(() => {
    return notes.map((x) => <Chip key={x.id} label={getLabel(x)} onDelete={() => handleDeleteQuickNoteBtnClick(x)} />);
  }, [symbol, loaded]);

  if (notes.length == 0) {
    return null;
  }

  return <Box sx={{ px: 2, pt: 2, pb: 1, display: 'flex', gap: 1, flexWrap: 'wrap' }}>{notesItems}</Box>;
};

export default QuickNotePanel;
