import { toast } from 'react-toastify';

import { AppDispatch } from '..';
import {
  addPortfolioContract,
  createPortfolio,
  fetchPortfolios,
  removePortfolio,
  removePortfolioContract,
  updatePortfolio
} from '../../api';
import { ContractDetails, Portfolio } from '../../types/entities';
import { Logger } from '../../utils/Logger';

import { success, fail, loading, add, remove, update } from './reducer';

export const getPortfoliosAsync = () => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    fetchPortfolios()
      .then((response) => {
        const portfolios = response.data;
        Logger.log(portfolios);
        dispatch(success(portfolios));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const createPortfolioAsync = (portfolio: Portfolio) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    createPortfolio(portfolio)
      .then((response) => {
        dispatch(add(response.data));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const updatePortfolioAsync = (portfolio: Portfolio) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    updatePortfolio(portfolio)
      .then(() => {
        Logger.log(portfolio);
        dispatch(update(portfolio));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const removePortfolioAsync = (portfolio: Portfolio) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    removePortfolio(portfolio)
      .then(() => {
        dispatch(remove(portfolio));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const addPortfolioContractAsync = (portfolio: Portfolio, details: ContractDetails) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    addPortfolioContract(portfolio.id, details)
      .then(() => {
        const copy = {
          ...portfolio,
          contracts: [...portfolio.contracts].concat(details)
        };
        dispatch(update(copy));
        toast.success(`Contract added to ${portfolio.name} successfully!`);
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

const removeImutablePortfolioContract = (portfolio: Portfolio, details: ContractDetails): Portfolio => {
  const copy = {
    ...portfolio,
    contracts: [...portfolio.contracts]
  };
  const conId = details.contract.conId;
  const item = copy.contracts.find((x) => x.contract.conId === conId);
  const index = item ? copy.contracts.indexOf(item) : -1;
  copy.contracts.splice(index, 1);
  return copy;
};

export const removePortfolioContractAsync = (portfolio: Portfolio, details: ContractDetails) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    removePortfolioContract(portfolio.id, details)
      .then(() => {
        const p = removeImutablePortfolioContract(portfolio, details);
        dispatch(update(p));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};
