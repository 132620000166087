import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OpenOrderReport } from '../../types/entities';

import { OpenOrderState, initialOpenOrderState } from './state';

const openOrderSlice = createSlice({
  name: 'open-order',
  initialState: initialOpenOrderState,
  reducers: {
    reset: () => initialOpenOrderState,
    loading: (state: OpenOrderState, action: PayloadAction<boolean>) => {
      state.error = undefined;
      state.loading = action.payload;
      state.loaded = !action.payload;
    },
    success: (state: OpenOrderState, action: PayloadAction<OpenOrderReport[]>) => {
      state.error = undefined;
      state.loading = false;
      state.loaded = true;
      state.orders = action.payload;
    },
    fail: (state: OpenOrderState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.loaded = true;
      state.orders = [];
    },
    removeOpenOrder: (state: OpenOrderState, action: PayloadAction<number>) => {
      const dbEntry = state.orders.find((x) => x.orderId === action.payload);
      if (!dbEntry) {
        return;
      }
      const index = state.orders.indexOf(dbEntry);
      state.orders.splice(index, 1);
      state.loading = false;
      state.loaded = true;
    }
  }
});

const { actions, reducer } = openOrderSlice;
export const { reset, loading, success, fail, removeOpenOrder } = actions;
export default reducer;
