import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TradeHistoryLoadingAction, TradeHistoryFailedAction, TradeHistorySuccessAction } from './actions';
import { TradeHistoryState, initialTradeHistoryState } from './state';

const getKey = (year: number, month: number) => `${year}-${month}`;

const tradeHistorySlice = createSlice({
  name: 'trade-history',
  initialState: initialTradeHistoryState,
  reducers: {
    reset: () => initialTradeHistoryState,
    loading: (state: TradeHistoryState, action: PayloadAction<TradeHistoryLoadingAction>) => {
      state[getKey(action.payload.year, action.payload.month)] = {
        error: undefined,
        loading: action.payload.loading,
        loaded: !action.payload.loading,
        positions: state[getKey(action.payload.year, action.payload.month)]?.positions ?? []
      };
    },
    success: (state: TradeHistoryState, action: PayloadAction<TradeHistorySuccessAction>) => {
      state[getKey(action.payload.year, action.payload.month)] = {
        error: undefined,
        loading: false,
        loaded: true,
        positions: action.payload.positions
      };
    },
    fail: (state: TradeHistoryState, action: PayloadAction<TradeHistoryFailedAction>) => {
      state[getKey(action.payload.year, action.payload.month)] = {
        error: action.payload.error,
        loading: false,
        loaded: true,
        positions: []
      };
    }
  }
});

const { actions, reducer } = tradeHistorySlice;
export const { reset, loading, success, fail } = actions;
export default reducer;
