import { toast } from 'react-toastify';

import { AppDispatch } from '..';
import { createFundamentalLink, fetchFundamentalLinks, removeFundamentalLink, updateFundamentalLink } from '../../api';
import { FundamentalLink } from '../../types/entities';
import { Logger } from '../../utils/Logger';

import { success, fail, loading, add, update, remove } from './reducer';

export const getFundamentalLinksAsync = (symbol: string | undefined) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading({ symbol: symbol ?? '*', loading: true }));
    fetchFundamentalLinks(symbol)
      .then((response) => {
        const links = response.data;
        Logger.log(links);
        dispatch(success({ symbol: symbol ?? '*', links }));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail({ symbol: symbol ?? '*', error: msg }));
        toast.error(msg);
      });
  };
};

export const createFundamentalLinkAsync = (link: FundamentalLink) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading({ symbol: link.symbol ?? '*', loading: true }));
    createFundamentalLink(link)
      .then((response) => {
        const linkWithId = response.data;
        dispatch(add({ symbol: link.symbol ?? '*', link: linkWithId }));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const updateFundamentalLinkAsync = (link: FundamentalLink) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading({ symbol: link.symbol ?? '*', loading: true }));
    updateFundamentalLink(link)
      .then(() => {
        Logger.log(link);
        dispatch(update({ symbol: link.symbol ?? '*', link }));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const removeFundamentalLinkAsync = (link: FundamentalLink) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading({ symbol: link.symbol ?? '*', loading: true }));
    removeFundamentalLink(link)
      .then(() => {
        dispatch(remove({ symbol: link.symbol ?? '*', link }));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};
