import { FunctionComponent } from 'react';

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import { Position } from '../../../../../types/entities';

import PositionDetails from './PositionDetails';
import PositionSummary from './PositionSummary';

interface Props {
  position: Position;
  isPositionClosed: boolean;
}

const PositionBody: FunctionComponent<Props> = ({ position, isPositionClosed }: Props) => {
  return (
    <Accordion disableGutters elevation={2} sx={{ backgroundColor: 'inherit' }}>
      <AccordionSummary sx={{ m: 0, p: 0, '& .MuiAccordionSummary-content': { m: 0 } }}>
        <PositionSummary position={position} isPositionClosed={isPositionClosed} />
      </AccordionSummary>
      <AccordionDetails sx={{ px: 1 }}>
        <PositionDetails position={position} isPositionClosed={isPositionClosed} />
      </AccordionDetails>
    </Accordion>
  );
};

export default PositionBody;
