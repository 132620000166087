import { ChartTrendLine } from '../../types/entities';

import { TrendLineState, TrendLineStateByConId, initialTrendLineStateByConId } from './state';

export const getTrendLineStateByConId = (trendLineState: TrendLineState, conId: number): TrendLineStateByConId => {
  return trendLineState[conId] || { ...initialTrendLineStateByConId, conId };
};

export const getTrendLines = (trendLineState: TrendLineState, conId: number): ChartTrendLine[] => {
  return getTrendLineStateByConId(trendLineState, conId).trendLines;
};

export const isLoaded = (trendLineState: TrendLineState, conId: number): boolean => {
  return getTrendLineStateByConId(trendLineState, conId).loaded;
};
