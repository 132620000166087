export interface ColorOption {
  colorName: string;
  value: string;
}

export const TREND_LINE_COLOR_OPTIONS: ColorOption[] = [
  { colorName: 'Orange', value: '#ff9800' },
  { colorName: 'Banana Yellow', value: '#ffe135' },
  { colorName: 'Columbia Blue', value: '#9bddff' },
  { colorName: 'Cream', value: '#fffdd0' },
  { colorName: 'Indian Red', value: '#cd5c5c' },
  { colorName: 'Ferrari Red', value: '#ff2800' },
  { colorName: 'Light Green', value: '#90ee90' },
  { colorName: 'Paris Green', value: '#50c878' },
  { colorName: 'Pale Brown', value: '#987654' }
];
