import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';

import { INDICATOR_GREEN_COLOR, INDICATOR_RED_COLOR } from '../../assets/colors';
import { securities } from '../../store/security/securities-mock';
import { Position, Security } from '../../types/entities';
import { getDurationString } from '../../utils/datetime-utils';

interface Props {
  securities: Security[];
  trades: Position[];
}

export default function TradeHistoryTable({ trades }: Props) {
  const tableHeader = (
    <TableHead>
      <TableRow sx={{ whiteSpace: 'nowrap' }}>
        <TableCell>Security</TableCell>
        <TableCell>Type</TableCell>
        <TableCell>Symbol</TableCell>
        <TableCell>Local Symbol</TableCell>
        <TableCell>Action</TableCell>
        <TableCell align="right">Size</TableCell>
        <TableCell align="right">Entry</TableCell>
        <TableCell align="right">Exit</TableCell>
        <TableCell align="right">Duration</TableCell>
        <TableCell align="right">PnL</TableCell>
      </TableRow>
    </TableHead>
  );

  const getFormattedDate = (isoDate: string | Date | undefined) => {
    if (!!isoDate) {
      const dt = new Date(isoDate);
      return dayjs(dt).format('MM/DD HH:mm A');
    }
    return 'N/A';
  };

  const getSecurityName = (pos: Position) => {
    const sec = securities.find((x) => x.symbol === pos.symbol);
    return sec ? sec.name : '';
  };

  const getPnlColor = (pnl: number) => {
    let pnlColor = 'inherit';
    if (pnl > 0) {
      pnlColor = INDICATOR_GREEN_COLOR;
    } else if (pnl < 0) {
      pnlColor = INDICATOR_RED_COLOR;
    }
    return pnlColor;
  };

  const sortByCompletedDate = (a: Position, b: Position) => {
    if (!a.exitDate || !b.exitDate) {
      return 0;
    }
    const aDt = new Date(a.exitDate);
    const bDt = new Date(b.exitDate);
    return aDt > bDt ? 1 : -1;
  };

  const sortedTrades = !!trades && trades.length > 1 ? [...trades].sort(sortByCompletedDate) : trades;

  const tableRows = sortedTrades.map((pos, index) => {
    const entryDate = getFormattedDate(pos.entryDate);
    const completedDate = getFormattedDate(pos.exitDate);

    let duration = '';
    if (!!pos.entryDate && !!pos.exitDate) {
      const entryDt = new Date(pos.entryDate);
      const completedDt = new Date(pos.exitDate);
      duration = getDurationString(entryDt, completedDt);
    }

    const orderAction = pos.right ? `${pos.orderAction} ${pos.right}` : pos.orderAction;

    const pnlColor = getPnlColor(pos.realizedPnl);

    return (
      <TableRow key={index} sx={{ whiteSpace: 'nowrap' }}>
        <TableCell>{getSecurityName(pos)}</TableCell>
        <TableCell>{pos.secType}</TableCell>
        <TableCell>{pos.symbol}</TableCell>
        <TableCell>{pos.localSymbol}</TableCell>
        <TableCell>{orderAction}</TableCell>
        <TableCell align="right">{pos.orderSize}</TableCell>
        <TableCell align="right">{entryDate}</TableCell>
        <TableCell align="right">{completedDate}</TableCell>
        <TableCell align="right">{duration}</TableCell>
        <TableCell align="right" sx={{ color: pnlColor }}>
          ${pos.realizedPnl.toFixed(0)}
        </TableCell>
      </TableRow>
    );
  });

  const sumRealizedPnl = trades.reduce((partialSum, pos) => partialSum + pos.realizedPnl, 0);
  const pnlColor = getPnlColor(sumRealizedPnl);

  const tableFooter = (
    <TableHead>
      <TableRow>
        <TableCell colSpan={9}>Sum:</TableCell>
        <TableCell align="right" sx={{ color: pnlColor }}>
          ${sumRealizedPnl.toFixed(0)}
        </TableCell>
      </TableRow>
    </TableHead>
  );

  return (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        {tableHeader}
        <TableBody>{tableRows}</TableBody>
        {tableFooter}
      </Table>
    </TableContainer>
  );
}
