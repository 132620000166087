import { QuickNote } from '../../types/entities';

export interface QuickNoteStateBySymbol {
  loading: boolean;
  loaded: boolean;
  error?: string;
  notes: QuickNote[];
}

export const initialQuickNoteStateBySymbol: QuickNoteStateBySymbol = {
  loading: false,
  loaded: false,
  error: undefined,
  notes: []
};

export interface QuickNoteState {
  [symbol: string]: QuickNoteStateBySymbol;
}

export const initialQuickNoteState: QuickNoteState = {};
