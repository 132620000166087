import { ContractDetails, Portfolio, Position, Security } from '../../types/entities';
import { SecurityCategory } from '../../types/enums';

export interface UserSettingsState {
  useFrozen: boolean;
  ignoreMissingUnderMarketPrice: boolean;
  useSnapshot: boolean;
  newsPanelCollapsed: boolean;
  indicatorPanelCollapsed: boolean;
  hourGraphCollapsed: boolean;
  dailyGraphCollapsed: boolean;
  selectedSecurityCategory: SecurityCategory;
  selectedSecurity: Security | undefined;
  selectedContractDetails?: ContractDetails | undefined;
  securityPanelCollapsed: boolean;
  favoritesPanelCollapsed: boolean;
  trendLinesCollapsed: boolean;
  selectedPortfolio?: Portfolio | undefined;
  selectedContractSection: 'all-contracts' | 'front-runners';
  selectedBackOfficeTab: 'securities' | 'portfolios' | 'fundamentals';
  selectedDashboardTab: 'positions' | 'portfolios' | 'browse';
  selectedExitPosition?: Position | undefined;
}

export const initialState: UserSettingsState = {
  useFrozen: false,
  ignoreMissingUnderMarketPrice: false,
  useSnapshot: false,
  newsPanelCollapsed: false,
  indicatorPanelCollapsed: false,
  hourGraphCollapsed: false,
  dailyGraphCollapsed: false,
  selectedSecurityCategory: SecurityCategory.Stock,
  selectedSecurity: undefined,
  securityPanelCollapsed: true,
  favoritesPanelCollapsed: false,
  trendLinesCollapsed: false,
  selectedContractSection: 'all-contracts',
  selectedBackOfficeTab: 'securities',
  selectedDashboardTab: 'positions'
};
