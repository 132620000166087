import { FunctionComponent } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { INDICATOR_GREEN_COLOR, INDICATOR_RED_COLOR } from '../../../../../../assets/colors';
import RaceTrack, { RaceDriver, RaceSetup } from '../../../../../../components/panels/RaceTrack';
import { Position } from '../../../../../../types/entities';
import { formatNum, setDecimalSpaces, setThousenSeparator } from '../../../../../../utils/currency-utils';

import { getRaceDuractionPct, getUnderlyingRaceDriver } from './utils';

interface Props {
  position: Position;
  isPositionClosed: boolean;
}

const UnderlyingPositionSummary: FunctionComponent<Props> = ({ position, isPositionClosed }: Props) => {
  const { marketPrice, realizedPnl, orderAction, orderSize } = position;

  if (isPositionClosed) {
    const realizedAmountFormatted = `$${setThousenSeparator(realizedPnl.toFixed(0))}`;
    return (
      <Box sx={{ width: '100%', px: 2, display: 'flex', gap: 1, justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography variant="caption">{`${orderAction} ${orderSize}`}</Typography>
        </Box>
        <Box>
          <Typography variant="caption">
            <span style={{ color: realizedPnl > 0 ? INDICATOR_GREEN_COLOR : realizedPnl < 0 ? INDICATOR_RED_COLOR : 'inherit' }}>
              {realizedAmountFormatted}
            </span>
          </Typography>
        </Box>
      </Box>
    );
  }

  const durationPct = getRaceDuractionPct(position);

  const raceSetup: RaceSetup = {
    durationPct,
    lossAreaPct: 50
  };

  const currentPriceFormatted = formatNum(marketPrice);
  const lossPct = position.unrealizedLossPct ?? 0;
  const lossPctFormated = lossPct ? `${setDecimalSpaces(lossPct, 0)}%` : '-';
  const profitPct = position.unrealizedProfitPct ?? 0;
  const profitPctFormated = profitPct ? `${setDecimalSpaces(profitPct, 0)}%` : '-';

  const underlyingDriver = getUnderlyingRaceDriver(position, profitPct, lossPct);
  const drivers: RaceDriver[] = [];
  if (underlyingDriver) {
    drivers.push(underlyingDriver);
  }

  return (
    <Box width="100%" sx={{ p: 1 }}>
      <RaceTrack drivers={drivers} setup={raceSetup} />
      <Grid container width="100%" sx={{ mt: 1 }}>
        <Grid item xs={4} sx={{ textAlign: 'center' }}>
          <Typography variant="caption" sx={{ color: INDICATOR_RED_COLOR }}>
            {lossPctFormated}
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: 'center' }}>
          <Typography variant="caption" sx={{ color: (theme) => theme.palette.grey[200] }}>
            {currentPriceFormatted}
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: 'center' }}>
          <Typography variant="caption" sx={{ color: INDICATOR_GREEN_COLOR }}>
            {profitPctFormated}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UnderlyingPositionSummary;
