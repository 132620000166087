import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import PosGroupIcon from '@mui/icons-material/CreateNewFolder';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';

import GroupScheduleDialog from '../../components/dialogs/GroupScheduleDialog';
import PositionGroupDialog from '../../components/dialogs/PositionGroupDialog';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setGroupId } from '../../store/order-summary/reducer';
import { getPositionGroupsAsync } from '../../store/position-group/service';

interface Props {
  symbol: string;
  groupId: string | undefined;
}

const PositionGroupPicker: FunctionComponent<Props> = ({ symbol, groupId }: Props) => {
  const [showPositionGroupDialog, setShowPositionGroupDialog] = useState(false);
  const handleAddGroupBtnClick = () => setShowPositionGroupDialog(true);
  const closeShowPositionGroupDialog = () => setShowPositionGroupDialog(false);

  const [showGroupScheduleDialog, setShowGroupScheduleDialog] = useState(false);
  const closeGroupScheduleDialog = () => setShowGroupScheduleDialog(false);

  const { groups, loaded } = useAppSelector((gs) => gs.positionGroupState);
  const selectedGroup = groups.find((x) => x.id === groupId);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loaded) {
      dispatch(getPositionGroupsAsync());
    }
  }, []);

  const handleGroupChanged = (id: string) => dispatch(setGroupId({ symbol: symbol || '', groupId: id }));

  const groupMenuItems = useMemo(() => {
    return groups.map((x) => (
      <MenuItem key={x.id} value={x.id}>
        {x.title}
      </MenuItem>
    ));
  }, [groups]);

  const posGroupPicker = useMemo(() => {
    return (
      <>
        <Typography variant="caption" component="div" sx={{ mb: 2 }}>
          SELECT POSITION GROUP
        </Typography>
        <Box sx={{ display: 'flex', width: '100%', gap: 1, justifyContent: 'stretch', alignItems: 'center' }}>
          <Box sx={{ flexGrow: 1 }}>
            <FormControl fullWidth>
              <InputLabel size="small">Position Group</InputLabel>
              <Select
                name="group-label"
                labelId="group-label"
                value={groupId ?? ''}
                label="Position Group"
                onChange={(e) => handleGroupChanged(e.target.value)}
                size="small"
              >
                <MenuItem key="" value="">
                  Velg..
                </MenuItem>
                {}
                {groupMenuItems}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <Tooltip title="Edit Group Schedule" placement="top">
              <span>
                <Button variant="outlined" color="primary" onClick={() => setShowGroupScheduleDialog(true)} disabled={!selectedGroup}>
                  <TimelapseIcon />
                </Button>
              </span>
            </Tooltip>
          </Box>
          <Box>
            <Tooltip title="Add Position Group" placement="top">
              <Button variant="outlined" color="primary" onClick={handleAddGroupBtnClick}>
                <PosGroupIcon />
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </>
    );
  }, [groupId, groups]);

  return (
    <>
      {posGroupPicker}
      <PositionGroupDialog isOpen={showPositionGroupDialog} onClose={closeShowPositionGroupDialog} />
      {showGroupScheduleDialog && selectedGroup && (
        <GroupScheduleDialog group={selectedGroup} isOpen={showGroupScheduleDialog} onClose={closeGroupScheduleDialog} />
      )}
    </>
  );
};

export default PositionGroupPicker;
