import React, { FunctionComponent } from 'react';

import { Box, Typography } from '@mui/material';

import GutterBox from '../ui/GutterBox';

interface Props {
  step: number;
  activeStep: number;
  label?: string;
  children?: React.ReactNode;
}

const WizardStep: FunctionComponent<Props> = ({ step, activeStep, label, children }: Props) => {
  return step === activeStep ? (
    <Box>
      {label && (
        <GutterBox>
          <Typography sx={{ my: 2 }} variant="h6">
            {label}
          </Typography>
        </GutterBox>
      )}
      {children}
    </Box>
  ) : null;
};

export default WizardStep;
