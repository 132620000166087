import { OptionRight } from '../../types/entities';

import { OptionChainState, OptionChainStateByComposite, initialOptionChainStateByConIdAndRight } from './state';
import { getOptionChainId } from './utils';

export const getOptionChainStateByComposite = (
  optionChainState: OptionChainState,
  conId: number,
  lastTradeDate: string,
  right: OptionRight
): OptionChainStateByComposite => {
  const id = getOptionChainId({ conId, lastTradeDate, right });
  return optionChainState[id] || { ...initialOptionChainStateByConIdAndRight };
};
