import { FunctionComponent, useState } from 'react';

import { Box, Dialog, DialogContent, DialogTitle, Tab, Tabs, tabsClasses, Typography } from '@mui/material';

import { GRAPH_PAGE_HOUR_CHART_ID } from '../../../assets/constants';
import { useAppSelector } from '../../../store/hooks';
import { Position } from '../../../types/entities';
import { BarSize } from '../../../types/enums';
import BarChartPanel from '../../panels/BarChartPanel';
import OptionVolatilityPanel from '../../panels/OptionVolatilityPanel';
import TimelinePanel from '../../panels/TimelinePanel';
import DialogCloseIconButton from '../../ui/DialogCloseIconButton';

import './styles.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  position: Position;
}

const PositionGraphDialog: FunctionComponent<Props> = ({ isOpen, onClose, position }: Props) => {
  const isUnderlying = position.secType === 'FUT' || position.secType === 'STK';
  const entryPrice = (isUnderlying ? position.entryPrice : position.underEntryPrice) ?? 0;
  const conId = isUnderlying ? position.conId : position.underConId;
  const symbol = isUnderlying ? position.symbol : position.underSymbol;

  const openOrderState = useAppSelector((gs) => gs.openOrderState);
  const openLimitOrder = openOrderState.orders.find((x) => x.conId === position.conId && x.triggerPrice > entryPrice);
  const openStopOrder = openOrderState.orders.find((x) => x.conId === position.conId && x.triggerPrice < entryPrice);
  const limitOrder = position.size > 0 ? openLimitOrder : openStopOrder;
  const stopOrder = position.size < 0 ? openLimitOrder : openStopOrder;

  const isOption = !!position.right;

  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (_e: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Dialog fullWidth open={isOpen} maxWidth="xl" onClose={onClose} classes={{ paper: 'graph-dialog__paper' }}>
      <DialogTitle sx={{ m: 0, pl: 1, pt: 2 }}>
        <Typography variant="body2">
          <b>{`${symbol} - ${position.localSymbol}`}</b>
        </Typography>
      </DialogTitle>
      <DialogCloseIconButton onClose={onClose} />
      {isOption && (
        <Box sx={{ mx: 1 }}>
          <OptionVolatilityPanel
            orderAction={position.orderAction}
            entryToExpireHistVlt={position.entryToExpireHistVlt}
            entryToExpireImpliedVlt={position.entryToExpireImpliedVlt}
            entryToStrikeVlt={position.entryToStrikeVlt}
            entryToDateVlt={position.entryToDateVlt}
          />
        </Box>
      )}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          mt: 1,
          mx: 1,
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 }
          }
        }}
      >
        <Tab label="Barchart" />
        <Tab label="History" />
      </Tabs>

      <DialogContent sx={{ p: 0 }}>
        {selectedTab === 0 ? (
          <Box sx={{ mt: 2 }}>
            <BarChartPanel
              barChartId={GRAPH_PAGE_HOUR_CHART_ID}
              conId={conId}
              exchange={position.exchange}
              title={''}
              strikePrice={position.strikePrice}
              entryPrice={entryPrice}
              entryDt={position.entryDate}
              limitPrice={limitOrder?.triggerPrice}
              stopPrice={stopOrder?.triggerPrice}
              action={position.orderAction}
              initialBarSize={BarSize._1_HR}
              initialDuration={5}
            />
          </Box>
        ) : (
          <TimelinePanel position={position} />
        )}
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default PositionGraphDialog;
