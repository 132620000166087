import { FunctionComponent } from 'react';

import { Box, Button, ButtonGroup } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setSelectedCategory, setSelectedContractSection } from '../../../store/user-settings/reducer';
import { ContractDetails, FavoriteContract } from '../../../types/entities';
import { SecurityCategory } from '../../../types/enums';

import ContractDetailsPanel from './ContractDetailsPanel';
import FavoritesPanel from './FavoritesPanel';

interface Props {
  selected: ContractDetails | undefined;
  onSelectionChanged: (selected: ContractDetails | undefined) => void;
}

const ContractDetailsPicker: FunctionComponent<Props> = ({ selected, onSelectionChanged }: Props) => {
  const dispatch = useAppDispatch();
  const { selectedContractSection } = useAppSelector((gs) => gs.userSettingsState);
  const handleContractSectionBtnClick = (section: 'all-contracts' | 'front-runners') => {
    dispatch(setSelectedContractSection(section));
    if (section === 'all-contracts') {
      dispatch(setSelectedCategory(SecurityCategory.Stock));
    } else if (section === 'front-runners') {
      dispatch(setSelectedCategory(SecurityCategory.Futures));
    }
  };

  const handleFavoriteSelected = (fav: FavoriteContract | undefined) => {
    const result = fav ? fav.details : undefined;
    onSelectionChanged(result);
  };

  const handleContractSelected = (details: ContractDetails | undefined) => {
    onSelectionChanged(details);
  };

  return (
    <Box>
      <ButtonGroup variant="outlined" disableElevation fullWidth>
        <Button
          onClick={() => handleContractSectionBtnClick('all-contracts')}
          variant={selectedContractSection === 'all-contracts' ? 'contained' : 'outlined'}
        >
          All Contracts
        </Button>
        <Button
          onClick={() => handleContractSectionBtnClick('front-runners')}
          variant={selectedContractSection === 'front-runners' ? 'contained' : 'outlined'}
        >
          Front Runners
        </Button>
      </ButtonGroup>

      <Box sx={{ mt: 2 }}>
        {selectedContractSection === 'all-contracts' && (
          <ContractDetailsPanel selected={selected} onSelectionChanged={handleContractSelected} />
        )}
        {selectedContractSection === 'front-runners' && <FavoritesPanel selected={selected} onFavoriteSelected={handleFavoriteSelected} />}
      </Box>
    </Box>
  );
};

export default ContractDetailsPicker;
