import { FunctionComponent } from 'react';

import { Box, Container, Tab, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { CustomTabPanel } from '../../components/ui/CustomTabPanel';
import ResponsiveContainer from '../../components/ui/ResponsiveContainer';
import WrappingTabs from '../../components/ui/WrappingTabs';

export const toTabIndex = (tabValue: string) => {
  switch (tabValue.toLowerCase()) {
    case 'underlying':
      return 1;
    default:
      return 0;
  }
};

export const fromTabIndex = (index: number) => {
  switch (index) {
    case 1:
      return 'underlying';
    default:
      return 'option';
  }
};

interface Props {
  selectedTab: string;
  orderType: string;
}

const OrderTabList: FunctionComponent<Props> = ({ selectedTab, orderType }: Props) => {
  const navigate = useNavigate();
  const tabIndex = toTabIndex(selectedTab);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    const tabValue = fromTabIndex(newValue);
    navigate(`?tab=${tabValue}`);
  };

  return (
    <>
      <ResponsiveContainer>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <WrappingTabs value={tabIndex} onChange={handleChange}>
              <Tab label="Option" />
              <Tab label="Underlying" />
            </WrappingTabs>
          </Box>
        </Box>
      </ResponsiveContainer>

      <Container sx={{ mt: 3, mb: 3 }}>
        <CustomTabPanel value={tabIndex} index={0}>
          <Typography variant="h5">{`Option ${orderType === 'MKT' ? 'Entry' : 'Exit'} Order`}</Typography>
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={1}>
          <Typography variant="h5">Underlying Order</Typography>
        </CustomTabPanel>
      </Container>
    </>
  );
};

export default OrderTabList;
