import { INDICATOR_GREEN_COLOR, INDICATOR_RED_COLOR } from '../../../assets/colors';
import { ContractMarketData, OpenOrderReport, Position } from '../../../types/entities';

export const getPremiumColor = (pos: Position) => {
  if (pos.premium == 0) {
    return 'Inherit';
  }
  return pos.orderAction === 'BUY' ? INDICATOR_RED_COLOR : INDICATOR_GREEN_COLOR;
};

export const orderOrPositionExists = (positions: Position[], openOrders: OpenOrderReport[], order: Position) => {
  const position = positions.find((x) => x.conId === order.conId && !x.completed && x.size != 0);
  if (position) {
    return true;
  }
  const openOrder = openOrders.find((x) => x.conId === order.conId);
  return !!openOrder;
};

export const getMarketPrice = (marketData: ContractMarketData) => {
  if (marketData.lastPrice) {
    return marketData.lastPrice;
  }
  const sum = marketData.askPrice + marketData.bidPrice;
  if (sum !== 0) {
    return sum / 2;
  }
  return undefined;
};
