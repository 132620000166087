export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const getMonthName = (oneBasedIndex: number) => {
  const name = months[oneBasedIndex - 1];
  return name || 'N/A';
};
