import { AuthToken } from '../types/entities';

export const LOCAL_STORAGE_TOKEN = 'orcastrike.token';
const LOCAL_STORAGE_EXPIRES_TIME = 'orcastrike.expires_time';
const LOCAL_STORAGE_ACCOUNT_ID = 'orcastrike.account_id';
const LOCAL_STORAGE_USERNAME = 'orcastrike.username';
const LOCAL_STORAGE_FIRSTNAME = 'orcastrike.firstname';

export const toLocalStorage = (authToken: AuthToken) => {
  localStorage.setItem(LOCAL_STORAGE_TOKEN, authToken.accessToken);
  if (authToken.expires) {
    localStorage.setItem(LOCAL_STORAGE_EXPIRES_TIME, authToken.expires);
  }
  localStorage.setItem(LOCAL_STORAGE_ACCOUNT_ID, authToken.userId);
  localStorage.setItem(LOCAL_STORAGE_USERNAME, authToken.userName);
  localStorage.setItem(LOCAL_STORAGE_FIRSTNAME, authToken.firstName);
};

export const clearLocalStorage = () => {
  localStorage.removeItem(LOCAL_STORAGE_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_EXPIRES_TIME);
  localStorage.removeItem(LOCAL_STORAGE_ACCOUNT_ID);
  localStorage.removeItem(LOCAL_STORAGE_USERNAME);
  localStorage.removeItem(LOCAL_STORAGE_FIRSTNAME);
};

export const fromLocalStorage = (): AuthToken => {
  const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
  const expires = localStorage.getItem(LOCAL_STORAGE_EXPIRES_TIME);
  return {
    accessToken: token,
    userId: localStorage.getItem(LOCAL_STORAGE_ACCOUNT_ID),
    userName: localStorage.getItem(LOCAL_STORAGE_USERNAME),
    firstName: localStorage.getItem(LOCAL_STORAGE_FIRSTNAME),
    expires
  } as AuthToken;
};
