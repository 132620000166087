import { FunctionComponent, useEffect } from 'react';

import { Box, Button, Card, CircularProgress, Skeleton, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getContractsByUnderlyingSymbolState } from '../../../store/security-contract/selectors';
import { getSecurityContractsAsync } from '../../../store/security-contract/service';
import { setSelectedSecurity } from '../../../store/user-settings/reducer';
import { ContractDetails } from '../../../types/entities';
import { Security } from '../../../types/entities';
import useCategorySecurities from '../../hooks/useCategorySecurities';
import SecurityCategoryTabs from '../../ui/SecurityCategoryTabs';

import ContractDetailsTable from './ContractDetailsTable';

interface Props {
  selected?: ContractDetails | undefined;
  onSelectionChanged: (contract: ContractDetails | undefined) => void;
  as?: 'card' | 'box' | undefined;
}

const ContractDetailsPanel: FunctionComponent<Props> = ({ selected, onSelectionChanged, as = 'card' }: Props) => {
  const selectedConId = selected?.contract?.conId || 0;
  const { securities } = useAppSelector((gs) => gs.securityState);
  const filteredSecurities = useCategorySecurities();
  const { selectedSecurity } = useAppSelector((gs) => gs.userSettingsState);

  const dispatch = useAppDispatch();

  const handleSelectSecurityBtnClick = (sec: Security | undefined) => {
    onSelectionChanged(undefined);
    dispatch(setSelectedSecurity(sec));
  };

  const conFutState = useAppSelector((gs) => gs.securityContractState);
  const { contracts, initialLoaded, loaded, loading } = getContractsByUnderlyingSymbolState(conFutState, selectedSecurity?.symbol || '');

  const onCategoryChanged = () => {
    onSelectionChanged(undefined);
  };

  useEffect(() => {
    if (!loaded) {
      handleReloadContracts();
    }
  }, [selectedSecurity]);

  useEffect(() => {
    if (selected) {
      const security = securities.find((x) => x.symbol === selected.contract.symbol);
      const needsUpdate = !!security && (!selectedSecurity || security.symbol !== selectedSecurity.symbol);
      if (needsUpdate) {
        dispatch(setSelectedSecurity(security));
      }
    }
  }, [selected]);

  const handleReloadContracts = () => {
    if (selectedSecurity) {
      dispatch(getSecurityContractsAsync(selectedSecurity));
    }
  };

  const handleSelectContractDetails = (details: ContractDetails) => {
    onSelectionChanged(details);
  };

  if (!selectedSecurity) {
    const buttons = filteredSecurities.map((x) => (
      <Button key={x.symbol} onClick={() => handleSelectSecurityBtnClick(x)} variant="outlined">
        {x.name}
      </Button>
    ));
    return (
      <Box>
        <Typography sx={{ px: 2 }} variant="caption" component="div">
          SELECT SECURITY
        </Typography>
        <SecurityCategoryTabs onCategoryChanged={onCategoryChanged} />
        {as === 'card' && <Card sx={{ display: 'flex', flexWrap: 'wrap', p: 2, gap: 2 }}>{buttons}</Card>}
        {as === 'box' && <Box sx={{ display: 'flex', flexWrap: 'wrap', p: 2, gap: 2 }}>{buttons}</Box>}
      </Box>
    );
  }

  const selectContractContent = (
    <>
      {!initialLoaded ? (
        <Skeleton sx={{ m: 2 }} variant="rounded" animation="wave" height={50} />
      ) : (
        <ContractDetailsTable list={contracts} maxRows={6} selectedConId={selectedConId} onSelect={handleSelectContractDetails} />
      )}

      <Box sx={{ m: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button size="small" color="warning" variant="outlined" onClick={() => handleSelectSecurityBtnClick(undefined)}>
          Go Back
        </Button>
        <Button size="small" variant="outlined" onClick={handleReloadContracts}>
          Reload Contracts {loading && <CircularProgress sx={{ ml: 1 }} color="inherit" size="1rem" />}
        </Button>
      </Box>
    </>
  );

  return (
    <Box>
      <Typography sx={{ px: 2 }} variant="caption" component="div">
        SELECT CONTRACT
      </Typography>

      {as === 'card' && <Card sx={{ mt: 1, pt: 1 }}>{selectContractContent}</Card>}
      {as === 'box' && <Box sx={{ mt: 1, pt: 1 }}>{selectContractContent}</Box>}
    </Box>
  );
};

export default ContractDetailsPanel;
