import { FunctionComponent } from 'react';

import { Box, Grid, Tooltip, Typography } from '@mui/material';

import { GOLD_COLOR, INDICATOR_GREEN_COLOR, INDICATOR_RED_COLOR } from '../../../../../../assets/colors';
import RaceTrack, { RaceDriver, RaceSetup } from '../../../../../../components/panels/RaceTrack';
import { useAppSelector } from '../../../../../../store/hooks';
import { Position } from '../../../../../../types/entities';
import { formatNum, setDecimalSpaces, setThousenSeparator } from '../../../../../../utils/currency-utils';

import { getRaceDuractionPct, getStopLossPct, getOptionRaceDriver } from './utils';

interface Props {
  position: Position;
  isPositionClosed: boolean;
}

const OptionPositionSummary: FunctionComponent<Props> = ({ position, isPositionClosed }: Props) => {
  const { underMarketPrice, strikePrice, orderAction, right, orderSize, realizedPnl } = position;
  const openOrderState = useAppSelector((gs) => gs.openOrderState);
  const optionOpenOrder = openOrderState.orders.find((x) => x.conId === position.conId);

  if (isPositionClosed) {
    const realizedAmountFormatted = `$${setThousenSeparator(realizedPnl.toFixed(0))}`;
    return (
      <Box sx={{ width: '100%', px: 2, display: 'flex', gap: 1, justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography variant="caption">{`${orderAction} ${orderSize} ${right}`}</Typography>
        </Box>
        <Box>
          <Typography variant="caption">
            <span style={{ color: realizedPnl > 0 ? INDICATOR_GREEN_COLOR : realizedPnl < 0 ? INDICATOR_RED_COLOR : 'inherit' }}>
              {realizedAmountFormatted}
            </span>
          </Typography>
        </Box>
      </Box>
    );
  }

  const isSellOption = orderAction !== 'BUY';
  let stopPriceFormatted = '',
    limitPriceFormatted = '';
  if (isSellOption) {
    stopPriceFormatted = '' + formatNum(strikePrice);
  } else {
    limitPriceFormatted = '' + formatNum(strikePrice);
  }

  const durationPct = getRaceDuractionPct(position);

  let stopLossPct: number | undefined;
  if (isSellOption) {
    if (optionOpenOrder) {
      stopLossPct = getStopLossPct(position, optionOpenOrder.triggerPrice);
    }
  }

  const raceSetup: RaceSetup = {
    durationPct,
    lossAreaPct: 50,
    stopLossPct
  };

  const optionDriver = getOptionRaceDriver(position);
  const drivers: RaceDriver[] = [];
  if (optionDriver) {
    drivers.push(optionDriver);
  }

  const lossPct = position.unrealizedLossPct;
  const lossPctFormatted = lossPct ? `${setDecimalSpaces(lossPct, 0)}%` : '-';
  const profitPct = position.unrealizedProfitPct;
  const profitPctFormatted = profitPct ? `${setDecimalSpaces(profitPct, 0)}%` : '-';

  let moneyStatus: JSX.Element | undefined;
  if (underMarketPrice && underMarketPrice !== 0) {
    const inTheMoney = (right === 'CALL' && underMarketPrice > strikePrice) || (right === 'PUT' && underMarketPrice < strikePrice);

    moneyStatus = (
      <Tooltip title={inTheMoney ? 'ITM' : 'OTM'} placement="right">
        <Typography variant="caption" sx={{ color: inTheMoney ? GOLD_COLOR : 'inherit' }}>
          {formatNum(underMarketPrice)}
        </Typography>
      </Tooltip>
    );
  }

  return (
    <Box width="100%" sx={{ p: 1 }}>
      <RaceTrack drivers={drivers} setup={raceSetup} />
      <Grid container width="100%" sx={{ mt: 1 }}>
        <Grid item xs={3}>
          <Typography variant="caption">{stopPriceFormatted}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="caption" sx={{ color: INDICATOR_RED_COLOR }}>
            {lossPctFormatted}
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: 'center' }}>
          <Typography variant="caption">{moneyStatus}</Typography>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: 'right' }}>
          <Typography variant="caption" sx={{ color: INDICATOR_GREEN_COLOR }}>
            {profitPctFormatted}
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ textAlign: 'right' }}>
          <Typography variant="caption">{limitPriceFormatted}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OptionPositionSummary;
