import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FoundationDataState, initialState } from './state';

const dashboaredSlice = createSlice({
  name: 'foundation-data',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    loading: (state: FoundationDataState, action: PayloadAction<boolean>) => {
      state.error = undefined;
      state.loading = action.payload;
      state.loaded = !action.payload;
    },
    loaded: (state: FoundationDataState, action: PayloadAction<boolean>) => {
      state.error = undefined;
      state.loaded = action.payload;
    },
    fail: (state: FoundationDataState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.loaded = true;
    }
  }
});

const { actions, reducer } = dashboaredSlice;
export const { reset, loading, loaded, fail } = actions;
export default reducer;
