import { FunctionComponent } from 'react';

import { Position } from '../../../../../../types/entities';

import OptionPositionSummary from './OptionPositionSummary';
import UnderlyingPositionSummary from './UnderlyingPositionSummary';

interface Props {
  position: Position;
  isPositionClosed: boolean;
}

const PositionSummary: FunctionComponent<Props> = ({ position, isPositionClosed }: Props) => {
  const isUnderlying = position.secType === 'FUT' || position.secType === 'STK';
  return isUnderlying ? (
    <UnderlyingPositionSummary position={position} isPositionClosed={isPositionClosed} />
  ) : (
    <OptionPositionSummary position={position} isPositionClosed={isPositionClosed} />
  );
};

export default PositionSummary;
