import { toast } from 'react-toastify';

import { fetchMarketData as fetchMarketDataApiCall } from '../../api';
import { ContractMarketData, MarketDataParams, Position } from '../../types/entities';

export const useMarketDataQuery = () => {
  const fetchMarketData = (pos: Position, useFrozen: boolean, pricesOnly = true) => {
    const params: MarketDataParams = {
      conId: pos.conId,
      symbol: pos.symbol,
      localSymbol: pos.localSymbol,
      exchange: pos.exchange,
      currency: 'USD',
      secType: pos.secType,
      right: pos.right,
      strike: pos.strikePrice,
      lastTradeDateOrContractMonth: pos.lastTradeDateOrContractMonth,
      multiplier: pos.multiplier,
      noIndicators: true,
      pricesOnly,
      useFrozen
    };

    return new Promise<ContractMarketData>((resolve, reject) => {
      fetchMarketDataApiCall(params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          const msg = error.response?.data.message || error.message;
          toast.error(msg);
          reject();
        });
    });
  };

  return fetchMarketData;
};
