import { FunctionComponent, MouseEvent } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Button, Card, Skeleton, Typography } from '@mui/material';

import { removeFavoriteAsync } from '../../../store/favorite-contract/service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ContractDetails, FavoriteContract } from '../../../types/entities';
import useFrontRunners from '../../hooks/useFrontRunners';

interface Props {
  selected: ContractDetails | undefined;
  onFavoriteSelected: (fav: FavoriteContract | undefined) => void;
}

const FavoritesPanel: FunctionComponent<Props> = ({ selected, onFavoriteSelected }) => {
  const { loading } = useAppSelector((gs) => gs.favoriteContractState);
  const selectedConId = selected?.contract?.conId || -1;

  const dispatch = useAppDispatch();
  const RemoveFrontRunnerIconClick = (e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>, fav: FavoriteContract) => {
    dispatch(removeFavoriteAsync(fav));
    e.stopPropagation();
  };

  const handleFavBtnClick = (fav: FavoriteContract) => {
    const clickedConId = fav.details.contract.conId;
    if (clickedConId === selectedConId) {
      onFavoriteSelected(undefined);
    } else {
      onFavoriteSelected(fav);
    }
  };

  const filteredFavorites = useFrontRunners();

  const favoriteItems = filteredFavorites.map((x, i) => {
    const favConId = x.details.contract.conId;
    const favLocalSymbol = x.details.contract.localSymbol;

    return (
      <Button sx={{ pr: 1 }} key={i} onClick={() => handleFavBtnClick(x)} variant={favConId === selectedConId ? 'contained' : 'outlined'}>
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}>
          {`${x.securityName} - ${favLocalSymbol}`} <CancelIcon onClick={(e) => RemoveFrontRunnerIconClick(e, x)} />
        </Box>
      </Button>
    );
  });

  return (
    <Card sx={{ p: 2 }}>
      {loading ? (
        <Skeleton variant="rounded" animation="wave" height={80} />
      ) : (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          {favoriteItems}
          {favoriteItems.length === 0 && (
            <Typography fontSize="smaller" component="div">
              No contracts added..
            </Typography>
          )}
        </Box>
      )}
    </Card>
  );
};

export default FavoritesPanel;
