import { FunctionComponent } from 'react';

import { Tab } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setSelectedCategory } from '../../store/user-settings/reducer';
import { SecurityCategory } from '../../types/enums';

import WrappingTabs from './WrappingTabs';

interface Props {
  onCategoryChanged?: (cat: SecurityCategory) => void;
}

const SecurityCategoryTabs: FunctionComponent<Props> = ({ onCategoryChanged }: Props) => {
  const { selectedSecurityCategory } = useAppSelector((gs) => gs.userSettingsState);
  const dispatch = useAppDispatch();

  const handleTabChange = (_event: React.SyntheticEvent, newValue: SecurityCategory) => {
    dispatch(setSelectedCategory(newValue));
    if (onCategoryChanged) {
      onCategoryChanged(newValue);
    }
  };

  return (
    <WrappingTabs value={selectedSecurityCategory} onChange={handleTabChange} aria-label="Security Categories">
      <Tab value={SecurityCategory.Stock} label="Stocks" />
      <Tab value={SecurityCategory.Futures} label="Futures" />
      <Tab value={SecurityCategory.MicroFutures} label="Micro Futures" />
    </WrappingTabs>
  );
};

export default SecurityCategoryTabs;
