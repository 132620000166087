import { FunctionComponent, useEffect } from 'react';

import { Box, Skeleton } from '@mui/material';

import NewsLinkTable from '../../../../components/panels/NewsPanel/NewsLinkTable';
import AccordionWrapper from '../../../../components/ui/AccordionWrapper';
import { getFundamentalNotesAsync } from '../../../../store/fundamental-note/service';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { setNewsPanelCollapsed } from '../../../../store/user-settings/reducer';

import SeasonalAccordion from './SeasonalAccordion';

const FundamentalsPanel: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const { securities } = useAppSelector((gs) => gs.securityState);
  const { newsPanelCollapsed } = useAppSelector((gs) => gs.userSettingsState);

  const toggleNewsPanelCollapsed = (_e: React.SyntheticEvent<Element, Event>, expanded: boolean) =>
    dispatch(setNewsPanelCollapsed(!expanded));

  const { loading, loaded, notes } = useAppSelector((gs) => gs.fundamentalNoteState);

  useEffect(() => {
    if (!loading && !loaded) {
      dispatch(getFundamentalNotesAsync());
    }
  }, []);

  return (
    <Box>
      <AccordionWrapper title="News" collapsed={newsPanelCollapsed} onChange={toggleNewsPanelCollapsed}>
        <NewsLinkTable readOnly />
      </AccordionWrapper>
      {loading ? <Skeleton variant="rounded" animation="wave" height={80} /> : <SeasonalAccordion securities={securities} notes={notes} />}
    </Box>
  );
};

export default FundamentalsPanel;
