import React, { FunctionComponent } from 'react';

import { Box, Container, Tab, Tabs, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CustomTabPanel } from '../../components/ui/CustomTabPanel';
import ResponsiveContainer from '../../components/ui/ResponsiveContainer';

import OptionOrder from './Option';
import UnderlyingOrder from './Underlying';

const OrderPage: FunctionComponent = () => {
  const toTabIndex = (tabValue: string) => {
    switch (tabValue.toLowerCase()) {
      case 'underlying':
        return 1;
      default:
        return 0;
    }
  };

  const fromTabIndex = (index: number) => {
    switch (index) {
      case 1:
        return 'underlying';
      default:
        return 'option';
    }
  };

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get('tab') ?? '';
  const tabIndex = toTabIndex(selectedTab);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    // setValue(newValue);
    const tabValue = fromTabIndex(newValue);
    navigate(`?tab=${tabValue}`);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  return (
    <div>
      <Container>
        <Typography variant="h4" sx={{ my: 3 }}>
          Orders
        </Typography>
      </Container>
      <ResponsiveContainer>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
            <Tabs
              value={tabIndex}
              onChange={handleChange}
              sx={{
                '& .MuiTabs-flexContainer': {
                  flexWrap: 'wrap'
                }
              }}
            >
              <Tab label="Option" {...a11yProps(0)}></Tab>
              <Tab label="Underlying" {...a11yProps(1)}></Tab>
            </Tabs>
          </Box>
        </Box>
      </ResponsiveContainer>

      <ResponsiveContainer>
        <CustomTabPanel value={tabIndex} index={0}>
          <OptionOrder />
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={1}>
          <UnderlyingOrder />
        </CustomTabPanel>
      </ResponsiveContainer>
    </div>
  );
};

export default OrderPage;
