import { FunctionComponent, useState } from 'react';

import { ContractDetails, OptionContract, OptionRight, OrderAction, TradingClass } from '../../../types/entities';

import OptionChainPanel from './OptionChainPanel';
import OptionTypePanel from './OptionTypePanel';
import TradingClassPanel from './TradingClassPanel';

interface Props {
  underlying: ContractDetails;
  selected: OptionContract | undefined;
  onOptionSelected: (opt: OptionContract | undefined) => void;
  optionRight: OptionRight;
  onOptionRightChanged: (right: OptionRight) => void;
  orderAction: OrderAction;
  onOrderActionChanged: (oa: OrderAction) => void;
}

const ContractOptionPanel: FunctionComponent<Props> = ({
  underlying,
  selected,
  onOptionSelected,
  optionRight,
  onOptionRightChanged,
  orderAction,
  onOrderActionChanged
}: Props) => {
  const [selectedTradingClass, setSelectedTradingClass] = useState<TradingClass | undefined>();
  const goBack = () => setSelectedTradingClass(undefined);

  return (
    <>
      <OptionTypePanel
        orderAction={orderAction}
        onOrderActionChanged={onOrderActionChanged}
        optionRight={optionRight}
        onOptionRightChanged={onOptionRightChanged}
      />
      {!selectedTradingClass && <TradingClassPanel underlying={underlying} onSelect={setSelectedTradingClass} />}
      {selectedTradingClass && (
        <OptionChainPanel
          underlying={underlying}
          tradingClass={selectedTradingClass}
          goBack={goBack}
          right={optionRight}
          action={orderAction}
          selected={selected}
          onOptionSelected={onOptionSelected}
        />
      )}
    </>
  );
};

export default ContractOptionPanel;
