import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, CircularProgress, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';

import { TABLE_BORDER_COLOR } from '../../../assets/colors';
import { useContractDetailsQuery } from '../../../components/hooks/useContractDetailsQuery';
import { useMarketDataQuery } from '../../../components/hooks/useMarketDataQuery';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { placeOptionEntryOrderAsync as placeOptionEntryOrderAsync, placeOptionExitOrderAsync } from '../../../store/order-option/service';
import { removeSummaryOrder, updateSummaryOrder } from '../../../store/order-summary/reducer';
import { placeUnderlyingEntryOrderAsync, placeUnderlyingExitOrderAsync } from '../../../store/order-underlying/service';
import { ContractMarketData, OpenOrderReport, Position } from '../../../types/entities';
import { formatNum, setThousenSeparator } from '../../../utils/currency-utils';
import { Logger } from '../../../utils/Logger';

import EditOrderDialog from './EditOrderDialog';
import { getPremiumColor } from './utils';

interface Props {
  order: Position;
  groupId: string | undefined;
  onRowClick: (pos: Position | undefined) => void;
  orderSelected: Position | undefined;
  openOrders: OpenOrderReport[] | undefined;
}

const OrderRow: FunctionComponent<Props> = ({ order, groupId, onRowClick, orderSelected, openOrders = [] }: Props) => {
  const dispatch = useAppDispatch();
  const query = useContractDetailsQuery();
  const { useFrozen } = useAppSelector((gs) => gs.userSettingsState);
  const getMarketDataAsync = useMarketDataQuery();
  const premiumColor = getPremiumColor(order);

  const [marketPrice, setMarketPrice] = useState(order.marketPrice);

  useEffect(() => {
    setMarketPrice(order.marketPrice);
  }, [order]);

  const marketPriceLabel = useMemo(() => {
    if (order.secType === 'FUT' && marketPrice > 0 && !!order.multiplier) {
      const sum = marketPrice * Number(order.multiplier) * order.orderSize;
      return `$${setThousenSeparator(sum.toFixed(0))}`;
    } else if (order.secType === 'STK') {
      const sum = marketPrice * order.orderSize;
      return `$${setThousenSeparator(sum.toFixed(0))}`;
    }
    return '-';
  }, [marketPrice, order]);

  const premiumLabel = useMemo(() => {
    const isOption = order.secType === 'FOP' || order.secType === 'OPT';
    const premium = isOption ? marketPrice * Number(order.multiplier) : 0;
    const totalPremium = premium * order.orderSize;
    return totalPremium != 0 ? `$${setThousenSeparator(totalPremium.toFixed(0))}` : '-';
  }, [marketPrice, order]);

  const entryPriceLabel = useMemo(() => {
    if (marketPrice != 0 && order.orderType === 'MKT') {
      return `$${formatNum(marketPrice)}`;
    }
    return order.entryPrice != 0 ? `$${formatNum(order.entryPrice)}` : '-';
  }, [marketPrice, order]);

  const [, setPlaceOrderBtnClicked] = useState(false);
  const [refreshingMarketData, setRefreshingMarketData] = useState(false);
  const [showPositionEditDialog, setShowPositionEditDialog] = useState(false);
  const closePositionEditDialog = () => setShowPositionEditDialog(false);

  const onOrderChanged = (order: Position) => {
    dispatch(updateSummaryOrder({ symbol: order.symbol, order }));
  };

  const onOrderRemoved = (order: Position) => {
    dispatch(removeSummaryOrder({ symbol: order.symbol, conId: order.conId, orderType: order.orderType }));
  };

  const getMarketPrice = (marketData: ContractMarketData) => {
    if (marketData.lastPrice) {
      return marketData.lastPrice;
    }
    const sum = marketData.askPrice + marketData.bidPrice;
    if (sum !== 0) {
      return sum / 2;
    }
    return undefined;
  };

  const refreshMarketData = async (p: Position) => {
    const pos = { ...p };
    setRefreshingMarketData(true);
    try {
      const marketData = await getMarketDataAsync(pos, useFrozen);
      const updatedMarketPrice = getMarketPrice(marketData);
      if (updatedMarketPrice) {
        setMarketPrice(updatedMarketPrice);

        const entryPrice = pos.orderType === 'MKT' ? updatedMarketPrice : 0;
        const isOption = order.secType === 'FOP' || order.secType === 'OPT';
        const prem = isOption && pos.multiplier ? updatedMarketPrice * Number(pos.multiplier) : 0;
        onOrderChanged({
          ...pos,
          entryPrice: entryPrice,
          marketPrice: updatedMarketPrice,
          premium: prem
        });
      }
    } catch (error) {
      Logger.log(error);
    } finally {
      setRefreshingMarketData(false);
    }
  };

  const handleRowMouseClick = (pos: Position) => {
    const p = pos.conId !== orderSelected?.conId ? pos : undefined;
    onRowClick(p);
  };

  const handleRefreshBtnClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    await refreshMarketData(order);
  };

  const handleEditBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShowPositionEditDialog(true);
  };

  const handlePlaceOrderBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setPlaceOrderBtnClicked(true);
    const pos = {
      ...order,
      groupId: groupId || ''
    };
    const isExitOrder = pos.size != 0; // set by portfolio
    const isOptionOrder = pos.secType === 'FOP' || pos.secType === 'OPT';
    if (isOptionOrder) {
      if (isExitOrder) {
        dispatch(placeOptionExitOrderAsync(pos));
      } else {
        dispatch(placeOptionEntryOrderAsync(pos));
      }
    } else {
      if (isExitOrder) {
        dispatch(placeUnderlyingExitOrderAsync(pos));
      } else {
        dispatch(placeUnderlyingEntryOrderAsync(pos));
      }
    }
  };

  const handleRemoveOrderBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    dispatch(removeSummaryOrder({ symbol: order.symbol, conId: order.conId, orderType: order.orderType }));
  };

  const handleCreateLimitOrderBtnClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    await query.gotoExitOrderAsync(order);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isSumbitted = openOrders.filter((x) => x.type === order.orderType).length > 0;
  const disablePlaceBtn = false; // placeOrderBtnClicked || isSumbitted;

  return (
    <>
      <TableRow
        selected={order.conId === orderSelected?.conId}
        sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
        onClick={() => handleRowMouseClick(order)}
      >
        <TableCell>{order.secType}</TableCell>
        <TableCell>{order.underSymbol}</TableCell>
        <TableCell>{order.localSymbol}</TableCell>
        <TableCell align="center">{order.orderSize}</TableCell>
        <TableCell>{order.orderType}</TableCell>
        <TableCell>{order.orderAction}</TableCell>
        <TableCell align="center">{order.right || '-'}</TableCell>
        <TableCell align="right">{entryPriceLabel}</TableCell>
        <TableCell align="right">{marketPriceLabel}</TableCell>
        <TableCell align="right" sx={{ color: premiumColor }}>
          {premiumLabel}
        </TableCell>
        <TableCell align="right" sx={{ width: '50px', px: 1 }}>
          <Tooltip title="Refresh Price and Premium">
            <IconButton size="small" onClick={handleRefreshBtnClick}>
              <RefreshIcon sx={{ fontSize: 20 }} />
              {refreshingMarketData && (
                <CircularProgress
                  size={30}
                  sx={{
                    color: 'white',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1
                  }}
                />
              )}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell align="right" sx={{ width: '80px', px: 1, borderLeft: `1px solid ${TABLE_BORDER_COLOR}` }}>
          <span>
            <Button size="small" variant="outlined" onClick={handleEditBtnClick}>
              Edit
            </Button>
          </span>
        </TableCell>
        <TableCell align="right" sx={{ width: '80px', px: 1 }}>
          <Button size="small" color="warning" variant="outlined" disabled={order.orderSize === 0} onClick={handleCreateLimitOrderBtnClick}>
            LMT/STP
          </Button>
        </TableCell>
        <TableCell align="right" sx={{ width: '80px', px: 1 }}>
          <span>
            <Button size="small" color="success" variant="contained" onClick={handlePlaceOrderBtnClick} disabled={disablePlaceBtn}>
              Place
            </Button>
          </span>
        </TableCell>
        <TableCell align="right" sx={{ width: '80px', px: 1, borderLeft: `1px solid ${TABLE_BORDER_COLOR}` }}>
          <span>
            <Button size="small" color="error" variant="contained" onClick={handleRemoveOrderBtnClick}>
              Remove
            </Button>
          </span>
        </TableCell>
      </TableRow>
      <EditOrderDialog
        isOpen={showPositionEditDialog}
        onClose={closePositionEditDialog}
        position={order}
        onChange={onOrderChanged}
        onDelete={onOrderRemoved}
      />
    </>
  );
};

export default OrderRow;
