import React, { FunctionComponent } from 'react';

import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

interface Props {
  label: string;
  selectedValue: Date | undefined;
  onDateSelected: (val: Date | undefined) => void;
}

const SelectDateTimeControl: FunctionComponent<Props> = ({ label, selectedValue, onDateSelected }) => {
  const val = selectedValue ? dayjs(selectedValue) : undefined;
  const changeDateHandler = (val: dayjs.Dayjs | null) => {
    const newDate = val?.toDate();
    onDateSelected(newDate);
  };
  return <DateTimePicker sx={{ width: '100%' }} label={label} defaultValue={val} onChange={changeDateHandler} />;
};

export default SelectDateTimeControl;
