import { POSTION_ZEBRA_COLOR_EVEN, POSTION_ZEBRA_COLOR_ODD } from '../../../assets/constants';
import { Position, PositionGroup } from '../../../types/entities';

import PositionGroupPanel from './PositionGroupPanel';
import PositionPanel from './PositionPanel';

const sortPositionGroups = (a: PositionGroup, b: PositionGroup) => {
  if (!a || !b) {
    return 0;
  }
  return a.sortOrder < b.sortOrder ? 1 : -1;
};

export const sortPositions = (a: Position, b: Position) => {
  if (!a || !b) {
    return 0;
  }
  return a.sortOrder < b.sortOrder ? 1 : -1;
};

export const mapSortedPositionGroupPanels = (groups: PositionGroup[]) => {
  return groups
    .slice()
    .sort(sortPositionGroups)
    .map((cnt) => <PositionGroupPanel key={cnt.id} group={cnt} />);
};

export const mapSortedGrouplessPositionPanels = (grouplessPositions: Position[]) => {
  return grouplessPositions
    .filter((x) => !x.completed)
    .sort(sortPositions)
    .map((pos, index) => (
      <PositionPanel key={pos.id} position={pos} bg={index % 2 === 0 ? POSTION_ZEBRA_COLOR_EVEN : POSTION_ZEBRA_COLOR_ODD} />
    ));
};
