import { FunctionComponent } from 'react';

import { Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ResponsiveContainer from '../../components/ui/ResponsiveContainer';
import { logout } from '../../store/auth/service';
import { useAppDispatch } from '../../store/hooks';

import MarketDataSettingsPanel from './MarketDataSettingsPanel';

const AccountPage: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogOutBtnClick = (): void => {
    dispatch(logout());
    navigate('/login');
  };

  return (
    <>
      <Container>
        <Typography variant="h4" sx={{ my: 3 }}>
          Account
        </Typography>
      </Container>
      <ResponsiveContainer>
        <MarketDataSettingsPanel />
      </ResponsiveContainer>
      <Container sx={{ mt: 2 }}>
        <Button variant="contained" color="error" fullWidth onClick={handleLogOutBtnClick}>
          Log Out
        </Button>
      </Container>
    </>
  );
};

export default AccountPage;
