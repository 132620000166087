import { FunctionComponent } from 'react';

import { Box } from '@mui/material';

import { useAppSelector } from '../../../../store/hooks';
import { Position } from '../../../../types/entities';

import PositionBody from './PositionBody';
import PositionHeader from './PositionHeader';

interface Props {
  position: Position;
  bg: string;
}

const PositionPanel: FunctionComponent<Props> = ({ position, bg }: Props) => {
  const { size } = position;
  const openOrderState = useAppSelector((gs) => gs.openOrderState);
  const optionOpenOrder = openOrderState.orders.find((x) => x.conId === position.conId);
  const openOrderSize = optionOpenOrder?.size || 0;
  const isPositionClosed = openOrderSize === 0 && size === 0;

  return (
    <Box sx={{ backgroundColor: bg }}>
      <PositionHeader position={position} isPositionClosed={isPositionClosed} />
      <PositionBody position={position} isPositionClosed={isPositionClosed} />
    </Box>
  );
};

export default PositionPanel;
