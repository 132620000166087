import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FundamentalNote } from '../../types/entities';

import { FundamentalNoteState, initialState } from './state';

const fundamentalNoteSlice = createSlice({
  name: 'fundamental-note',
  initialState,
  reducers: {
    reset: () => initialState,
    loading: (state: FundamentalNoteState, action: PayloadAction<boolean>) => {
      state.error = undefined;
      state.loading = action.payload;
      state.loaded = !action.payload;
    },
    success: (state: FundamentalNoteState, action: PayloadAction<FundamentalNote[]>) => {
      state.error = undefined;
      state.loading = false;
      state.loaded = true;
      state.notes = action.payload;
    },
    fail: (state: FundamentalNoteState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.loaded = true;
      state.notes = [];
    },
    add: (state: FundamentalNoteState, action: PayloadAction<FundamentalNote>) => {
      state.notes.push(action.payload);
      state.loading = false;
      state.loaded = true;
    },
    update: (state: FundamentalNoteState, action: PayloadAction<FundamentalNote>) => {
      const dbEntry = state.notes.find((x) => x.id === action.payload.id);
      if (!!dbEntry) {
        const index = state.notes.indexOf(dbEntry);
        state.notes[index] = action.payload;
      }
      state.loading = false;
      state.loaded = true;
    },
    remove: (state: FundamentalNoteState, action: PayloadAction<FundamentalNote>) => {
      const dbEntry = state.notes.find((x) => x.id === action.payload.id);
      if (!!dbEntry) {
        const index = state.notes.indexOf(dbEntry);
        state.notes.splice(index, 1);
      }
      state.loading = false;
      state.loaded = true;
    }
  }
});

const { actions, reducer } = fundamentalNoteSlice;
export const { reset, loading, success, fail, add, update, remove } = actions;
export default reducer;
