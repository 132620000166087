import * as React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useAppSelector } from '../../../../store/hooks';
import { OptionContract } from '../../../../types/entities';
import { setThousenSeparator } from '../../../../utils/currency-utils';

interface Props {
  marketPrice: number;
  options: OptionContract[];
  optionSelected: number;
  onOptionClick: (opt: OptionContract) => void;
}

export default function OptionChainTable({ marketPrice, options, optionSelected, onOptionClick }: Props) {
  const orderOptionState = useAppSelector((gs) => gs.orderOptionState);
  const action = orderOptionState.orderAction;

  const header = (
    <TableHead sx={{ position: 'sticky' }}>
      <TableRow>
        <TableCell>Strike</TableCell>
        <TableCell align="right" title="Intrinsic Value">
          Intrinsic
        </TableCell>
        <TableCell align="right" title="Extrinsic Value">
          Extrinsic
        </TableCell>
        <TableCell align="right" title="Price">
          Price
        </TableCell>
        <TableCell align="right" title="Delta">
          Delta
        </TableCell>
        <TableCell align="right" title="Standard Deviation">
          SD
        </TableCell>
        <TableCell align="right" title="Premium">
          Premium
        </TableCell>
        <TableCell align="right" title="Premium">
          Undrl PnL
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const getStyles = (standardDeviation: number, isMarketPrice?: boolean) => {
    let gradiantBgColor = '';
    if (standardDeviation < 0.5) {
      gradiantBgColor = 'rgb(104, 55, 65, 0.5)'; // '#f0dc82';
    } else if (standardDeviation < 1) {
      gradiantBgColor = 'rgb(197, 91, 109, 0.5)'; // '#f8efc6';
    } else {
      gradiantBgColor = 'inherit';
    }
    return {
      '&:last-child td, &:last-child th': { border: 0 },
      cursor: isMarketPrice ? 'not-allowed' : 'pointer',
      backgroundColor: gradiantBgColor,
      whiteSpace: 'nowrap'
    };
  };

  const getPremium = (premium: number) => {
    return action === 'BUY' ? premium * -1 : premium;
  };

  const mapRow = (opt: OptionContract) => {
    const premium = getPremium(opt.premium);
    return (
      <TableRow
        key={opt.localSymbol}
        sx={getStyles(opt.standardDeviation)}
        selected={opt.conId === optionSelected}
        onClick={() => onOptionClick(opt)}
      >
        <TableCell component="th" scope="row">
          {opt.strikePrice}
        </TableCell>
        <TableCell align="right">{opt.intrinsicValue.toFixed(2)}</TableCell>
        <TableCell align="right">{opt.extrinsicValue.toFixed(2)}</TableCell>
        <TableCell align="right">{opt.optionPrice.toFixed(2)}</TableCell>
        <TableCell align="right">{opt.delta.toFixed(2)}</TableCell>
        <TableCell align="right">{opt.standardDeviation.toFixed(2)}</TableCell>
        <TableCell align="right">{setThousenSeparator(premium.toFixed(0))}</TableCell>
        <TableCell align="right">{setThousenSeparator(opt.underlyingProfit.toFixed(0))}</TableCell>
      </TableRow>
    );
  };

  const optAboveMarketPrice = options.filter((opt) => opt.strikePrice > marketPrice).map((opt) => mapRow(opt));
  const optBelowMarketPrice = options.filter((opt) => opt.strikePrice < marketPrice).map((opt) => mapRow(opt));

  const marketPriceRow = marketPrice && (
    <TableRow sx={getStyles(0, true)}>
      <TableCell component="th" scope="row">
        {marketPrice}
      </TableCell>
      <TableCell align="right">-</TableCell>
      <TableCell align="right">-</TableCell>
      <TableCell align="right">-</TableCell>
      <TableCell align="right">0.50</TableCell>
      <TableCell align="right">0</TableCell>
      <TableCell align="right">-</TableCell>
      <TableCell align="right">-</TableCell>
    </TableRow>
  );

  return (
    <TableContainer sx={{ height: '50vh' }}>
      <Table size="small" aria-label="a dense table">
        {header}
        <TableBody>
          {optAboveMarketPrice}
          {marketPriceRow}
          {optBelowMarketPrice}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
