import { FunctionComponent, useEffect, useState } from 'react';

import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { INDICATOR_GREEN_COLOR, INDICATOR_RED_COLOR, TABLE_BORDER_COLOR } from '../../assets/colors';
import { useConfirm } from '../../components/hooks/useConfirm';
import { useContractDetailsQuery } from '../../components/hooks/useContractDetailsQuery';
import EditPositionIconButton from '../../components/ui/EditPositionIconButton';
import ShowGraphIconButton from '../../components/ui/ShowGraphIconButton';
import Spinner from '../../components/ui/Spinner';
import VerticalSortIconButton from '../../components/ui/VerticalSortIconButton';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { placeOptionExitOrderAsync as placeOptionExitOrderAsync } from '../../store/order-option/service';
import { placeUnderlyingExitOrderAsync } from '../../store/order-underlying/service';
import { Position } from '../../types/entities';
import { formatNum, setThousenSeparator } from '../../utils/currency-utils';
import { createOptionExitOrder, createUnderlyingExitOrder } from '../../utils/position-utils';

interface Props {
  symbol: string;
  positionSelected: Position | undefined;
  onPositionSelected: (pos: Position | undefined) => void;
}

const ExistingPositionsTable: FunctionComponent<Props> = ({ symbol, positionSelected, onPositionSelected }: Props) => {
  const [confirm, ConfirmDialog] = useConfirm();
  const query = useContractDetailsQuery();
  const dispatch = useAppDispatch();

  const { positions } = useAppSelector((gs) => gs.positionState);
  const [securityPositions, setSecurityPositions] = useState(positions);

  const sortPositions = (a: Position, b: Position) => {
    if (!a || !b) {
      return 0;
    }
    return a.sortOrder < b.sortOrder ? 1 : -1;
  };

  useEffect(() => {
    const list = positions
      .slice()
      .filter((x) => x.symbol === symbol && !x.completed)
      .sort(sortPositions);
    setSecurityPositions(list);
  }, [positions]);

  const handleRowMouseClick = (pos: Position) => {
    const p = pos.conId !== positionSelected?.conId ? pos : undefined;
    onPositionSelected(p);
  };

  const stopPropagation = (event: React.MouseEvent<HTMLTableCellElement>) => {
    event.stopPropagation();
  };

  const handleClosePositionBtnClick = async (event: React.MouseEvent<HTMLButtonElement>, pos: Position) => {
    event.stopPropagation();
    const isOption = pos.secType === 'FOP' || pos.secType === 'OPT';
    if (await confirm('Are you sure you want to close this position?')) {
      if (isOption) {
        const exitOrder = createOptionExitOrder(pos, 'MKT');
        dispatch(placeOptionExitOrderAsync(exitOrder));
      } else {
        const exitOrder = createUnderlyingExitOrder(pos, 'MKT');
        dispatch(placeUnderlyingExitOrderAsync(exitOrder));
      }
    }
  };

  const handleCreateLimitOrderBtnClick = async (event: React.MouseEvent<HTMLButtonElement>, pos: Position) => {
    event.stopPropagation();
    await query.gotoExitOrderAsync(pos);
  };

  if (securityPositions.length === 0) {
    return (
      <Box sx={{ m: 2 }}>
        <small>No Positions..</small>
      </Box>
    );
  }

  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableCell>Sec Type</TableCell>
        <TableCell>Parent</TableCell>
        <TableCell>Local</TableCell>
        <TableCell align="center">Size</TableCell>
        <TableCell>Type</TableCell>
        <TableCell>Action</TableCell>
        <TableCell align="center">Right</TableCell>
        {/* <TableCell align="right">Entry Price</TableCell> */}
        <TableCell align="right">Price</TableCell>
        <TableCell align="right">Premium</TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="right"></TableCell>
        <TableCell align="right"></TableCell>
      </TableRow>
    </TableHead>
  );

  const orderRows = securityPositions.map((pos: Position, i) => {
    // const entryPrice = pos.entryPrice != 0 ? `$${formatNum(pos.entryPrice)}` : '-';
    const marketPrice = pos.marketPrice != 0 ? `$${formatNum(pos.marketPrice)}` : '-';
    const totalPremium = pos.premium * pos.size;
    const totalPremiumFormatted = totalPremium != 0 ? `$${setThousenSeparator(totalPremium.toFixed(0))}` : '-';
    const premiumColor = pos.premium == 0 ? 'Inherit' : pos.orderAction === 'BUY' ? INDICATOR_RED_COLOR : INDICATOR_GREEN_COLOR;

    return (
      <TableRow
        key={`${pos.conId}-${i}`}
        selected={pos.conId === positionSelected?.conId}
        sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
        onClick={() => handleRowMouseClick(pos)}
      >
        <TableCell>{pos.secType}</TableCell>
        <TableCell>{pos.underSymbol}</TableCell>
        <TableCell>{pos.localSymbol}</TableCell>
        <TableCell align="center">{pos.size}</TableCell>
        <TableCell>{pos.orderType}</TableCell>
        <TableCell>{pos.orderAction}</TableCell>
        <TableCell align="center">{pos.right || '-'}</TableCell>
        {/* <TableCell align="right">{entryPrice}</TableCell> */}
        <TableCell align="right">{marketPrice}</TableCell>
        <TableCell align="right" sx={{ color: premiumColor }}>
          {totalPremiumFormatted}
        </TableCell>
        <TableCell align="center" sx={{ px: 0.5 }} onClick={stopPropagation}>
          <ShowGraphIconButton position={pos} />
        </TableCell>
        <TableCell align="center" sx={{ px: 0.5, borderLeft: `1px solid ${TABLE_BORDER_COLOR}` }} onClick={stopPropagation}>
          <VerticalSortIconButton position={pos} direction="up" />
        </TableCell>
        <TableCell align="center" sx={{ px: 0.5 }} onClick={stopPropagation}>
          <VerticalSortIconButton position={pos} direction="down" />
        </TableCell>
        <TableCell align="center" sx={{ px: 0.5 }} onClick={stopPropagation}>
          <EditPositionIconButton position={pos} />
        </TableCell>
        <TableCell align="right" sx={{ width: '80px', px: 1, borderLeft: `1px solid ${TABLE_BORDER_COLOR}` }}>
          <Button
            size="small"
            color="warning"
            variant="outlined"
            disabled={pos.size === 0}
            onClick={(e) => handleCreateLimitOrderBtnClick(e, pos)}
          >
            LMT/STP
          </Button>
        </TableCell>
        <TableCell align="right" sx={{ width: '80px', px: 1 }}>
          <Button
            size="small"
            color="error"
            variant="contained"
            onClick={(e) => handleClosePositionBtnClick(e, pos)}
            disabled={pos.size === 0}
          >
            Close
          </Button>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <TableContainer>
        <Table size="small">
          {tableHeader}
          <TableBody>{orderRows}</TableBody>
        </Table>
      </TableContainer>
      <ConfirmDialog />
      <Spinner loading={query.loading} />
    </>
  );
};

export default ExistingPositionsTable;
