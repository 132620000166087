import { TradingClass } from '../../types/entities';

export interface TradingClassStateByConId {
  loading: boolean;
  loaded: boolean;
  error?: string;
  tradingClasses: TradingClass[];
}

export const initialTradingClassStateByConId: TradingClassStateByConId = {
  loading: false,
  loaded: false,
  error: undefined,
  tradingClasses: []
};

export interface TradingClassState {
  [conId: number]: TradingClassStateByConId;
}

export const initialTradingClassState: TradingClassState = {};
