import { toast } from 'react-toastify';

import { AppDispatch } from '..';
import { authUser } from '../../api';
import { setAuthorizationHeader } from '../../api/axios-api';
import { AuthToken } from '../../types/entities';
import { clearLocalStorage, fromLocalStorage, toLocalStorage } from '../../utils/local-storage-utils';

import { reset, setLoading, success, fail } from './reducer';

const useAuthToken = (dispatch: AppDispatch, authToken: AuthToken) => {
  setAuthorizationHeader(authToken.accessToken);
  dispatch(success(authToken));
};

const isAuthTokenExpired = (authToken: AuthToken) => {
  const now = new Date();
  const expiresTime = authToken.expires ? new Date(authToken.expires).getTime() : 0;
  const expiresInSeconds = expiresTime - now.getTime();
  return expiresInSeconds < 0;
};

export const authAsync = (username: string, password: string) => {
  return (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    authUser(username, password)
      .then((response) => {
        const authToken = response.data;
        // save to local storage
        toLocalStorage(authToken);
        useAuthToken(dispatch, authToken);
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const validate = () => {
  // read from local storage
  const authToken = fromLocalStorage();
  const exists = !!authToken && !!authToken.accessToken && !!authToken.expires;
  const expired = exists ? isAuthTokenExpired(authToken) : true;
  const isValid = exists && !expired;

  return (dispatch: AppDispatch) => {
    if (isValid) {
      useAuthToken(dispatch, authToken);
    } else {
      dispatch(reset());
      dispatch(setLoading(false));
    }
  };
};

export const logout = () => {
  return (dispatch: AppDispatch) => {
    dispatch(reset());
    dispatch(setLoading(false));
    // delete local storage
    clearLocalStorage();
    setAuthorizationHeader(undefined);
  };
};
