import { FunctionComponent } from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { useAppSelector } from '../../../store/hooks';
import { Position } from '../../../types/entities';

import OrderRow from './OrderRow';

interface Props {
  orders: Position[];
  groupId: string | undefined;
  orderSelected: Position | undefined;
  onRowClick: (pos: Position | undefined) => void;
}

const OrderTable: FunctionComponent<Props> = ({ orders, groupId, orderSelected, onRowClick }: Props) => {
  const { orders: openOrders } = useAppSelector((gs) => gs.openOrderState);

  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableCell>Sec Type</TableCell>
        <TableCell>Parent</TableCell>
        <TableCell>Local</TableCell>
        <TableCell align="center">Size</TableCell>
        <TableCell>Type</TableCell>
        <TableCell>Action</TableCell>
        <TableCell align="center">Right</TableCell>
        {/* <TableCell align="right">Entry Price</TableCell> */}
        <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
          Entry Price
        </TableCell>
        <TableCell align="right">Cost</TableCell>
        <TableCell align="right">Premium</TableCell>
        <TableCell align="right"></TableCell>
        <TableCell align="right"></TableCell>
        <TableCell align="right"></TableCell>
        <TableCell align="right"></TableCell>
        <TableCell align="right"></TableCell>
      </TableRow>
    </TableHead>
  );

  const orderRows = orders.map((pos: Position) => {
    const posOpenOrders = openOrders.filter((x) => x.conId === pos.conId);
    return (
      <OrderRow
        key={`${pos.conId}-${pos.orderType}`}
        order={pos}
        groupId={groupId}
        orderSelected={orderSelected}
        onRowClick={onRowClick}
        openOrders={posOpenOrders}
      />
    );
  });

  return (
    <TableContainer>
      <Table size="small">
        {tableHeader}
        <TableBody>{orderRows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrderTable;
