import { toast } from 'react-toastify';

import { cancelOpenOrder, fetchOpenOrders } from '../../api';
import { AppDispatch } from '../../store';
import { OpenOrderReport } from '../../types/entities';
import { Logger } from '../../utils/Logger';

import { success, fail, loading, removeOpenOrder } from './reducer';

export const getOpenOrdersAsync = () => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    fetchOpenOrders()
      .then((response) => {
        const openOrders = response.data;
        Logger.log(openOrders);
        dispatch(success(openOrders));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const cancelOpenOrderAsync = (request: OpenOrderReport) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    cancelOpenOrder(request)
      .then((response) => {
        const id = response.data;
        dispatch(removeOpenOrder(id));
        toast.success('Order cancelled successfully!');
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};
