import { FunctionComponent, useEffect, useState } from 'react';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@mui/material';

import { useAppDispatch } from '../../store/hooks';
import { createPortfolioAsync, removePortfolioAsync, updatePortfolioAsync } from '../../store/portfolio/service';
import { setSelectedPortfolio } from '../../store/user-settings/reducer';
import { Portfolio } from '../../types/entities';
import DialogCloseIconButton from '../ui/DialogCloseIconButton';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  portfolio?: Portfolio | undefined;
}

const PortfolioDialog: FunctionComponent<Props> = ({ isOpen, onClose, portfolio }: Props) => {
  const portfolioTemplate: Portfolio = {
    id: '',
    name: '',
    comment: '',
    contracts: []
  };

  const [portfolioForm, setPortfolioForm] = useState<Portfolio>(portfolio || portfolioTemplate);

  useEffect(() => {
    if (portfolio) {
      setPortfolioForm(portfolio);
    } else {
      setPortfolioForm(portfolioTemplate);
    }
  }, [portfolio]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updatePortfolioForm = (field: keyof Portfolio, value: any) => {
    const copy = {
      ...portfolioForm,
      [field]: value
    } as Portfolio;
    setPortfolioForm(copy);
  };

  const dispatch = useAppDispatch();

  const handleSavePortfolio = () => {
    const isNewPortfolio = !portfolio;
    if (isNewPortfolio) {
      dispatch(createPortfolioAsync(portfolioForm));
    } else {
      dispatch(updatePortfolioAsync(portfolioForm));
    }
    onClose();
  };

  const handleDeletePortfolio = () => {
    const isNewPortfolio = !portfolio;
    if (!isNewPortfolio) {
      dispatch(setSelectedPortfolio(undefined));
      dispatch(removePortfolioAsync(portfolioForm));
    }
    onClose();
  };

  const title = `${portfolio ? 'Edit' : 'New'} Portfolio`;

  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm" onClose={onClose}>
      <DialogTitle sx={{ pl: 2 }}>{title}</DialogTitle>
      <DialogCloseIconButton onClose={onClose} />

      <DialogContent sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
        <FormControl>
          <TextField
            name="name"
            size="small"
            label="Name"
            variant="outlined"
            type="text"
            value={portfolioForm.name || ''}
            onChange={(e) => updatePortfolioForm('name', e.target.value)}
          />
        </FormControl>

        <FormControl>
          <TextField
            name="url"
            size="small"
            label="Notes"
            variant="outlined"
            type="text"
            multiline
            rows={3}
            value={portfolioForm.comment || ''}
            onChange={(e) => updatePortfolioForm('comment', e.target.value)}
          />
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        {portfolio && (
          <Box sx={{ display: 'flex', gap: 1, flexGrow: 1, justifyContent: 'flex-start' }}>
            <Button variant="contained" color="error" onClick={handleDeletePortfolio}>
              Remove
            </Button>
          </Box>
        )}
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary" onClick={handleSavePortfolio}>
            Save
          </Button>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default PortfolioDialog;
