import { rest } from 'msw';

import positionListResult from './covered-option-positions.json';

/// <summary>
/// <param name="req">an information about a matching request</param>
/// <param name="res">a functional utility to create the mocked response</param>
/// <param name="ctx">a group of functions that help to set a status code, headers, body, etc. of the mocked response.</param>
/// </summary>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getPositions = async (_req: any, res: any, ctx: any) => {
  return res(ctx.status(200), ctx.json(positionListResult));
};

export const positionHandlers = [
  // Handles a GET /info request
  rest.get(`${process.env.REACT_APP_SERVER_URL}/api/broker/position`, getPositions)
];
