import { PositionGroup } from '../../types/entities';

export interface PositionGroupState {
  loading: boolean;
  loaded: boolean;
  error?: string;
  groups: PositionGroup[];
}

export const initialState: PositionGroupState = {
  loading: false,
  loaded: false,
  error: undefined,
  groups: []
};
