import { toast } from 'react-toastify';

import { AppDispatch } from '..';
import { createSecurity, fetchSecurities, removeSecurity, updateSecurity } from '../../api';
import { Security } from '../../types/entities';
import { Logger } from '../../utils/Logger';

import { success, fail, loading, add, remove, update } from './reducer';

export const getSecuritiesAsync = () => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    fetchSecurities()
      .then((response) => {
        const securities = response.data;
        Logger.log(securities);
        dispatch(success(securities));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const createSecurityAsync = (sec: Security) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    createSecurity(sec)
      .then((response) => {
        dispatch(add(response.data));
        toast.success('Security created successfully!');
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const updateSecurityAsync = (sec: Security) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    updateSecurity(sec)
      .then(() => {
        Logger.log(sec);
        dispatch(update(sec));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const removeSecurityAsync = (sec: Security) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    removeSecurity(sec)
      .then(() => {
        dispatch(remove(sec));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};
