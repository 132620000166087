import { Security } from '../../types/entities';
import { SecurityCategory, SecuritySector } from '../../types/enums';

export const stocks: Security[] = [
  {
    id: '',
    name: 'Alphabet',
    symbol: 'GOOGL',
    exchange: 'SMART',
    secType: 'STK',
    currency: 'USD',
    category: SecurityCategory.Stock,
    sector: SecuritySector.Tech,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Amazon',
    symbol: 'AMZN',
    exchange: 'SMART',
    secType: 'STK',
    currency: 'USD',
    category: SecurityCategory.Stock,
    sector: SecuritySector.Tech,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Apple',
    symbol: 'AAPL',
    exchange: 'SMART',
    secType: 'STK',
    currency: 'USD',
    category: SecurityCategory.Stock,
    sector: SecuritySector.Tech,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Blink',
    symbol: 'BLNK',
    exchange: 'SMART',
    secType: 'STK',
    currency: 'USD',
    category: SecurityCategory.Stock,
    sector: SecuritySector.Industrial,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'DaVita',
    symbol: 'DVA',
    exchange: 'SMART',
    secType: 'STK',
    currency: 'USD',
    category: SecurityCategory.Stock,
    sector: SecuritySector.Healthcare,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'DraftKings',
    symbol: 'DKNG',
    exchange: 'SMART',
    secType: 'STK',
    currency: 'USD',
    category: SecurityCategory.Stock,
    sector: SecuritySector.Tech,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Extra Space Storage',
    symbol: 'EXR',
    exchange: 'SMART',
    secType: 'STK',
    currency: 'USD',
    category: SecurityCategory.Stock,
    sector: SecuritySector.RealEstate,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'First Solar',
    symbol: 'FSL',
    exchange: 'SMART',
    secType: 'STK',
    currency: 'USD',
    category: SecurityCategory.Stock,
    sector: SecuritySector.Environmental,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Intel Corp',
    symbol: 'INTC',
    exchange: 'SMART',
    secType: 'STK',
    currency: 'USD',
    category: SecurityCategory.Stock,
    sector: SecuritySector.Tech,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'IBM',
    symbol: 'IBM',
    exchange: 'SMART',
    secType: 'STK',
    currency: 'USD',
    category: SecurityCategory.Stock,
    sector: SecuritySector.Tech,
    shortList: false,
    subscribed: true,
    disabled: true
  },
  {
    id: '',
    name: 'Meta',
    symbol: 'META',
    exchange: 'SMART',
    secType: 'STK',
    currency: 'USD',
    category: SecurityCategory.Stock,
    sector: SecuritySector.Tech,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Microsoft',
    symbol: 'MSFT',
    exchange: 'SMART',
    secType: 'STK',
    currency: 'USD',
    category: SecurityCategory.Stock,
    sector: SecuritySector.Tech,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'NVIDIA',
    symbol: 'NVDA',
    exchange: 'SMART',
    secType: 'STK',
    currency: 'USD',
    category: SecurityCategory.Stock,
    sector: SecuritySector.Tech,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'PayCom',
    symbol: 'PAYC',
    exchange: 'SMART',
    secType: 'STK',
    currency: 'USD',
    category: SecurityCategory.Stock,
    sector: SecuritySector.Tech,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Palo Alto',
    symbol: 'PANW',
    exchange: 'SMART',
    secType: 'STK',
    currency: 'USD',
    category: SecurityCategory.Stock,
    sector: SecuritySector.Tech,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Salesforce',
    symbol: 'CRM',
    exchange: 'SMART',
    secType: 'STK',
    currency: 'USD',
    category: SecurityCategory.Stock,
    sector: SecuritySector.Tech,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'ServiceNow',
    symbol: 'NOW',
    exchange: 'SMART',
    secType: 'STK',
    currency: 'USD',
    category: SecurityCategory.Stock,
    sector: SecuritySector.Tech,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Shopify',
    symbol: 'SHOP',
    exchange: 'SMART',
    secType: 'STK',
    currency: 'USD',
    category: SecurityCategory.Stock,
    sector: SecuritySector.Tech,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Symatec',
    symbol: 'GEN',
    exchange: 'SMART',
    secType: 'STK',
    currency: 'USD',
    category: SecurityCategory.Stock,
    sector: SecuritySector.Tech,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Tesla',
    symbol: 'TSLA',
    exchange: 'SMART',
    secType: 'STK',
    currency: 'USD',
    category: SecurityCategory.Stock,
    sector: SecuritySector.Tech,
    shortList: false,
    subscribed: true,
    disabled: false
  }
];

export const commodities: Security[] = [
  {
    id: '',
    name: 'SP 500 Mini',
    symbol: 'ES',
    exchange: 'CME',
    secType: 'FUT',
    currency: 'USD',
    category: SecurityCategory.Futures,
    sector: SecuritySector.Indexes,
    multiplier: '50',
    tickIncrement: 0.25,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Dow Jones Mini',
    symbol: 'YM',
    exchange: 'CBOT',
    secType: 'FUT',
    currency: 'USD',
    multiplier: '5',
    tickIncrement: 1,
    category: SecurityCategory.Futures,
    sector: SecuritySector.Indexes,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Corn',
    symbol: 'ZC',
    exchange: 'CBOT',
    secType: 'FUT',
    currency: 'USD',
    multiplier: '5000',
    tickIncrement: 0.0025,
    priceMagnifier: 100,
    category: SecurityCategory.Futures,
    sector: SecuritySector.Softs,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Russel 2000 Mini',
    symbol: 'RTY',
    exchange: 'CME',
    secType: 'FUT',
    currency: 'USD',
    multiplier: '50',
    tickIncrement: 0.1,
    category: SecurityCategory.Futures,
    sector: SecuritySector.Indexes,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Copper',
    symbol: 'HG',
    exchange: 'COMEX',
    secType: 'FUT',
    currency: 'USD',
    multiplier: '25000',
    tickIncrement: 0.0005,
    category: SecurityCategory.Futures,
    sector: SecuritySector.Metals,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Nasdaq-100 Mini',
    symbol: 'NQ',
    exchange: 'CME',
    secType: 'FUT',
    currency: 'USD',
    category: SecurityCategory.Futures,
    sector: SecuritySector.Indexes,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Crude Oil',
    symbol: 'CL',
    exchange: 'NYMEX',
    secType: 'FUT',
    currency: 'USD',
    category: SecurityCategory.Futures,
    sector: SecuritySector.Energies,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Gold',
    symbol: 'GC',
    exchange: 'COMEX',
    secType: 'FUT',
    currency: 'USD',
    category: SecurityCategory.Futures,
    sector: SecuritySector.Metals,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Bitcoin',
    symbol: 'BRR',
    exchange: 'CME',
    secType: 'FUT',
    currency: 'USD',
    category: SecurityCategory.Futures,
    sector: SecuritySector.Financial,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Ether',
    symbol: 'ETHUSDRR',
    exchange: 'CME',
    secType: 'FUT',
    currency: 'USD',
    category: SecurityCategory.Futures,
    sector: SecuritySector.Financial,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Silver',
    symbol: 'SI',
    exchange: 'COMEX',
    secType: 'FUT',
    currency: 'USD',
    category: SecurityCategory.Futures,
    sector: SecuritySector.Financial,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'T-Bonds',
    symbol: 'ZB',
    exchange: 'CBOT',
    secType: 'FUT',
    currency: 'USD',
    category: SecurityCategory.Futures,
    sector: SecuritySector.Financial,
    shortList: false,
    subscribed: true,
    disabled: false
  }
];

export const micros: Security[] = [
  {
    id: '',
    name: 'SP 500 Micro',
    parentSymbol: 'ES',
    symbol: 'MES',
    exchange: 'CME',
    secType: 'FUT',
    currency: 'USD',
    multiplier: '5',
    tickIncrement: 0.25,
    category: SecurityCategory.MicroFutures,
    sector: SecuritySector.Indexes,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Nasdaq-100 Micro',
    parentSymbol: 'NQ',
    symbol: 'MNQ',
    exchange: 'CME',
    secType: 'FUT',
    currency: 'USD',
    category: SecurityCategory.MicroFutures,
    sector: SecuritySector.Indexes,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Crude Oil Micro',
    parentSymbol: 'CL',
    symbol: 'MCL',
    exchange: 'NYMEX',
    secType: 'FUT',
    currency: 'USD',
    category: SecurityCategory.MicroFutures,
    sector: SecuritySector.Energies,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Gold Micro',
    parentSymbol: 'GC',
    symbol: 'MGC',
    exchange: 'COMEX',
    secType: 'FUT',
    currency: 'USD',
    category: SecurityCategory.MicroFutures,
    sector: SecuritySector.Metals,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Bitcoin Micro',
    symbol: 'MBT',
    exchange: 'CME',
    secType: 'FUT',
    currency: 'USD',
    category: SecurityCategory.MicroFutures,
    sector: SecuritySector.Financial,
    shortList: false,
    subscribed: true,
    disabled: false
  },
  {
    id: '',
    name: 'Ether Micro',
    parentSymbol: 'RT',
    symbol: 'MET',
    exchange: 'CME',
    secType: 'FUT',
    currency: 'USD',
    category: SecurityCategory.MicroFutures,
    sector: SecuritySector.Financial,
    shortList: false,
    subscribed: true,
    disabled: false
  }
];

export const securities = stocks.concat(commodities).concat(micros);
