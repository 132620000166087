import { rest } from 'msw';

import fixEntryResult from './fix-entry.json';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fixEntryHandler = async (_req: any, res: any, ctx: any) => {
  return res(ctx.status(200), ctx.json(fixEntryResult));
};

export const repairHandlers = [
  // Handles a GET /info request
  rest.post(`${process.env.REACT_APP_SERVER_URL}/api/broker/repair/fix-entry`, fixEntryHandler)
];
