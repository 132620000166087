import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { validate } from '../../store/auth/service';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Logger } from '../../utils/Logger';

const isValidationCheckOutdated = (dt1: Date, dt2: Date) => {
  if (!dt2) {
    return true;
  }
  const ts = dt1.getTime() - dt2.getTime();
  const totalSeconds = ts / 1000;
  const minutes = Math.round(Math.abs(totalSeconds) / 60);
  return minutes > 30;
};
export const useContinuousAuthValidation = () => {
  const location = useLocation();

  const { token } = useAppSelector((gs) => gs.authState);
  const [lastValidateCheck, setLastValidateCheck] = useState<Date>(new Date());

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!!token) {
      const now = new Date();
      const outdated = isValidationCheckOutdated(now, lastValidateCheck);
      if (outdated) {
        Logger.log('Continuous auth-check triggered..');
        setLastValidateCheck(now);
        dispatch(validate());
      }
    }
  }, [location]);
};
