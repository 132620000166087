import { CssBaseline, ThemeProvider } from '@mui/material';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import App from './App';
import { theme } from './assets/theme';
import { initMockServiceWorker } from './msw/msw-setup';
import reportWebVitals from './reportWebVitals';
import { setupStore } from './store';

import 'react-toastify/dist/ReactToastify.css';
import './assets/global.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const store = setupStore();
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <App />
        <ToastContainer position="bottom-center" theme="dark" />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);

const enableMockServiceWorker = process.env.REACT_APP_ENABLE_MOCK_SERVICE_WORKER === 'true';
// eslint-disable-next-line no-console
console.log(enableMockServiceWorker ? 'starting worker...' : 'NOT starting worker!');
if (enableMockServiceWorker) {
  initMockServiceWorker();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
