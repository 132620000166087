import { Typography } from '@mui/material';

import { INDICATOR_YELLOW_COLOR } from '../../../../../assets/colors';
import { Position } from '../../../../../types/entities';
import { getDaysAppart } from '../../../../../utils/datetime-utils';

export const getDaysApartValue = (expires: string | Date) => {
  const expiresDt = expires ? new Date(expires) : undefined;
  if (expiresDt) {
    const daysAppart = getDaysAppart(expiresDt, new Date());
    const daysAppartLabel = `${daysAppart}d`;
    const daysAppartColor = daysAppart < 10 ? INDICATOR_YELLOW_COLOR : 'inherit';
    return (
      <Typography variant="caption" color={daysAppartColor}>
        {daysAppartLabel}
      </Typography>
    );
  }
};

export const getDetails = (position: Position, isPositionClosed: boolean) => {
  const isOption = position.secType === 'FOP' || position.secType === 'OPT';
  if (isOption) {
    const expires = position.expirationDate;
    if (expires && !isPositionClosed) {
      return getDaysApartValue(expires);
    } else {
      return <Typography variant="caption">-</Typography>;
    }
  } else {
    return <Typography variant="caption">{position.localSymbol}</Typography>;
  }
};
