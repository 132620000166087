import { FunctionComponent, useMemo } from 'react';

import { Box, Divider, Typography } from '@mui/material';

import racingFlagsIcon from '../../../../../../assets/svg/racing-flags.svg';
import MarketHoursIndicator from '../../../../../../components/ui/MarketHoursIndicator';
import { Position } from '../../../../../../types/entities';
import { getDurationString } from '../../../../../../utils/datetime-utils';

import OptionPositionDetails from './OptionPositionDetails';
import UnderlyingPositionDetails from './UnderlyingPositionDetails';

interface Props {
  position: Position;
  isPositionClosed: boolean;
}

const PositionDetails: FunctionComponent<Props> = ({ position, isPositionClosed }) => {
  const isUnderlying = position.secType === 'FUT' || position.secType === 'STK';

  const duration = useMemo(() => {
    if (!position.entryDate) {
      return '';
    }
    const entryDate = new Date(position.entryDate);
    const endDate = position.exitDate ? new Date(position.exitDate) : new Date();
    return getDurationString(entryDate, endDate);
  }, [position.entryDate, position.exitDate]);

  const hasPositionExpired = useMemo(() => {
    if (!position.expirationDate) {
      return false;
    }
    const now = new Date();
    const exDt = new Date(position.expirationDate);
    return now > exDt;
  }, [position.expirationDate]);

  const posEndedIcon = isPositionClosed || hasPositionExpired ? <img src={racingFlagsIcon} width="24px" alt="Finished" /> : undefined;

  return (
    <>
      <Box sx={{ mb: 1, mx: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {duration && (
            <Typography variant="caption" component="div">
              Position Duration: {duration}
            </Typography>
          )}
          <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>{posEndedIcon}</Box>
        </Box>
        <MarketHoursIndicator position={position} />
      </Box>
      <Divider />
      {isUnderlying ? <UnderlyingPositionDetails position={position} /> : <OptionPositionDetails position={position} />}
    </>
  );
};

export default PositionDetails;
