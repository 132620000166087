import { OptionContract } from '../../types/entities';

export interface OptionChainStateByComposite {
  loading: boolean;
  loaded: boolean;
  error: string | undefined;
  options: OptionContract[];
}

export const initialOptionChainStateByConIdAndRight: OptionChainStateByComposite = {
  loading: false,
  loaded: false,
  error: undefined,
  options: []
};

export interface OptionChainState {
  [compositeId: string]: OptionChainStateByComposite;
}

export const initialOptionChainState: OptionChainState = {};
