import { toast } from 'react-toastify';

import { AppDispatch } from '..';
import { createPositionGroup, fetchPositionGroups, removePositionGroup, updatePositionGroup } from '../../api';
import { PositionGroup } from '../../types/entities';

import { fail, loading, add, remove, update, success } from './reducer';

export const getPositionGroupsAsync = () => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    fetchPositionGroups()
      .then((response) => {
        const groups = response.data;
        dispatch(success(groups));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const createPositionGroupAsync = (grp: PositionGroup) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    createPositionGroup(grp)
      .then((response) => {
        const groupWithId = response.data;
        dispatch(add(groupWithId));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const updatePositionGroupAsync = (grp: PositionGroup) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    updatePositionGroup(grp.id as string, grp)
      .then(() => {
        dispatch(update(grp));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const removePositionGroupAsync = (grp: PositionGroup) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    removePositionGroup(grp.id as string)
      .then(() => {
        dispatch(remove(grp));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};
