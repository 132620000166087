import { useEffect } from 'react';

const useKeyDownEvent = (keyCode: number, onKeyDown: () => void, ctrlDown = false) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleKeyDown = (event: any) => {
      const validateCtrlKey = !ctrlDown ? true : ctrlDown && event.ctrlKey;
      if (validateCtrlKey && event.keyCode === keyCode) {
        onKeyDown();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
};

export default useKeyDownEvent;
