import { FunctionComponent } from 'react';

import { Card } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import SelectSecurityPanel from '../../../../components/panels/SelectSecurityPanel';
import { setSecurity } from '../../../../store/order-underlying/reducer';
import { Security } from '../../../../types/entities';

const SelectSecurityStep: FunctionComponent = () => {
  const securityState = useAppSelector((gs) => gs.securityState);
  const securities = securityState.securities;

  const orderUnderlyingState = useAppSelector((gs) => gs.orderUnderlyingState);
  const selectedSecurity = orderUnderlyingState.security;

  const dispatch = useAppDispatch();

  const setSelectedSecurity = (sec: Security | undefined) => {
    dispatch(setSecurity(sec));
  };

  return (
    <Card sx={{ p: 2 }}>
      <SelectSecurityPanel securities={securities} selected={selectedSecurity} onSelected={setSelectedSecurity} />
    </Card>
  );
};

export default SelectSecurityStep;
