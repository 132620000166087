import { toast } from 'react-toastify';

import { AppDispatch } from '../';
import { fetchHistoricalData } from '../../api';
import { HistoricalDataParams } from '../../types/entities';

import { success, fail, loading } from './reducer';

export const getHistoricalDataAsync = (barChartId: number, params: HistoricalDataParams) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading({ id: barChartId, conId: params.conId, loading: true }));
    fetchHistoricalData(params)
      .then((response) => {
        const bars = response.data;
        dispatch(success({ id: barChartId, conId: params.conId, bars }));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail({ id: barChartId, conId: params.conId, error: msg }));
        toast.error(msg);
      });
  };
};
