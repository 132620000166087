import { FunctionComponent, useMemo } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { OptionContract, OptionRight, OrderAction } from '../../../types/entities';
import { setThousenSeparator } from '../../../utils/currency-utils';

interface Props {
  options: OptionContract[];
  action: OrderAction;
  right: OptionRight;
  optionSelected: OptionContract | undefined;
  onOptionSelected: (opt: OptionContract | undefined) => void;
}

const OptionChainTable: FunctionComponent<Props> = ({ options, action, right, optionSelected, onOptionSelected }: Props) => {
  const sortedOptions = options.sort((a: OptionContract, b: OptionContract) => (a.strikePrice > b.strikePrice ? -1 : 1));

  const header = (
    <TableHead sx={{ position: 'sticky' }}>
      <TableRow>
        <TableCell>Strike</TableCell>
        <TableCell align="right" title="Intrinsic Value">
          Intrinsic
        </TableCell>
        <TableCell align="right" title="Extrinsic Value">
          Extrinsic
        </TableCell>
        <TableCell align="right" title="Price">
          Price
        </TableCell>
        <TableCell align="right" title="Delta">
          Delta
        </TableCell>
        <TableCell align="right" title="Standard Deviation">
          SD
        </TableCell>
        <TableCell align="right" title="Premium">
          Premium
        </TableCell>
        <TableCell align="right" title="Premium">
          Undrl PnL
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const getStyles = (standardDeviation: number, isMarketPrice?: boolean) => {
    let gradiantBgColor = '';
    if (standardDeviation < 0.5) {
      gradiantBgColor = 'rgb(104, 55, 65, 0.5)'; // '#f0dc82';
    } else if (standardDeviation < 1) {
      gradiantBgColor = 'rgb(197, 91, 109, 0.5)'; // '#f8efc6';
    } else {
      gradiantBgColor = 'inherit';
    }
    return {
      '&:last-child td, &:last-child th': { border: 0 },
      cursor: isMarketPrice ? 'not-allowed' : 'pointer',
      backgroundColor: gradiantBgColor,
      whiteSpace: 'nowrap'
    };
  };

  const handleOptionRowClick = (opt: OptionContract) => {
    if (opt.conId === optionSelected?.conId) {
      onOptionSelected(undefined);
    } else {
      onOptionSelected(opt);
    }
  };

  const mapRow = (opt: OptionContract) => {
    const premium = action === 'BUY' ? opt.premium * -1 : opt.premium;
    return (
      <TableRow
        key={opt.localSymbol}
        sx={getStyles(opt.standardDeviation)}
        selected={opt.conId === optionSelected?.conId}
        onClick={() => handleOptionRowClick(opt)}
      >
        <TableCell component="th" scope="row">
          {opt.strikePrice}
        </TableCell>
        <TableCell align="right">{opt.intrinsicValue.toFixed(2)}</TableCell>
        <TableCell align="right">{opt.extrinsicValue.toFixed(2)}</TableCell>
        <TableCell align="right">{opt.optionPrice.toFixed(2)}</TableCell>
        <TableCell align="right">{opt.delta.toFixed(2)}</TableCell>
        <TableCell align="right">{opt.standardDeviation.toFixed(2)}</TableCell>
        <TableCell align="right">{setThousenSeparator(premium.toFixed(0))}</TableCell>
        <TableCell align="right">{setThousenSeparator(opt.underlyingProfit.toFixed(0))}</TableCell>
      </TableRow>
    );
  };

  const aboveMarketPriceOptions = useMemo(() => {
    if (right === 'CALL') {
      return sortedOptions.filter((opt) => opt.intrinsicValue === 0).map((opt) => mapRow(opt));
    } else {
      return sortedOptions.filter((opt) => opt.intrinsicValue > 0).map((opt) => mapRow(opt));
    }
  }, [sortedOptions, action, right, optionSelected]);

  const belowMarketPriceOptions = useMemo(() => {
    if (right === 'CALL') {
      return sortedOptions.filter((opt) => opt.intrinsicValue > 0).map((opt) => mapRow(opt));
    } else {
      return sortedOptions.filter((opt) => opt.intrinsicValue === 0).map((opt) => mapRow(opt));
    }
  }, [sortedOptions, action, right, optionSelected]);

  const sx = { sx: { textAlign: 'right', color: '#ff8d8d' } };
  const marketPriceRow = (
    <TableRow sx={getStyles(0, true)}>
      <TableCell component="th" scope="row" sx={{ color: '#ff8d8d' }}>
        MARKET
      </TableCell>
      <TableCell {...sx}>-</TableCell>
      <TableCell {...sx}>-</TableCell>
      <TableCell {...sx}>-</TableCell>
      <TableCell {...sx}>0.50</TableCell>
      <TableCell {...sx}>0</TableCell>
      <TableCell {...sx}>-</TableCell>
      <TableCell {...sx}>-</TableCell>
    </TableRow>
  );

  return (
    <TableContainer sx={{ height: '400px' }}>
      <Table size="small">
        {header}
        <TableBody>
          {aboveMarketPriceOptions}
          {marketPriceRow}
          {belowMarketPriceOptions}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OptionChainTable;
