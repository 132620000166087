import { ContractDetails } from '../../types/entities';

export interface ContractsByUnderlyingSymbolState {
  loading: boolean;
  initialLoaded: boolean;
  loaded: boolean;
  error?: string;
  contracts: ContractDetails[];
}

export const initialContractsByUnderlyingSymbolState: ContractsByUnderlyingSymbolState = {
  loading: false,
  initialLoaded: false,
  loaded: false,
  error: undefined,
  contracts: []
};

export interface SecurityContractState {
  [symbol: string]: ContractsByUnderlyingSymbolState;
}

export const initialSecurityContractState: SecurityContractState = {};
