import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface Props {
  sx?: object;
  children?: React.ReactNode;
}

/**
 * Bruk gutter-boks sammen med ResponsiveContainer; hvor man unntaksvis ønsker å enable gutter
 */
const GutterBox: FunctionComponent<Props> = ({ sx, children }: Props) => {
  const theme = useTheme();
  const isXsViewPort = useMediaQuery(theme.breakpoints.only('xs'));
  if (isXsViewPort) {
    sx = {
      ...sx,
      mx: 2
    };
  }

  return <Box sx={sx}>{children}</Box>;
};

export default GutterBox;
