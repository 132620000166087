import { SxProps, Tabs } from '@mui/material';

interface Props<T> {
  value: T;
  onChange: (_event: React.SyntheticEvent, value: T) => void;
  children: React.ReactNode[];
  sx?: SxProps;
}

const WrappingTabs = <T,>({ value, onChange, sx = {}, children }: Props<T>) => {
  const sxStyling: SxProps = {
    '& .MuiTabs-flexContainer': {
      flexWrap: 'wrap'
    },
    ...sx
  };

  return (
    <Tabs value={value} onChange={onChange} sx={sxStyling}>
      {children}
    </Tabs>
  );
};

export default WrappingTabs;
