import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Position } from '../../types/entities';

import { DashboardState, initialDashboardState } from './state';

const dashboaredSlice = createSlice({
  name: 'dashboard',
  initialState: initialDashboardState,
  reducers: {
    reset: () => initialDashboardState,
    loading: (state: DashboardState, action: PayloadAction<boolean>) => {
      state.error = undefined;
      state.loading = action.payload;
      state.loaded = !action.payload;
    },
    loaded: (state: DashboardState, action: PayloadAction<boolean>) => {
      state.error = undefined;
      state.loaded = action.payload;
    },
    fail: (state: DashboardState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    setSelectedPosition: (state: DashboardState, action: PayloadAction<Position>) => {
      state.selected = action.payload;
    }
  }
});

const { actions, reducer } = dashboaredSlice;
export const { reset, loading, loaded, fail, setSelectedPosition } = actions;
export default reducer;
