import { FunctionComponent } from 'react';

import { Box, Button, Card } from '@mui/material';
import { useDispatch } from 'react-redux';

import { ORDER_PAGE_DAILY_CHART_ID } from '../../assets/constants';
import BarChartPanel from '../../components/panels/BarChartPanel';
import OptionIndicatorPanel from '../../components/panels/OptionIndicatorPanel';
import TrendLinePanel from '../../components/panels/TrendLinePanel';
import AccordionWrapper from '../../components/ui/AccordionWrapper';
import { useAppSelector } from '../../store/hooks';
import { setTrendLinesCollapsed } from '../../store/user-settings/reducer';
import { ContractDetails, OptionContract, OrderAction } from '../../types/entities';

interface Props {
  orderAction: OrderAction;
  underlying: ContractDetails;
  contractAlias?: string | undefined;
  optionContract?: OptionContract | undefined;
  entryPrice?: number | undefined;
  strikePrice?: number | undefined;
  limitPrice?: number | undefined;
  stopPrice?: number | undefined;
  onAddOrder: () => void;
  addOrderDisabled?: boolean | undefined;
}

const OrderDetailsPanel: FunctionComponent<Props> = ({
  underlying,
  optionContract,
  orderAction,
  contractAlias,
  entryPrice,
  strikePrice,
  limitPrice,
  stopPrice,
  onAddOrder,
  addOrderDisabled = false
}: Props) => {
  const contract = underlying.contract;
  const { trendLinesCollapsed } = useAppSelector((gs) => gs.userSettingsState);
  const dispatch = useDispatch();
  const toggleTrendLinesCollapsed = () => dispatch(setTrendLinesCollapsed(!trendLinesCollapsed));
  return (
    <Card>
      {optionContract && <OptionIndicatorPanel option={optionContract} orderAction={orderAction} />}
      <Box sx={{ p: 1 }}>
        <BarChartPanel
          barChartId={ORDER_PAGE_DAILY_CHART_ID}
          conId={contract.conId}
          title={contractAlias || contract.localSymbol}
          exchange={contract.exchange}
          entryPrice={entryPrice}
          strikePrice={strikePrice}
          limitPrice={limitPrice}
          stopPrice={stopPrice}
          action={orderAction}
        />
      </Box>
      <AccordionWrapper title="Trend Lines" collapsed={trendLinesCollapsed} onChange={toggleTrendLinesCollapsed}>
        <TrendLinePanel conId={contract.conId} />
      </AccordionWrapper>
      <Box sx={{ m: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button color="success" variant="contained" onClick={onAddOrder} disabled={addOrderDisabled}>
          Add Order
        </Button>
      </Box>
    </Card>
  );
};

export default OrderDetailsPanel;
