import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import CalculateIcon from '@mui/icons-material/Calculate';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Grid,
  FormControlLabel,
  Switch,
  TextField,
  Divider,
  Box,
  Tooltip,
  IconButton,
  CircularProgress,
  Typography,
  Alert,
  Button
} from '@mui/material';

import { Position, PositionGroup } from '../../../types/entities';
import { setThousenSeparator } from '../../../utils/currency-utils';
import { useRefreshMarketData } from '../../hooks/useRefreshMarketData';

import { AveragingResult, testAveraging } from './averaging-test-utils';

import './styles.scss';

interface Props {
  group: PositionGroup;
  groupPositions?: Position[] | undefined;
  onChange: (pos: PositionGroup) => void;
  saveGroup: () => void;
  p?: number | undefined;
}
const AvergingForm: FunctionComponent<Props> = ({ group, groupPositions = [], onChange, saveGroup, p = 0 }) => {
  const { refreshMarketData, loading } = useRefreshMarketData();
  const firstPosition = groupPositions.length > 0 ? groupPositions[0] : undefined;
  const [useSimpleAvg, setUseSimpleAvg] = useState(true);
  const [useBiasRange, setUseBiasRange] = useState(true);

  const avgUpAmount = useMemo(() => {
    if (group.avgUpChangePct > 0 && group.initialMarketPrice > 0) {
      return (group.initialMarketPrice * group.avgUpChangePct) / 100;
    }
    return 0;
  }, [group.initialMarketPrice, group.avgUpChangePct]);

  const avgDownAmount = useMemo(() => {
    if (group.avgDownChangePct > 0 && group.initialMarketPrice > 0) {
      return (group.initialMarketPrice * group.avgDownChangePct) / 100;
    }
    return 0;
  }, [group.initialMarketPrice, group.avgDownChangePct]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUpdateForm = (field: keyof PositionGroup, value: any) => {
    const copy = {
      ...group,
      logs: [...(group.logs ?? [])],
      [field]: value
    } as PositionGroup;
    onChange(copy);
  };

  useEffect(() => {
    if (group.initialSize === 0) {
      const sum = groupPositions
        .map((x) => x.size)
        .reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0);
      handleUpdateForm('initialSize', sum);
    }
  }, [groupPositions]);

  useEffect(() => {
    if (group && firstPosition) {
      if (!group.avgMultiplier) {
        handleUpdateForm('avgMultiplier', firstPosition.multiplier ?? 1);
      }
    }
  }, [group, firstPosition]);

  const onRefreshMarketDataCallback = (pos: Position | undefined) => {
    if (pos) {
      handleUpdateForm('initialMarketPrice', pos.marketPrice);
    }
  };

  const handleRefreshBtnClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (firstPosition) {
      await refreshMarketData(firstPosition, onRefreshMarketDataCallback);
    }
  };

  const [avgResult, setAvgResult] = useState<AveragingResult | undefined>();

  const handleCalculateBtnClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    saveGroup();
    const result = testAveraging(group, useSimpleAvg, useBiasRange);
    setAvgResult(result);
  };

  return (
    <>
      <Grid p={p} container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch sx={{ ml: 1 }} onChange={() => handleUpdateForm('useAveraging', !group.useAveraging)} checked={group.useAveraging} />
            }
            label="Use Averaging"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            size="small"
            label="Average Up Pct"
            variant="outlined"
            type="number"
            value={group.avgUpChangePct}
            onChange={(e) => handleUpdateForm('avgUpChangePct', Number(e.target.value))}
            helperText="Sell-high trigger"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            size="small"
            label="Average Down Pct"
            variant="outlined"
            type="number"
            value={group.avgDownChangePct}
            onChange={(e) => handleUpdateForm('avgDownChangePct', Number(e.target.value))}
            helperText="Buy-low trigger"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            size="small"
            label="Max Size"
            variant="outlined"
            type="number"
            value={group.maxSize}
            onChange={(e) => handleUpdateForm('maxSize', Number(e.target.value))}
            helperText="Threshold for number of buy-ins"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            size="small"
            label="Avg Order Size"
            variant="outlined"
            type="number"
            value={group.avgSizeChange}
            onChange={(e) => handleUpdateForm('avgSizeChange', Number(e.target.value))}
            helperText="Trigger size"
            fullWidth
          />
        </Grid>
      </Grid>
      <Divider />
      <Typography variant="subtitle1" component="div" pt={2} pl={2}>
        Group Averaging Tester
      </Typography>
      <Grid p={p} container spacing={2}>
        <Grid item xs={6}>
          <TextField
            size="small"
            label="Initial Size"
            variant="outlined"
            type="number"
            value={group.initialSize}
            onChange={(e) => handleUpdateForm('initialSize', Number(e.target.value))}
            helperText="Can be 0 only if max-turns has value"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            label="Avg Max Turns"
            variant="outlined"
            type="number"
            value={group.avgMaxTurns}
            onChange={(e) => handleUpdateForm('avgMaxTurns', Number(e.target.value))}
            helperText="Will not complete until reached max turns (even with size 0)"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            size="small"
            label="Avg Multiplier"
            variant="outlined"
            type="number"
            value={group.avgMultiplier}
            onChange={(e) => handleUpdateForm('avgMultiplier', Number(e.target.value))}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <TextField
              size="small"
              label="Market Price"
              variant="outlined"
              type="number"
              value={group.initialMarketPrice}
              onChange={(e) => handleUpdateForm('initialMarketPrice', Number(e.target.value))}
              fullWidth
            />
            {firstPosition && (
              <Tooltip title="Refresh Market Price">
                <IconButton size="small" onClick={handleRefreshBtnClick}>
                  <RefreshIcon sx={{ fontSize: 20 }} />
                  {loading && (
                    <CircularProgress
                      size={30}
                      sx={{
                        color: 'white',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 1
                      }}
                    />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField size="small" label="Avg. Up" variant="outlined" type="number" value={avgUpAmount} disabled fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField size="small" label="Avg. Down" variant="outlined" type="number" value={avgDownAmount} disabled fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            label="Trend Bias Pct"
            variant="outlined"
            type="number"
            value={group.trendBiasPct}
            onChange={(e) => handleUpdateForm('trendBiasPct', Number(e.target.value))}
            helperText="The speculative strength of the trend (in %)"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            label="Volatility Pct"
            variant="outlined"
            type="number"
            value={group.volatilityPct}
            onChange={(e) => handleUpdateForm('volatilityPct', Number(e.target.value))}
            helperText="How much the price fluctuates (in %) for each turn"
            fullWidth
          />
        </Grid>
      </Grid>
      {avgResult && (
        <Alert sx={{ mb: 2, mx: 1, px: 1 }} severity={avgResult.sumPnl > 0 ? 'success' : 'error'} variant="standard">
          Calulcation Details:
          <Grid container mt={1} spacing={1} alignItems="center">
            <Grid item xs={8} sm={6} textAlign="right">
              Averaging Down (opening)
            </Grid>
            <Grid item xs={4} sm={6} sx={{ fontWeight: 'bold', pl: 1 }}>
              {avgResult.numAvgDown} times
            </Grid>
            <Grid item xs={8} sm={6} textAlign="right">
              Averaging Up (closing)
            </Grid>
            <Grid item xs={4} sm={6} sx={{ fontWeight: 'bold', pl: 1 }}>
              {avgResult.numAvgUp} times
            </Grid>
            <Grid item xs={8} sm={6} textAlign="right">
              Total Turns
            </Grid>
            <Grid item xs={4} sm={6} sx={{ fontWeight: 'bold', pl: 1 }}>
              {avgResult.turns}
            </Grid>
            <Grid item xs={8} sm={6} textAlign="right">
              Lowest Entry
            </Grid>
            <Grid item xs={4} sm={6} sx={{ fontWeight: 'bold', pl: 1 }}>
              {Math.min(...avgResult.positions.map((x) => x.entryPrice)).toFixed(2)}
            </Grid>
            <Grid item xs={8} sm={6} textAlign="right">
              Highest Exit
            </Grid>
            <Grid item xs={4} sm={6} sx={{ fontWeight: 'bold', pl: 1 }}>
              {Math.min(...avgResult.positions.map((x) => x.exitPrice || 0)).toFixed(2)}
            </Grid>
            <Grid item xs={8} sm={6} textAlign="right">
              Sum Profit And Loss
            </Grid>
            <Grid item xs={4} sm={6} sx={{ fontWeight: 'bold', pl: 1 }}>
              ${setThousenSeparator(avgResult.sumPnl.toFixed(0))}
            </Grid>
            <Grid item xs={8} sm={6} textAlign="right">
              Average PnL
            </Grid>
            <Grid item xs={4} sm={6} sx={{ fontWeight: 'bold', pl: 1 }}>
              ${setThousenSeparator((avgResult.sumPnl / avgResult.totalSize).toFixed(0))}
            </Grid>
          </Grid>
        </Alert>
      )}
      <Grid px={2} pb={2} container spacing={2}>
        <Grid item xs={12} sm={8} sx={{ display: 'flex', gap: 1, justifyContent: 'flex-start', alignItems: 'center' }}>
          <FormControlLabel
            control={<Switch sx={{ ml: 1 }} onChange={() => setUseBiasRange(!useBiasRange)} checked={useBiasRange} />}
            label={<Typography variant="caption">Bias Range</Typography>}
          />
          <FormControlLabel
            control={<Switch sx={{ ml: 1 }} onChange={() => setUseSimpleAvg(!useSimpleAvg)} checked={useSimpleAvg} />}
            label={<Typography variant="caption">Server Averaging</Typography>}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button endIcon={<CalculateIcon />} variant="outlined" onClick={handleCalculateBtnClick}>
            Calculate
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AvergingForm;
