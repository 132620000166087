import { FundamentalLink } from '../../types/entities';

export interface FundamentalLinkStateBySymbol {
  loading: boolean;
  loaded: boolean;
  error?: string;
  links: FundamentalLink[];
}

export const initialFundamentalLinkStateBySymbol: FundamentalLinkStateBySymbol = {
  loading: false,
  loaded: false,
  error: undefined,
  links: []
};

export interface FundamentalLinkState {
  [symbol: string]: FundamentalLinkStateBySymbol;
}

export const initialFundamentalLinkState: FundamentalLinkState = {};
