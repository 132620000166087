import { toast } from 'react-toastify';

import { AppDispatch } from '..';
import { createQuickNote, deleteQuickNote, fetchQuickNotes } from '../../api';
import { QuickNote } from '../../types/entities';
import { Logger } from '../../utils/Logger';

import { success, fail, loading, add, remove } from './reducer';

export const getQuickNotesAsync = (symbol: string) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading({ symbol, loading: true }));
    fetchQuickNotes(symbol)
      .then((response) => {
        const notes = response.data;
        Logger.log(notes);
        dispatch(success({ symbol, notes }));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail({ symbol: symbol, error: msg }));
        toast.error(msg);
      });
  };
};

export const createQuickNoteAsync = (note: QuickNote) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading({ symbol: note.symbol, loading: true }));
    createQuickNote(note)
      .then((response) => {
        const linkWithId = response.data;
        dispatch(add({ symbol: note.symbol, note: linkWithId }));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const removeQuickNoteAsync = (note: QuickNote) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading({ symbol: note.symbol, loading: true }));
    deleteQuickNote(note)
      .then(() => {
        dispatch(remove({ symbol: note.symbol, note }));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};
