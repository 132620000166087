import { FunctionComponent, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Card, Tooltip } from '@mui/material';

import SecurityCategoryTabs from '../../../components/ui/SecurityCategoryTabs';
import { useAppSelector } from '../../../store/hooks';
import { Security } from '../../../types/entities';
import { SecurityCategory } from '../../../types/enums';

import SearchDialog from './SearchDialog';
import SecurityDialog from './SecurityDialog';
import SecurityListTable from './SecurityListTable';

const SecuritiesTab: FunctionComponent = () => {
  const securityState = useAppSelector((gs) => gs.securityState);
  const { securities } = securityState;

  const [securitySelected, setSecuritySelected] = useState<Security | undefined>();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showSearchDialog, setShowSearchDialog] = useState(false);

  const { selectedSecurityCategory } = useAppSelector((gs) => gs.userSettingsState);

  const filteredSecurities = useMemo(() => {
    switch (selectedSecurityCategory) {
      case SecurityCategory.Futures:
        return securities.filter((x) => x.category === SecurityCategory.Futures);
      case SecurityCategory.MicroFutures:
        return securities.filter((x) => x.category === SecurityCategory.MicroFutures);
      case SecurityCategory.Stock:
        return securities.filter((x) => x.category === SecurityCategory.Stock);
    }
  }, [securities, selectedSecurityCategory]);

  const onSecuritySelected = (sec: Security) => {
    setSecuritySelected(sec);
    setShowEditDialog(true);
  };

  const handleSecuritySearchBtnClick = () => {
    setSecuritySelected(undefined);
    setShowSearchDialog(true);
  };

  const handleAddSecurityBtnClick = () => {
    setSecuritySelected(undefined);
    setShowEditDialog(true);
  };

  return (
    <Box>
      <SecurityCategoryTabs />
      <Card>
        <Box sx={{ m: 2, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Tooltip title="Security Search" placement="top">
            <Button size="small" color="primary" variant="outlined" onClick={handleSecuritySearchBtnClick}>
              <SearchIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Add Security" placement="top-end">
            <Button size="small" color="primary" variant="outlined" onClick={handleAddSecurityBtnClick}>
              <AddIcon />
            </Button>
          </Tooltip>
        </Box>
        <SecurityListTable securities={filteredSecurities} onSecuritySelected={onSecuritySelected} />
      </Card>
      {showEditDialog && (
        <SecurityDialog
          isOpen={showEditDialog}
          onClose={() => setShowEditDialog(false)}
          securities={securities}
          security={securitySelected}
          category={selectedSecurityCategory}
        />
      )}
      {showSearchDialog && (
        <SearchDialog
          isOpen={showSearchDialog}
          onClose={() => setShowSearchDialog(false)}
          securities={securities}
          category={selectedSecurityCategory}
        />
      )}
    </Box>
  );
};

export default SecuritiesTab;
