import { TableContainer } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { INDICATOR_GREEN_COLOR, INDICATOR_RED_COLOR } from '../../../assets/colors';
import { OptionContract, OrderAction } from '../../../types/entities';
import { formatNum } from '../../../utils/currency-utils';

interface Props {
  optionContract: OptionContract;
  orderAction: OrderAction;
}

export default function TimeDetailsTable({ optionContract, orderAction }: Props) {
  const {
    strikePrice,
    expiresInDays,
    expiresInHours,
    weeksSinceStrikePrice,
    daysSinceStrikePrice,
    hoursSinceStrikePrice,
    timeSinceStrikeError
  } = optionContract;

  const timeSinceStrikePrice = optionContract.timeSinceStrikePrice;

  let timeToExpireIsLessThanTimeSinceStrikePrice = false;
  if (!timeSinceStrikeError) {
    if (weeksSinceStrikePrice === -99) {
      timeToExpireIsLessThanTimeSinceStrikePrice = true; // infinite
    } else if (weeksSinceStrikePrice > 0) {
      timeToExpireIsLessThanTimeSinceStrikePrice = true;
    } else {
      const expires = expiresInDays * 24 + expiresInHours;
      const timeSinceStrikePrice = daysSinceStrikePrice * 24 + hoursSinceStrikePrice;
      timeToExpireIsLessThanTimeSinceStrikePrice = expires < timeSinceStrikePrice;
    }
  }

  const timeIndicator: 'Shortest' | 'Longest' = timeToExpireIsLessThanTimeSinceStrikePrice ? 'Shortest' : 'Longest';
  let timeIndicatorColor = 'Inherit';
  if (orderAction === 'BUY') {
    timeIndicatorColor = !timeToExpireIsLessThanTimeSinceStrikePrice ? INDICATOR_GREEN_COLOR : INDICATOR_RED_COLOR;
  } else {
    timeIndicatorColor = timeToExpireIsLessThanTimeSinceStrikePrice ? INDICATOR_GREEN_COLOR : INDICATOR_RED_COLOR;
  }

  return (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">Time</TableCell>
            <TableCell align="right">Value</TableCell>
            <TableCell align="right">Indicator</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="right" style={{ fontWeight: 'bold' }}>
              Bid At Strike
            </TableCell>
            <TableCell align="right">
              <span>{timeSinceStrikePrice}</span>
            </TableCell>
            <TableCell align="right">
              <span>${formatNum(strikePrice)}</span>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right" style={{ fontWeight: 'bold' }}>
              Expires
            </TableCell>
            <TableCell title="Expires" align="right">
              {`${expiresInDays}d ${expiresInHours}h`}
            </TableCell>
            <TableCell align="right">-</TableCell>
            <TableCell align="right">
              <span style={{ color: timeIndicatorColor }}>{timeIndicator}</span>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
