import { FunctionComponent, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Card, Tooltip, Typography } from '@mui/material';

import PortfolioDialog from '../../../components/dialogs/PortfolioDialog';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setSelectedPortfolio } from '../../../store/user-settings/reducer';
import { Portfolio } from '../../../types/entities';

import PortfolioContractsPanel from './PortfolioContractsPanel';
import PortfolioTable from './PortfolioTable';

const ManagePortfoliosTab: FunctionComponent = () => {
  const { portfolios } = useAppSelector((gs) => gs.portfolioState);
  const { selectedPortfolio } = useAppSelector((gs) => gs.userSettingsState);
  const portfolio = portfolios.find((x) => x.id === selectedPortfolio?.id);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const closeDialog = () => setShowEditDialog(false);

  const dispatch = useAppDispatch();

  const handleEditBtnClick = (portfolio: Portfolio | undefined) => {
    dispatch(setSelectedPortfolio(portfolio));
    setShowEditDialog(true);
  };

  return (
    <>
      <Card>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', m: 2 }}>
          <Typography variant="h6">Portfolios</Typography>
          <Tooltip title="Add Portfolio" placement="left-start">
            <Button size="small" color="primary" variant="outlined" onClick={() => handleEditBtnClick(undefined)}>
              <AddIcon />
            </Button>
          </Tooltip>
        </Box>
        <PortfolioTable onEditBtnClick={handleEditBtnClick} />
      </Card>
      {portfolio ? (
        <Box sx={{ mt: 2 }}>
          <PortfolioContractsPanel portfolio={portfolio} />
        </Box>
      ) : (
        <Typography sx={{ m: 2 }} fontSize="smaller" component="div">
          No portfolio selected..
        </Typography>
      )}
      {showEditDialog && <PortfolioDialog portfolio={portfolio} isOpen={showEditDialog} onClose={closeDialog} />}
    </>
  );
};

export default ManagePortfoliosTab;
