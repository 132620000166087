import * as React from 'react';
import { useMemo } from 'react';

import { Tooltip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addPortfolioContractAsync } from '../../../store/portfolio/service';
import { ContractDetails, Portfolio } from '../../../types/entities';
import { mapToDto } from '../../../utils/contract-details-utils';
import DropDownButton from '../../ui/DropDownButton';
import GutterBox from '../../ui/GutterBox';

interface Props {
  details: ContractDetails;
}

const AddToPortfolioButton: React.FunctionComponent<Props> = ({ details }: Props) => {
  const { portfolios } = useAppSelector((gs) => gs.portfolioState);
  const dispatch = useAppDispatch();

  const sortByName = (a: Portfolio, b: Portfolio) => (a.name > b.name ? 1 : -1);
  const sortedPorfolios = useMemo(() => {
    return portfolios.slice().sort(sortByName);
  }, [portfolios]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const closeMenu = () => setAnchorEl(null);

  const handleAddPortfolioBtnClick = (portfolio: Portfolio) => {
    const exists = portfolio.contracts.find((x) => x.contract.conId === details.contract.conId);
    if (exists) {
      toast(`Contract already exists in ${portfolio.name}.`);
    } else {
      const dtoReady = mapToDto(details);
      dispatch(addPortfolioContractAsync(portfolio, dtoReady));
    }
    closeMenu();
  };

  const menuOptions = sortedPorfolios.map((x) => (
    <MenuItem key={x.id} onClick={() => handleAddPortfolioBtnClick(x)} disableRipple>
      <Tooltip title={x.comment}>
        <span>{x.name}</span>
      </Tooltip>
    </MenuItem>
  ));
  return (
    <GutterBox>
      <DropDownButton label="Add To.." variant="outlined" anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        {menuOptions}
      </DropDownButton>
    </GutterBox>
  );
};

export default AddToPortfolioButton;
