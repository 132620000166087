import { FunctionComponent } from 'react';

import { Card, useTheme } from '@mui/material';

import { AVERAGING_BORDER_COLOR } from '../../../../assets/colors';
import { PositionGroup } from '../../../../types/entities';
import { PositionGroupType } from '../../../../types/enums';

import PositionGroupBody from './PositionGroupBody';
import PositionGroupHeader from './PositionGroupHeader';

interface Props {
  group: PositionGroup;
}

const PositionGroupPanel: FunctionComponent<Props> = ({ group }: Props) => {
  const theme = useTheme();
  const borderColor = group.type === PositionGroupType.Averaging ? AVERAGING_BORDER_COLOR : theme.palette.grey[500];
  return (
    <Card sx={{ border: `1px solid ${borderColor}` }}>
      <PositionGroupHeader group={group} />
      <PositionGroupBody group={group} />
    </Card>
  );
};

export default PositionGroupPanel;
