import { Position } from '../../types/entities';

export interface TradeHistoryStateByDate {
  loading: boolean;
  loaded: boolean;
  error?: string;
  positions: Position[];
}

export const initialTradeHistoryStateByDate: TradeHistoryStateByDate = {
  loading: false,
  loaded: false,
  error: undefined,
  positions: []
};

export interface TradeHistoryState {
  [yearDashMonth: string]: TradeHistoryStateByDate;
}

export const initialTradeHistoryState: TradeHistoryState = {};
