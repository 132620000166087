import { FunctionComponent, useMemo } from 'react';

import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { TABLE_BORDER_COLOR } from '../../assets/colors';
import { useConfirm } from '../../components/hooks/useConfirm';
import { useContractDetailsQuery } from '../../components/hooks/useContractDetailsQuery';
import Spinner from '../../components/ui/Spinner';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { cancelOpenOrderAsync } from '../../store/open-order/service';
import { OpenOrderReport, Position } from '../../types/entities';
import { formatNum } from '../../utils/currency-utils';

interface Props {
  symbol: string;
  positionSelected: Position | undefined;
  onPositionSelected: (pos: Position | undefined) => void;
}

const ExistingOrdersTable: FunctionComponent<Props> = ({ symbol, positionSelected, onPositionSelected }: Props) => {
  // DEV-NOTE: distinct between order and position. These are not interchangable
  const UNIQUE_ORDER_CONID_MODIFIER = 1000;

  const [confirm, ConfirmDialog] = useConfirm();
  const query = useContractDetailsQuery();
  const dispatch = useAppDispatch();

  const { orders: openOrders } = useAppSelector((gs) => gs.openOrderState);
  const { positions } = useAppSelector((gs) => gs.positionState);

  const filteredOrders = useMemo(() => {
    return openOrders.slice().filter((x) => x.symbol === symbol);
  }, [openOrders]);
  const sortPositions = (a: Position, b: Position) => {
    if (!a || !b) {
      return 0;
    }
    return a.sortOrder < b.sortOrder ? 1 : -1;
  };

  const securityPositions = useMemo(() => {
    return positions
      .slice()
      .filter((x) => x.symbol === symbol && !x.completed)
      .sort(sortPositions);
  }, [positions]);

  const handleRowMouseClick = (order: OpenOrderReport, pos?: Position) => {
    if (pos) {
      // use trigger-price as strike-price to show in chart where it triggers
      const copy = {
        ...pos,
        conId: pos.conId + UNIQUE_ORDER_CONID_MODIFIER,
        entryPrice: order.triggerPrice,
        strikePrice: order.triggerPrice
      };
      onPositionSelected(copy);
    }
  };

  const handleCancelOrderBtnClick = async (event: React.MouseEvent<HTMLButtonElement>, openOrder: OpenOrderReport) => {
    event.stopPropagation();
    if (await confirm('Are you sure you want to cancel this order?')) {
      dispatch(cancelOpenOrderAsync(openOrder));
    }
  };

  if (filteredOrders.length === 0) {
    return (
      <Box sx={{ m: 2 }}>
        <small>No Orders..</small>
      </Box>
    );
  }

  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableCell>Sec Type</TableCell>
        <TableCell>Symbol</TableCell>
        <TableCell>Local</TableCell>
        <TableCell align="center">Size</TableCell>
        <TableCell>Type</TableCell>
        <TableCell>Action</TableCell>
        <TableCell align="center">Right</TableCell>
        <TableCell align="right">Entry Price</TableCell>
        <TableCell align="right">Trigger Price</TableCell>
        <TableCell align="center">Status</TableCell>
        <TableCell align="center">Canceled</TableCell>
        <TableCell align="center"></TableCell>
      </TableRow>
    </TableHead>
  );

  const orderRows = filteredOrders.map((order: OpenOrderReport, i) => {
    const pos = securityPositions.find((x) => x.conId === order.conId);
    const isOption = pos?.secType === 'OPT' || pos?.secType === 'FOP';
    const ep = (isOption ? pos?.underEntryPrice : pos?.entryPrice) ?? 0;
    const entryPrice = ep > 0 ? `$${formatNum(ep)}` : '-';
    const triggerPrice = order.triggerPrice != 0 ? `$${formatNum(order.triggerPrice)}` : '-';

    return (
      <TableRow
        key={`${order.conId}-${i}`}
        selected={order.conId + UNIQUE_ORDER_CONID_MODIFIER === positionSelected?.conId}
        sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
        onClick={() => handleRowMouseClick(order, pos)}
      >
        <TableCell>{pos?.secType}</TableCell>
        <TableCell>{order.symbol}</TableCell>
        <TableCell>{pos?.localSymbol}</TableCell>
        <TableCell align="center">{order.size}</TableCell>
        <TableCell>{order.type}</TableCell>
        <TableCell>{order.action}</TableCell>
        <TableCell align="center">{pos?.right || '-'}</TableCell>
        <TableCell align="right">{entryPrice}</TableCell>
        <TableCell align="right">{triggerPrice}</TableCell>
        <TableCell align="center">{order.orderStatus}</TableCell>
        <TableCell align="center">{order.canceled ? 'Y' : 'N'}</TableCell>
        <TableCell align="right" sx={{ width: '80px', px: 1, borderLeft: `1px solid ${TABLE_BORDER_COLOR}` }}>
          <Button
            size="small"
            color="error"
            variant="contained"
            onClick={(e) => handleCancelOrderBtnClick(e, order)}
            disabled={order.size === 0}
          >
            Cancel
          </Button>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <TableContainer>
        <Table size="small">
          {tableHeader}
          <TableBody>{orderRows}</TableBody>
        </Table>
      </TableContainer>
      <ConfirmDialog />
      <Spinner loading={query.loading} />
    </>
  );
};

export default ExistingOrdersTable;
