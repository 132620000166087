import { useEffect } from 'react';

import { Security } from '../../types/entities';
import { useAppDispatch, useAppSelector } from '../hooks';

import { getContractsByUnderlyingSymbolState } from './selectors';
import { getSecurityContractsAsync } from './service';

export const useSecurityContractStore = (security: Security | undefined, fetchOnInit = false) => {
  const secContractState = useAppSelector((gs) => gs.securityContractState);
  const { contracts, loaded } = getContractsByUnderlyingSymbolState(secContractState, security?.symbol ?? '');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (fetchOnInit && !loaded && security) {
      dispatch(getSecurityContractsAsync(security));
    }
  }, [fetchOnInit, loaded, security]);

  return {
    loaded,
    contracts
  };
};
