import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ContractDetails } from '../../types/entities';

import { OrderState, initialOrderState } from './state';

export type SetContractDetailsAction = {
  symbol: string;
  details: ContractDetails | undefined;
};

const orderSlice = createSlice({
  name: 'order',
  initialState: initialOrderState,
  reducers: {
    reset: () => initialOrderState,
    setContractDetails: (state: OrderState, action: PayloadAction<ContractDetails | undefined>) => {
      state.details = action.payload;
    }
  }
});

const { actions, reducer } = orderSlice;
export const { setContractDetails } = actions;
export default reducer;
