import { addFavoriteAsync, removeFavoriteAsync } from '../../store/favorite-contract/service';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ContractDetails, FavoriteContract } from '../../types/entities';
import { mapToDto } from '../../utils/contract-details-utils';

const useToggleFavorite = (): [isFavorite: (details: ContractDetails) => boolean, toggleFavorite: (details: ContractDetails) => void] => {
  const { favorites } = useAppSelector((gs) => gs.favoriteContractState);

  const isFavorite = (details: ContractDetails): boolean => {
    const conId = details.contract.conId;
    const fav = favorites.find((x) => x.details.contract.conId === conId);
    return !!fav;
  };

  const { securities } = useAppSelector((gs) => gs.securityState);
  const getSecurity = (details: ContractDetails) => {
    const sec = securities.find((x) => x.symbol === details.contract.symbol);
    return sec ? sec.name : 'N/A';
  };

  const dispatch = useAppDispatch();

  const toggleFavorite = (details: ContractDetails) => {
    const favorite = favorites.find((x) => x.details.contract.conId === details.contract.conId);
    if (!!favorite) {
      dispatch(removeFavoriteAsync(favorite));
    } else {
      const fav: FavoriteContract = {
        securityName: getSecurity(details),
        details: mapToDto(details)
      };
      dispatch(addFavoriteAsync(fav));
    }
  };

  return [isFavorite, toggleFavorite];
};

export default useToggleFavorite;
