import { FunctionComponent, useEffect } from 'react';

import { Box, Button, Card, TextField } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import MarginPanel from '../../../../components/panels/MarginPanel';
import Spinner from '../../../../components/ui/Spinner';
import { setOrderSize } from '../../../../store/order-option/reducer';
import { checkOrderMarginAsync } from '../../../../store/order-option/service';
import { CheckMarginParams, OptionContract } from '../../../../types/entities';

const MarginOverviewStep: FunctionComponent = () => {
  const orderOptionState = useAppSelector((gs) => gs.orderOptionState);
  const option = orderOptionState.option as OptionContract;
  const { orderAction, orderSize, loading, margin } = orderOptionState;

  const dispatch = useAppDispatch();

  const setOrderSizeHandler = (size: number) => {
    if (size > 0) {
      dispatch(setOrderSize(size));
    }
  };

  const handleCheckOptionMargin = () => {
    const optionMarginParams: CheckMarginParams = {
      conId: option.conId,
      localSymbol: option.localSymbol,
      secType: option.secType,
      exchange: option.exchange,
      currency: option.currency,
      multiplier: option.multiplier,
      orderAction,
      orderSize
    };
    dispatch(checkOrderMarginAsync(optionMarginParams));
  };

  useEffect(() => {
    if (!margin) {
      handleCheckOptionMargin();
    }
  }, []);

  return (
    <Card sx={{ mt: 3, p: 2 }}>
      <Spinner loading={loading} />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <Box sx={{ pr: 1, flexGrow: 1 }}>
            <TextField
              size="small"
              fullWidth
              label="Order Size"
              variant="outlined"
              type="number"
              value={orderSize}
              onChange={(e) => setOrderSizeHandler(Number(e.target.value))}
            />
          </Box>
          <Button onClick={() => handleCheckOptionMargin()} variant="outlined" type="button">
            Check
          </Button>
        </Box>
        {margin && <MarginPanel symbol={option.localSymbol} margin={margin} />}
      </Box>
    </Card>
  );
};

export default MarginOverviewStep;
