import { FunctionComponent, useEffect, useState } from 'react';

import { Alert, Box, Button, Card, CircularProgress } from '@mui/material';

import OrderTypePicker from '../../components/panels/OrderTypePicker';
import { useAppDispatch } from '../../store/hooks';
import { useMarketDataStore } from '../../store/market-data/hooks';
import { useOrderSummaryStore } from '../../store/order-summary/hooks';
import { addSummaryOrder } from '../../store/order-summary/reducer';
import { ContractDetails, OrderAction, OrderType } from '../../types/entities';
import { createUnderlyingEntryPosition } from '../../utils/position-utils';

import OrderDetailsPanel from './OrderDetailsPanel';

interface Props {
  underlying: ContractDetails;
  orderType?: OrderType | undefined;
}

const OrderUnderlyingPanel: FunctionComponent<Props> = ({ underlying, orderType = 'MKT' }: Props) => {
  const contract = underlying.contract;

  const { orderExists } = useOrderSummaryStore(contract);

  const { fetch, loading, error, marketPrice } = useMarketDataStore(contract, true);

  const [orderAction, setOrderAction] = useState<OrderAction>('BUY');
  const [orderTypeVal, setOrderTypeVal] = useState<OrderType>(orderType);
  const [entryPrice, setEntryPrice] = useState<number>(marketPrice || 0);

  useEffect(() => {
    if (marketPrice) {
      setEntryPrice(marketPrice);
    }
  }, [orderAction, orderType, marketPrice]);

  const dispatch = useAppDispatch();

  const handleAddOrderBtnClick = () => {
    if (!orderExists) {
      const order = createUnderlyingEntryPosition(underlying, orderAction, orderTypeVal, 1, entryPrice, marketPrice);
      dispatch(addSummaryOrder({ symbol: contract.symbol, order }));
    }
  };

  return (
    <>
      <Card sx={{ mb: 2 }}>
        <OrderTypePicker
          orderAction={orderAction}
          onOrderActionChanged={setOrderAction}
          orderType={orderTypeVal}
          onOrderTypeChanged={setOrderTypeVal}
          marketPrice={marketPrice}
          triggerPrice={entryPrice}
          onTriggerPriceChanged={setEntryPrice}
        />
        {error && (
          <Alert sx={{ mb: 2, mx: 2 }} color="error">
            Error loading market data..
          </Alert>
        )}
        <Box display="flex" justifyContent="flex-end" mr={2} mb={2}>
          <Button variant="outlined" onClick={() => fetch()}>
            Reload Market Price {loading && <CircularProgress sx={{ ml: 1 }} color="inherit" size="1rem" />}
          </Button>
        </Box>
      </Card>

      <OrderDetailsPanel
        underlying={underlying}
        orderAction={orderAction}
        entryPrice={orderTypeVal === 'MKT' ? entryPrice : undefined}
        limitPrice={orderTypeVal === 'LMT' ? entryPrice : undefined}
        stopPrice={orderTypeVal === 'STP' ? entryPrice : undefined}
        onAddOrder={handleAddOrderBtnClick}
        addOrderDisabled={orderExists}
      />
    </>
  );
};

export default OrderUnderlyingPanel;
