import { FunctionComponent, useMemo } from 'react';

import { Grid, FormControlLabel, Switch, TextField, Typography } from '@mui/material';

import { PositionGroup } from '../../../types/entities';
import { getDurationString } from '../../../utils/datetime-utils';
import SelectDateTimeControl from '../../form-controls/SelectDateTimeControl';

interface Props {
  group: PositionGroup;
  onChange: (pos: PositionGroup) => void;
  p?: number | undefined;
}
const GroupScheduleForm: FunctionComponent<Props> = ({ group, onChange, p = 0 }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUpdateForm = (field: keyof PositionGroup, value: any) => {
    const copy = {
      ...group,
      logs: [...(group.logs ?? [])],
      [field]: value
    } as PositionGroup;
    onChange(copy);
  };

  const setCreatedDate = (value: Date | undefined) => {
    if (value) {
      handleUpdateForm('created', value);
    }
  };

  const createdDate = useMemo(() => {
    return new Date(group.created as string);
  }, [group.created]);

  const duration = useMemo(() => {
    if (!!group.created) {
      const entryDt = new Date(group.created);
      return getDurationString(entryDt, new Date());
    }
    return '';
  }, [group.created]);

  return (
    <Grid p={p} container spacing={2}>
      <Grid item xs={12}>
        <SelectDateTimeControl label="Group Created" selectedValue={createdDate} onDateSelected={setCreatedDate} />
        {duration && (
          <Typography variant="caption" component="div" sx={{ mt: 1 }}>
            <i>Time Elapsed Since Group Created: {duration}</i>
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControlLabel
          control={
            <Switch
              sx={{ ml: 1 }}
              onChange={() => handleUpdateForm('useSmallProfit', !group.useSmallProfit)}
              checked={group.useSmallProfit}
            />
          }
          label="Use Small Profit"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          size="small"
          label="Hours Delayed"
          variant="outlined"
          type="number"
          value={group.smallProfitDelayHours}
          onChange={(e) => handleUpdateForm('smallProfitDelayHours', Number(e.target.value))}
          disabled={!group.useSmallProfit}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          size="small"
          label="Small Profit"
          variant="outlined"
          type="number"
          value={group.smallProfit}
          onChange={(e) => handleUpdateForm('smallProfit', Number(e.target.value))}
          disabled={!group.useSmallProfit}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControlLabel
          control={<Switch sx={{ ml: 1 }} onChange={() => handleUpdateForm('useTimeOut', !group.useTimeOut)} checked={group.useTimeOut} />}
          label="Use Time Out"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          size="small"
          label="Hours Delayed"
          variant="outlined"
          type="number"
          value={group.timeOutDelayHours}
          onChange={(e) => handleUpdateForm('timeOutDelayHours', Number(e.target.value))}
          disabled={!group.useTimeOut}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default GroupScheduleForm;
