import { useState } from 'react';

import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { fetchContractDetails, fetchUnderlyingContractDetails } from '../../api';
import { useAppDispatch } from '../../store/hooks';
import { setSelectedContractDetails, setSelectedExitPosition } from '../../store/user-settings/reducer';
import { ContractDetails, Position } from '../../types/entities';

export const useContractDetailsQuery = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const fetchContractDetailsAsync = async (position: Position, isOption = false) => {
    setLoading(true);
    let details: ContractDetails | undefined;
    try {
      const response = isOption ? await fetchUnderlyingContractDetails(position) : await fetchContractDetails(position);
      details = response.data;
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || error.message;
      toast.error(msg);
    }
    return details;
  };

  const gotoExitOrderAsync = async (position: Position) => {
    const isOption = position.secType === 'FOP' || position.secType === 'OPT';
    const details = await fetchContractDetailsAsync(position, isOption);
    if (details) {
      dispatch(setSelectedContractDetails(details));
      dispatch(setSelectedExitPosition(position));
      navigate(`/order?tab=${isOption ? 'option' : 'underlying'}&type=lmt`);
    }
  };

  return { fetchContractDetailsAsync, gotoExitOrderAsync, loading };
};
