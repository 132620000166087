import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Portfolio } from '../../types/entities';

import { initialState, PortfolioState } from './state';

const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    reset: () => initialState,
    loading: (state: PortfolioState, action: PayloadAction<boolean>) => {
      state.error = undefined;
      state.initialLoaded = state.initialLoaded;
      state.loading = action.payload;
      state.loaded = !action.payload;
    },
    success: (state: PortfolioState, action: PayloadAction<Portfolio[]>) => {
      state.error = undefined;
      state.loading = false;
      state.initialLoaded = true;
      state.loaded = true;
      state.portfolios = action.payload;
    },
    fail: (state: PortfolioState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.initialLoaded = true;
      state.loaded = true;
      state.portfolios = [];
    },
    add: (state: PortfolioState, action: PayloadAction<Portfolio>) => {
      state.portfolios.push(action.payload);
      state.loading = false;
      state.loaded = true;
    },
    update: (state: PortfolioState, action: PayloadAction<Portfolio>) => {
      const dbEntry = state.portfolios.find((x) => x.id === action.payload.id);
      if (!!dbEntry) {
        const index = state.portfolios.indexOf(dbEntry);
        state.portfolios[index] = action.payload;
      }
      state.loading = false;
      state.loaded = true;
    },
    remove: (state: PortfolioState, action: PayloadAction<Portfolio>) => {
      const entry = state.portfolios.find((x) => x.id === action.payload.id);
      const index = entry ? state.portfolios.indexOf(entry) : -1;
      if (index !== -1) {
        state.portfolios.splice(index, 1);
      }
      state.loading = false;
      state.loaded = true;
    }
  }
});

const { actions, reducer } = portfolioSlice;
export const { reset, loading, success, fail, add, update, remove } = actions;
export default reducer;
