import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ContractDetails,
  ContractMarketData,
  OptionContract,
  OptionRight,
  OrderAction,
  OrderObjective,
  OrderState,
  OrderType,
  PlaceOrderReport,
  Security,
  TradingClass
} from '../../types/entities';
import { StrategyType } from '../../types/enums';

import { initialState, OrderOptionState } from './state';

const orderOptionSlice = createSlice({
  name: 'order-option',
  initialState,
  reducers: {
    reset: () => initialState,
    setActiveStep: (state: OrderOptionState, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setNavDirection: (state: OrderOptionState, action: PayloadAction<'FORWARD' | 'BACKWARD' | undefined>) => {
      state.direction = action.payload;
    },
    fail: (state: OrderOptionState, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
      state.loading = false;
    },
    loading: (state: OrderOptionState, action: PayloadAction<boolean>) => {
      state.error = undefined;
      state.loading = action.payload;
    },
    setSecurity: (state: OrderOptionState, action: PayloadAction<Security | undefined>) => {
      state.security = action.payload;
    },
    setContractDetails: (state: OrderOptionState, action: PayloadAction<ContractDetails | undefined>) => {
      state.contractDetails = action.payload;
    },
    setMarketData: (state: OrderOptionState, action: PayloadAction<ContractMarketData | undefined>) => {
      state.marketData = action.payload;
    },
    setTradingClass: (state: OrderOptionState, action: PayloadAction<TradingClass | undefined>) => {
      state.tradingClass = action.payload;
    },
    setOptionRight: (state: OrderOptionState, action: PayloadAction<OptionRight>) => {
      state.optionRight = action.payload;
    },
    setOrderAction: (state: OrderOptionState, action: PayloadAction<OrderAction>) => {
      state.orderAction = action.payload;
    },
    setOrderType: (state: OrderOptionState, action: PayloadAction<OrderType>) => {
      state.orderType = action.payload;
    },
    setStrategyType: (state: OrderOptionState, action: PayloadAction<StrategyType>) => {
      switch (action.payload) {
        case StrategyType.BUY_CALL:
          state.orderAction = 'BUY';
          state.optionRight = 'CALL';
          state.orderObjective = 'Bullish';
          break;
        case StrategyType.BUY_PUT:
          state.orderAction = 'BUY';
          state.optionRight = 'PUT';
          state.orderObjective = 'Bearish';
          break;
        case StrategyType.SELL_CALL:
          state.orderAction = 'SELL';
          state.optionRight = 'CALL';
          state.orderObjective = 'Bearish';
          break;
        case StrategyType.SELL_PUT:
          state.orderAction = 'SELL';
          state.optionRight = 'PUT';
          state.orderObjective = 'Bullish';
          break;
      }
    },
    setOrderObjective: (state: OrderOptionState, action: PayloadAction<OrderObjective>) => {
      state.orderObjective = action.payload;
    },
    setEntryPrice: (state: OrderOptionState, action: PayloadAction<number>) => {
      state.entryPrice = action.payload;
    },
    setMarketPrice: (state: OrderOptionState, action: PayloadAction<number>) => {
      state.marketPrice = action.payload;
    },
    setOptionContract: (state: OrderOptionState, action: PayloadAction<OptionContract | undefined>) => {
      state.option = action.payload;
    },
    setOrderSize: (state: OrderOptionState, action: PayloadAction<number>) => {
      state.orderSize = action.payload;
    },
    setMargin: (state: OrderOptionState, action: PayloadAction<OrderState>) => {
      state.margin = action.payload;
      state.loading = false;
      state.error = undefined;
    },
    setEntryOrderReport: (state: OrderOptionState, action: PayloadAction<PlaceOrderReport>) => {
      state.entryOrderReport = action.payload;
      state.loading = false;
      state.error = undefined;
    },
    setCloseOrderReport: (state: OrderOptionState, action: PayloadAction<PlaceOrderReport>) => {
      state.closeOrderReport = action.payload;
      state.loading = false;
      state.error = undefined;
    }
  }
});

const { actions, reducer } = orderOptionSlice;
export const {
  reset,
  fail,
  loading,
  setActiveStep,
  setSecurity,
  setContractDetails,
  setMarketData,
  setTradingClass,
  setOrderAction,
  setOptionRight,
  setStrategyType,
  setOrderType,
  setOrderObjective,
  setEntryPrice,
  setMarketPrice,
  setOptionContract,
  setOrderSize,
  setMargin,
  setEntryOrderReport,
  setCloseOrderReport,
  setNavDirection
} = actions;
export default reducer;
