import * as React from 'react';

import { Divider } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

import DropDownButton from '../../ui/DropDownButton';
import GutterBox from '../../ui/GutterBox';

interface Props {
  symbol: string;
}

const GotoOrderButton: React.FunctionComponent<Props> = ({ symbol }: Props) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const closeMenu = () => setAnchorEl(null);

  const handleGotoOptionOrder = () => {
    navigate('/order?tab=option');
    closeMenu();
  };

  const handleGotoUnderlyingOrder = () => {
    navigate('/order?tab=underlying');
    closeMenu();
  };

  const handleGoToOrderSummaryPage = () => {
    navigate(`/order-summary/${symbol}`);
    closeMenu();
  };

  return (
    <GutterBox>
      <DropDownButton label="Go To Order" anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        <MenuItem onClick={handleGotoOptionOrder} disableRipple>
          Option Order
        </MenuItem>

        <MenuItem onClick={handleGotoUnderlyingOrder} disableRipple>
          Underlying Order
        </MenuItem>
        <Divider sx={{ m: 0 }} />
        <MenuItem onClick={handleGoToOrderSummaryPage} disableRipple>
          Order Summary
        </MenuItem>
      </DropDownButton>
    </GutterBox>
  );
};

export default GotoOrderButton;
