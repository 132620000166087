import { FunctionComponent } from 'react';

import { Card, Tooltip, Typography } from '@mui/material';

interface Props {
  title: string;
  value: string | number;
  valueColor?: string;
  footer?: string;
  tooltip?: string;
}

const KeyIndicator: FunctionComponent<Props> = ({ title, value, valueColor = 'inherit', footer, tooltip }: Props) => {
  //  <Typography component="div" color={subTitleColor} sx={{ mt: 1, typography: { sm: 'body1', xs: 'body2' } }}>
  //           {subTitle}
  //         </Typography>
  const titleText = (
    <Typography variant="caption" sx={{ textAlign: 'center' }}>
      {title.toUpperCase()}
    </Typography>
  );

  const titleLabel = tooltip ? <Tooltip title={tooltip}>{titleText}</Tooltip> : titleText;

  return (
    <Card
      raised={true}
      sx={{
        p: 0.5,
        display: 'inline-flex',
        flexDirection: 'column',
        gap: 0.5,
        minWidth: { sm: '100px', xs: '80px' },
        maxWidth: '150px'
      }}
    >
      {titleLabel}
      <Typography variant="h5" sx={{ flexGrow: 1, textAlign: 'center', color: valueColor, typography: { sm: 'h5', xs: 'h6' } }}>
        {value}
      </Typography>
      {footer && (
        <Typography variant="caption" sx={{ textAlign: 'right' }}>
          {footer}
        </Typography>
      )}
    </Card>
  );
};

export default KeyIndicator;
