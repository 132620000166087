import { FavoriteContract } from '../../types/entities';

export interface FavoriteContractState {
  loading: boolean;
  loaded: boolean;
  error?: string;
  favorites: FavoriteContract[];
}

export const initialState: FavoriteContractState = {
  loading: false,
  loaded: false,
  error: undefined,
  favorites: []
};
