import { FundamentalLink } from '../../types/entities';

import { FundamentalLinkState, FundamentalLinkStateBySymbol, initialFundamentalLinkStateBySymbol } from './state';

export const getFundamentalLinkBySymbol = (fundamentalLinkState: FundamentalLinkState, symbol: string): FundamentalLinkStateBySymbol => {
  return fundamentalLinkState[symbol] || { ...initialFundamentalLinkStateBySymbol, symbol };
};

export const getFundamentalLinks = (fundamentalLinkState: FundamentalLinkState, symbol: string): FundamentalLink[] => {
  return getFundamentalLinkBySymbol(fundamentalLinkState, symbol).links;
};

export const isLoaded = (fundamentalLinkState: FundamentalLinkState, symbol: string): boolean => {
  return getFundamentalLinkBySymbol(fundamentalLinkState, symbol).loaded;
};
