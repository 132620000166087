import { DefaultBodyType, MockedRequest, RestHandler, setupWorker, rest } from 'msw';

import { accountInfoHandlers } from './mocks/account-info-handlers';
import { openOrderReportHandlers } from './mocks/open-order-report-handlers';
import { optionChainHandlers } from './mocks/option-chain-handlers';
import { orderWizardHandlers } from './mocks/order-wizard/order-wizard-handlers';
import { positionHandlers } from './mocks/position/position-handlers';
import { repairHandlers } from './mocks/repair-handlers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const checkConnectionHandler = async (_req: any, res: any, ctx: any) => res(ctx.status(200), ctx.json(true));
const checkConnectionRequest = rest.get(`${process.env.REACT_APP_SERVER_URL}/api/broker/check-connection`, checkConnectionHandler);

const miscHandlers: RestHandler<MockedRequest<DefaultBodyType>>[] = [checkConnectionRequest];

// turn off MSW warnings for specific routes
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onUnhandledRequest = (req: any, print: any) => {
  // specify routes to exclude
  const excludedRoutes = ['/favicon.ico', '/manifest.json', '/logo192.png', '*.svg', '*.hot-update.js'];

  // check if the req.url.pathname contains excludedRoutes
  const isExcluded = excludedRoutes.some((route) => req.url.pathname.includes(route));

  if (isExcluded) {
    return;
  }

  if (req.url.pathname.startsWith('/chunks/')) {
    return;
  }

  print.warning();
};

export const initMockServiceWorker = () => {
  const combinedHandlers = [
    ...miscHandlers,
    ...optionChainHandlers,
    ...accountInfoHandlers,
    ...openOrderReportHandlers,
    ...repairHandlers,
    ...orderWizardHandlers,
    ...positionHandlers
  ];
  const worker = setupWorker(...combinedHandlers);
  worker.start({ onUnhandledRequest });
};
