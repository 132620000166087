import { useEffect, useState } from 'react';

import { Contract, MarketDataParams } from '../../types/entities';
import { useAppDispatch, useAppSelector } from '../hooks';

import { getMarketDataStateByConId } from './selectors';
import { getMarketDataAsync } from './service';

export const useMarketDataStore = (contract: Contract, fetchOnInit = false) => {
  const dispatch = useAppDispatch();
  const { useFrozen } = useAppSelector((gs) => gs.userSettingsState);

  const marketDataState = useAppSelector((gs) => gs.marketDataState);
  const marketDataByConId = getMarketDataStateByConId(marketDataState, contract.conId);
  const { marketData, loaded, loading, error } = marketDataByConId;

  const [marketPrice, setMarketPrice] = useState(0);

  useEffect(() => {
    if (!!marketData) {
      const { lastPrice, askPrice, bidPrice } = marketData;
      const price = lastPrice > 0 ? lastPrice : (askPrice + bidPrice) / 2;
      setMarketPrice(price);
    }
  }, [marketData]);

  const fetch = () => {
    const params: MarketDataParams = {
      conId: contract.conId,
      symbol: contract.symbol,
      localSymbol: contract.localSymbol,
      exchange: contract.exchange,
      currency: contract.currency,
      secType: contract.secType,
      right: contract.right,
      strike: contract.strike,
      lastTradeDateOrContractMonth: contract.lastTradeDateOrContractMonth,
      multiplier: contract.multiplier,
      pricesOnly: false,
      useFrozen
    };
    dispatch(getMarketDataAsync(params));
  };

  useEffect(() => {
    if (fetchOnInit && !loaded) {
      fetch();
    }
  }, [fetchOnInit, loaded, contract]);

  return {
    fetch,
    loading,
    loaded,
    error,
    marketData,
    marketPrice
  };
};
