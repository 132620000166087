import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';

import { TradingClass } from '../../../types/entities';

interface Props {
  list: TradingClass[];
  onSelect: (selected: TradingClass) => void;
}

export default function TradingClassTable({ list, onSelect }: Props) {
  const tableHeader = (
    <TableHead>
      <TableRow sx={{ whiteSpace: 'nowrap' }}>
        <TableCell>Month</TableCell>
        <TableCell>Date</TableCell>
        <TableCell>Day</TableCell>
        <TableCell>Expires</TableCell>
        <TableCell>Name</TableCell>
        <TableCell align="right">Multiplier</TableCell>
        <TableCell align="right">Strikes</TableCell>
      </TableRow>
    </TableHead>
  );

  const tableRows = list.map((tc, index) => {
    const dt = new Date(tc.expiresYear, tc.expiresMonth - 1, tc.expiresDay);
    const monthName = dt.toLocaleDateString('en-US', { month: 'short' });
    const monthLabel = `${monthName.toUpperCase()} ${tc.expiresYear - 2000}`;
    const dateNum = dayjs(dt).format('D.').toUpperCase();
    const day = dayjs(dt).format('ddd').toUpperCase();

    const hoursApart = dayjs(dt).diff(new Date(), 'hour');
    const daysApart = hoursApart / 24;
    const daysApartLabel = `${Math.ceil(daysApart)} days`;

    return (
      <TableRow key={index} onClick={() => onSelect(tc)} hover sx={{ cursor: 'pointer' }}>
        <TableCell>{monthLabel}</TableCell>
        <TableCell>{dateNum}</TableCell>
        <TableCell>{day}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{daysApartLabel}</TableCell>
        <TableCell>{tc.name}</TableCell>
        <TableCell align="right">{tc.multiplier}</TableCell>
        <TableCell align="right">{tc.strikes.length}</TableCell>
      </TableRow>
    );
  });

  return (
    <TableContainer sx={{ maxHeight: '300px' }}>
      <Table size="small">
        {tableHeader}
        <TableBody>{tableRows}</TableBody>
      </Table>
    </TableContainer>
  );
}
