import { rest } from 'msw';

import continuousFutues from './continuous-futures.json';
import marketData from './market-data2.json';
import optionChain from './option-chain-take2.json';
import tradingClasses from './trading-class.json';
import whatif from './whatif.json';

/// <summary>
/// <param name="req">an information about a matching request</param>
/// <param name="res">a functional utility to create the mocked response</param>
/// <param name="ctx">a group of functions that help to set a status code, headers, body, etc. of the mocked response.</param>
/// </summary>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getContinuousFutures = async (_req: any, res: any, ctx: any) => {
  return res(ctx.status(200), ctx.json(continuousFutues));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const postTradingClasses = async (_req: any, res: any, ctx: any) => {
  return res(ctx.status(200), ctx.json(tradingClasses));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const postMarketData = async (_req: any, res: any, ctx: any) => {
  return res(ctx.status(200), ctx.json(marketData));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const postOptionChain = async (_req: any, res: any, ctx: any) => {
  return res(ctx.status(200), ctx.json(optionChain));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const postWhatIf = async (_req: any, res: any, ctx: any) => {
  return res(ctx.status(200), ctx.json(whatif));
};

export const orderWizardHandlers = [
  rest.get(`${process.env.REACT_APP_SERVER_URL}/api/broker/continuous-futures`, getContinuousFutures),
  rest.post(`${process.env.REACT_APP_SERVER_URL}/api/broker/trading-class`, postTradingClasses),
  rest.post(`${process.env.REACT_APP_SERVER_URL}/api/broker/market-data2`, postMarketData),
  rest.post(`${process.env.REACT_APP_SERVER_URL}/api/broker/option-chain/take2`, postOptionChain),
  rest.post(`${process.env.REACT_APP_SERVER_URL}/api/broker/poly-order/check-margins`, postWhatIf)
];
