/// <summary>
/// The Halted tick type indicates if a contract has been halted for trading.
/// </summary>
export enum Halted {
  NA = -1,
  NotHalted = 0,
  GeneralHalt = 1,
  VolatilityHalt = 2
}

export enum OrderStatusType {
  PendingSubmit = 0,
  PendingCancel = 1,
  PreSubmitted = 2,
  Submitted = 3,
  ApiCancelled = 4,
  Cancelled = 5,
  Filled = 6,
  Inactive = 7
}

export enum BarSize {
  NA = 0,
  _1_SEC = 1,
  _5_SEC = 2,
  _10_SEC = 3,
  _15_SEC = 4,
  _30_SEC = 5,
  _1_MIN = 6,
  _2_MIN = 7,
  _3_MIN = 8,
  _5_MIN = 9,
  _10_MIN = 10,
  _15_MIN = 11,
  _20_MIN = 12,
  _30_MIN = 13,
  _1_HR = 14,
  _2_HRS = 15,
  _3_HRS = 16,
  _4_HRS = 17,
  _8_HRS = 18,
  _1_DAY = 19,
  _1_WEEK = 20,
  _1_MONTH = 21
}

export enum StrategyType {
  NOT_SET = 0,
  BUY_CALL = 1,
  BUY_PUT = 2,
  SELL_CALL = 3,
  SELL_PUT = 4
}

export enum FundamentalType {
  Period = 0,
  Event = 1
}

export enum FundamentalTrend {
  Volatile = 0,
  Bullish = 1,
  Bearish = 2
}

export enum PeriodMonthPart {
  Beginning = 0,
  End = 1,
  Full = 2
}

export enum SqueezeResult {
  NA = 0,
  Inside = 1,
  Outside = 2
}

export enum FundamentalLinkType {
  SecuritySpecific = 0,
  General = 1
}

export enum MarketTradingHours {
  Closed = 0,
  Open = 1,
  OpenLiquid = 2
}

export enum SecurityCategory {
  Stock = 0,
  Futures = 1,
  MicroFutures = 2
}

export enum SecuritySector {
  Unspecificed = 0,
  Indexes = 1,
  Tech = 2,
  Financial = 3,
  Healthcare = 4,
  Industrial = 5,
  RealEstate = 6,
  Energies = 7,
  Environmental = 8,
  Metals = 9,
  Softs = 10
}

export enum PositionGroupType {
  General = 0,
  Averaging = 1
}
