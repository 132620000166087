import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_URL;

const instance = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*'
  },
  baseURL
});

export const setAuthorizationHeader = (token?: string) => {
  instance.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : undefined;
};

export default instance;
