import { FunctionComponent, useEffect, useState } from 'react';

import { Alert, Box, Button, Card, CircularProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import OrderTypePicker from '../../components/panels/OrderTypePicker';
import { useAppDispatch } from '../../store/hooks';
import { useMarketDataStore } from '../../store/market-data/hooks';
import { addSummaryOrder } from '../../store/order-summary/reducer';
import { ContractDetails, OrderType, Position } from '../../types/entities';
import { createOptionExitOrder } from '../../utils/position-utils';

import ExistingPositionTable from './ExistingPositionPanel';
import OrderDetailsPanel from './OrderDetailsPanel';

interface Props {
  position: Position | undefined;
  underlying: ContractDetails;
  orderType?: OrderType | undefined;
}

const OptionExitOrder: FunctionComponent<Props> = ({ position, underlying, orderType = 'LMT' }: Props) => {
  const contract = underlying.contract;
  const symbol = contract.symbol;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { fetch, loading, loaded, error, marketPrice } = useMarketDataStore(contract, true);

  const orderAction = position && position.orderAction === 'BUY' ? 'SELL' : 'BUY';

  const [orderTypeVal, setOrderTypeVal] = useState<OrderType>(orderType);
  const [exitPrice, setExitPrice] = useState<number>(marketPrice);

  useEffect(() => {
    if (marketPrice) {
      setExitPrice(marketPrice);
    }
  }, [orderAction, orderType, marketPrice]);

  const handleAddOrderBtnClick = () => {
    if (!position) {
      return;
    }
    const order = createOptionExitOrder(position, orderTypeVal, exitPrice);
    dispatch(addSummaryOrder({ symbol, order }));
    navigate(`/order-summary/${contract.symbol}`);
  };

  if (!position) {
    return (
      <Alert color="warning" variant="filled">
        {`Cannot find selected base position for ${orderType === 'LMT' ? 'limit' : 'stop'} order.`}
      </Alert>
    );
  }

  return (
    <>
      <Card sx={{ mb: 2 }}>
        <Typography variant="h6" component="div" m={2}>
          Existing Position
        </Typography>
        <ExistingPositionTable position={position} />
      </Card>

      <Card sx={{ mb: 2 }}>
        {loaded && (
          <OrderTypePicker
            orderAction={orderAction}
            onOrderActionChanged={() => {
              return;
            }}
            orderType={orderTypeVal}
            onOrderTypeChanged={setOrderTypeVal}
            marketPrice={marketPrice}
            triggerPrice={exitPrice}
            onTriggerPriceChanged={setExitPrice}
            exitPosition={position}
          />
        )}
        {error && (
          <Alert sx={{ mb: 2, mx: 2 }} color="error">
            Error loading market data..
          </Alert>
        )}
        <Box display="flex" justifyContent="flex-end" mr={2} mb={2}>
          <Button variant="outlined" onClick={() => fetch()}>
            Reload Market Price {loading && <CircularProgress sx={{ ml: 1 }} color="inherit" size="1rem" />}
          </Button>
        </Box>
      </Card>

      <OrderDetailsPanel
        underlying={underlying}
        orderAction={orderAction}
        entryPrice={position.underEntryPrice ?? marketPrice}
        strikePrice={position.strikePrice}
        limitPrice={orderTypeVal === 'LMT' ? exitPrice : undefined}
        stopPrice={orderTypeVal === 'STP' ? exitPrice : undefined}
        onAddOrder={handleAddOrderBtnClick}
        addOrderDisabled={false}
      />
    </>
  );
};

export default OptionExitOrder;
