import { FunctionComponent } from 'react';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { useAppSelector } from '../../../../store/hooks';
import { OptionContract } from '../../../../types/entities';
import { INDICATOR_GREEN_COLOR, INDICATOR_RED_COLOR } from '../../../../assets/colors';
import { formatNum, setThousenSeparator } from '../../../../utils/currency-utils';

const OptionOrderTable: FunctionComponent = () => {
  const orderOptionState = useAppSelector((gs) => gs.orderOptionState);
  const { orderSize, orderType, orderAction, optionRight, entryOrderReport } = orderOptionState;
  const option = orderOptionState.option as OptionContract;

  const expiresDt = new Date(option.expires as string);
  const expires = `${expiresDt.toLocaleDateString()} ${expiresDt.toLocaleTimeString()}`;

  const expectedPremium = option.premium * orderOptionState.orderSize;
  const expectedPremiumColor = orderAction === 'SELL' ? INDICATOR_GREEN_COLOR : INDICATOR_RED_COLOR;

  const realizedOrderSize = entryOrderReport ? `${entryOrderReport.filled}` : '-';

  return (
    <Box>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Local Symbol</TableCell>
              <TableCell>Underlying</TableCell>
              <TableCell align="right">Order</TableCell>
              <TableCell align="right">Action</TableCell>
              <TableCell align="right">Right</TableCell>
              <TableCell align="right">Multiplier</TableCell>
              <TableCell align="right">Strike</TableCell>
              <TableCell align="right">Premium</TableCell>
              <TableCell align="right">Expires</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{option.localSymbol}</TableCell>
              <TableCell>{option.symbol}</TableCell>
              <TableCell align="right">{orderType}</TableCell>
              <TableCell align="right">{orderAction}</TableCell>
              <TableCell align="right">{optionRight}</TableCell>
              <TableCell align="right">{option.multiplier}</TableCell>
              <TableCell align="right">${formatNum(option.strikePrice)}</TableCell>
              <TableCell align="right" sx={{ color: expectedPremiumColor }}>
                ${setThousenSeparator(expectedPremium.toFixed(0))}
              </TableCell>
              <TableCell align="right">{expires}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Table sx={{ mt: 3 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">Expected</TableCell>
            <TableCell align="right">Realized</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ whiteSpace: 'nowrap' }}>
            <TableCell>Order Size</TableCell>
            <TableCell align="right">{orderSize}</TableCell>
            <TableCell align="right">{realizedOrderSize}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default OptionOrderTable;
