import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FundamentalLinkSuccessAction, FundamentalLinkFailedAction, FundamentalLinkLoadingAction, FundamentalLinkAction } from './actions';
import { FundamentalLinkState, initialFundamentalLinkState, initialFundamentalLinkStateBySymbol } from './state';

const fundamentalLinkSlice = createSlice({
  name: 'fundamental-link',
  initialState: initialFundamentalLinkState,
  reducers: {
    reset: () => initialFundamentalLinkState,
    loading: (state: FundamentalLinkState, action: PayloadAction<FundamentalLinkLoadingAction>) => {
      state[action.payload.symbol] = {
        error: undefined,
        loading: action.payload.loading,
        loaded: !action.payload.loading,
        links: state[action.payload.symbol]?.links ?? []
      };
    },
    success: (state: FundamentalLinkState, action: PayloadAction<FundamentalLinkSuccessAction>) => {
      state[action.payload.symbol] = {
        error: undefined,
        loading: false,
        loaded: true,
        links: action.payload.links
      };
    },
    fail: (state: FundamentalLinkState, action: PayloadAction<FundamentalLinkFailedAction>) => {
      state[action.payload.symbol] = {
        error: action.payload.error,
        loading: false,
        loaded: true,
        links: state[action.payload.symbol]?.links ?? []
      };
    },
    add: (state: FundamentalLinkState, action: PayloadAction<FundamentalLinkAction>) => {
      const current = state[action.payload.symbol] ?? { ...initialFundamentalLinkStateBySymbol, symbol: action.payload.symbol };
      current.links.push(action.payload.link);
      current.loaded = true;
      current.loading = false;
      current.error = undefined;
    },
    update: (state: FundamentalLinkState, action: PayloadAction<FundamentalLinkAction>) => {
      const current = state[action.payload.symbol] ?? { ...initialFundamentalLinkStateBySymbol, symbol: action.payload.symbol };
      const link = action.payload.link;
      const item = current.links.find((x) => x.id === link.id);
      const index = item ? current.links.indexOf(item) : -1;
      current.links[index] = { ...link };
      current.loaded = true;
      current.loading = false;
      current.error = undefined;
    },
    remove: (state: FundamentalLinkState, action: PayloadAction<FundamentalLinkAction>) => {
      const current = state[action.payload.symbol] ?? { ...initialFundamentalLinkStateBySymbol, symbol: action.payload.symbol };
      const link = action.payload.link;
      const item = current.links.find((x) => x.id === link.id);
      const index = item ? current.links.indexOf(item) : -1;
      current.links.splice(index, 1);
      current.loaded = true;
      current.loading = false;
      current.error = undefined;
    }
  }
});

const { actions, reducer } = fundamentalLinkSlice;
export const { reset, loading, success, fail, add, update, remove } = actions;
export default reducer;
