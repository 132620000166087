import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TradingClassFailedAction, TradingClassLoadingAction, TradingClassSuccessAction } from './actions';
import { TradingClassState, initialTradingClassState } from './state';

const tradingClassSlice = createSlice({
  name: 'trading-class',
  initialState: initialTradingClassState,
  reducers: {
    reset: () => initialTradingClassState,
    loading: (state: TradingClassState, action: PayloadAction<TradingClassLoadingAction>) => {
      state[action.payload.conId] = {
        error: undefined,
        loading: action.payload.loading,
        loaded: !action.payload.loading,
        tradingClasses: state[action.payload.conId]?.tradingClasses ?? []
      };
    },
    success: (state: TradingClassState, action: PayloadAction<TradingClassSuccessAction>) => {
      state[action.payload.conId] = {
        error: undefined,
        loading: false,
        loaded: true,
        tradingClasses: action.payload.tradingClasses
      };
    },
    fail: (state: TradingClassState, action: PayloadAction<TradingClassFailedAction>) => {
      state[action.payload.conId] = {
        error: action.payload.error,
        loading: false,
        loaded: true,
        tradingClasses: []
      };
    }
  }
});

const { actions, reducer } = tradingClassSlice;
export const { reset, loading, success, fail } = actions;
export default reducer;
