import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AccountInfo } from '../../types/entities';

import { AccountInfoState, initialAccountInfoState } from './state';

const accountInfoSlice = createSlice({
  name: 'account-info',
  initialState: initialAccountInfoState,
  reducers: {
    reset: () => initialAccountInfoState,
    loading: (state: AccountInfoState, action: PayloadAction<boolean>) => {
      state.error = undefined;
      state.loading = action.payload;
      state.loaded = !action.payload;
    },
    success: (state: AccountInfoState, action: PayloadAction<AccountInfo>) => {
      state.error = undefined;
      state.loading = false;
      state.loaded = true;
      state.accountInfo = action.payload;
    },
    fail: (state: AccountInfoState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.loaded = true;
      state.accountInfo = undefined;
    }
  }
});

const { actions, reducer } = accountInfoSlice;
export const { reset, loading, success, fail } = actions;
export default reducer;
