import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  HistoricalDataFailedAction,
  HistoricalDataInitializeAction,
  HistoricalDataLoadingAction,
  HistoricalDataSuccessAction,
  SetDurationInDaysAction,
  SetSelectedBarSizeAction
} from './actions';
import { HistoricalDataState, initialHistoricalDataState, initialHistoricalDataStateByKey } from './state';
import { getKey } from './utils';

const historicalDataSlice = createSlice({
  name: 'historical-data',
  initialState: initialHistoricalDataState,
  reducers: {
    reset: () => initialHistoricalDataState,
    initialize: (state: HistoricalDataState, action: PayloadAction<HistoricalDataInitializeAction>) => {
      const key = getKey({ id: action.payload.id, conId: action.payload.conId });
      const currState = state[key] || initialHistoricalDataStateByKey;
      state[key] = {
        ...currState,
        initialized: true
      };
      if (action.payload.barSize) {
        state[key].barSize = action.payload.barSize;
      }
      if (action.payload.duration) {
        state[key].barDurationInDays = action.payload.duration;
      }
    },
    loading: (state: HistoricalDataState, action: PayloadAction<HistoricalDataLoadingAction>) => {
      const key = getKey({ id: action.payload.id, conId: action.payload.conId });
      const currState = state[key] || initialHistoricalDataStateByKey;
      state[key] = {
        ...currState,
        error: undefined,
        loading: action.payload.loading,
        loaded: !action.payload.loading
      };
    },
    success: (state: HistoricalDataState, action: PayloadAction<HistoricalDataSuccessAction>) => {
      const key = getKey({ id: action.payload.id, conId: action.payload.conId });
      const currState = state[key] || initialHistoricalDataStateByKey;
      state[key] = {
        ...currState,
        error: undefined,
        loading: false,
        loaded: true,
        bars: action.payload.bars
      };
    },
    fail: (state: HistoricalDataState, action: PayloadAction<HistoricalDataFailedAction>) => {
      const key = getKey({ id: action.payload.id, conId: action.payload.conId });
      const currState = state[key] || initialHistoricalDataStateByKey;
      state[key] = {
        ...currState,
        error: action.payload.error,
        loading: false,
        loaded: true,
        bars: []
      };
    },
    updateBarSize: (state: HistoricalDataState, action: PayloadAction<SetSelectedBarSizeAction>) => {
      const key = getKey({ id: action.payload.id, conId: action.payload.conId });
      const currState = state[key] || initialHistoricalDataStateByKey;
      state[key] = {
        ...currState,
        barSize: action.payload.barSize
      };
    },
    updateDuration: (state: HistoricalDataState, action: PayloadAction<SetDurationInDaysAction>) => {
      const key = getKey({ id: action.payload.id, conId: action.payload.conId });
      const currState = state[key] || initialHistoricalDataStateByKey;
      state[key] = {
        ...currState,
        barDurationInDays: action.payload.duration
      };
    }
  }
});

const { actions, reducer } = historicalDataSlice;
export const { reset, loading, success, fail, initialize, updateBarSize, updateDuration } = actions;
export default reducer;
