import { FunctionComponent } from 'react';

import { Box, Button, ButtonGroup, Grid, Typography } from '@mui/material';

import { OptionRight, OrderAction } from '../../../types/entities';

interface Props {
  orderAction: OrderAction;
  onOrderActionChanged: (oa: OrderAction) => void;
  optionRight: OptionRight;
  onOptionRightChanged: (or: OptionRight) => void;
}

const OptionTypePanel: FunctionComponent<Props> = ({ orderAction, onOrderActionChanged, optionRight, onOptionRightChanged }) => {
  return (
    <Box sx={{ mx: 2 }}>
      <Box>
        <Typography variant="h6" sx={{ pt: 2 }} component="div">
          Option Type
        </Typography>
      </Box>
      <Box sx={{ py: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <ButtonGroup variant="outlined" disableElevation fullWidth>
              <Button onClick={() => onOrderActionChanged('BUY')} variant={orderAction === 'BUY' ? 'contained' : 'outlined'}>
                Buy
              </Button>
              <Button onClick={() => onOrderActionChanged('SELL')} variant={orderAction === 'SELL' ? 'contained' : 'outlined'}>
                Sell
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12} md={6}>
            <ButtonGroup variant="outlined" fullWidth>
              <Button onClick={() => onOptionRightChanged('CALL')} variant={optionRight === 'CALL' ? 'contained' : 'outlined'}>
                Call
              </Button>
              <Button onClick={() => onOptionRightChanged('PUT')} variant={optionRight === 'PUT' ? 'contained' : 'outlined'}>
                Put
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default OptionTypePanel;
