import { FunctionComponent, useState } from 'react';

import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import { IconButton, Tooltip } from '@mui/material';

import { Position } from '../../types/entities';
import EditPositionDialog from '../dialogs/EditPositionDialog';

interface Props {
  position: Position;
}

const EditPositionIconButton: FunctionComponent<Props> = ({ position }: Props) => {
  const [showEditPositionDialog, setShowEditPositionDialog] = useState(false);
  const handleEditBtnClick = () => setShowEditPositionDialog(true);
  const closeEditPositionDialog = () => setShowEditPositionDialog(false);

  return (
    <>
      <Tooltip title="Settings" placement="top">
        <IconButton size="small" onClick={handleEditBtnClick} sx={{ color: (theme) => theme.palette.grey[200] }}>
          <EditAttributesIcon />
        </IconButton>
      </Tooltip>
      <EditPositionDialog isOpen={showEditPositionDialog} onClose={closeEditPositionDialog} position={position} />
    </>
  );
};

export default EditPositionIconButton;
