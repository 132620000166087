import { useState } from 'react';

import { useAppSelector } from '../../store/hooks';
import { ContractMarketData, Position } from '../../types/entities';
import { Logger } from '../../utils/Logger';

import { useMarketDataQuery } from './useMarketDataQuery';

export const useRefreshMarketData = () => {
  const getMarketDataAsync = useMarketDataQuery();

  const { useFrozen } = useAppSelector((gs) => gs.userSettingsState);
  const [loading, setLoading] = useState(false);

  const getMarketPrice = (marketData: ContractMarketData) => {
    if (marketData.lastPrice) {
      return marketData.lastPrice;
    }
    const sum = marketData.askPrice + marketData.bidPrice;
    if (sum !== 0) {
      return sum / 2;
    }
    return undefined;
  };

  const refreshMarketData = async (p: Position, callback: (pos: Position | undefined) => void) => {
    const pos = { ...p };
    setLoading(true);
    try {
      const marketData = await getMarketDataAsync(pos, useFrozen);
      const marketPrice = getMarketPrice(marketData);
      if (marketPrice) {
        if (pos.secType === 'FOP' || pos.secType === 'OPT') {
          pos.marketPrice = marketPrice;
          if (pos.multiplier) {
            pos.premium = marketPrice * Number(pos.multiplier);
          }
        } else {
          pos.marketPrice = marketPrice;
          if (pos.orderType === 'MKT') {
            pos.entryPrice = marketPrice;
          }
        }

        if (callback) {
          callback(pos);
        }
      }
    } catch (error) {
      Logger.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    refreshMarketData,
    loading
  };
};
