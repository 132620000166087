import React, { FunctionComponent } from 'react';

import { Backdrop, Box } from '@mui/material';

import './styles.css';

interface Props {
  loading: boolean;
  inline?: boolean;
}

const Spinner: FunctionComponent<Props> = ({ loading, inline }: Props) => {
  if (inline) {
    return (
      <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </Box>
    );
  }
  return (
    <Backdrop open={loading} sx={{ zIndex: 100 }}>
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </Backdrop>
  );
};

export default Spinner;
