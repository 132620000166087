import { FunctionComponent, useState } from 'react';

import EditNoteIcon from '@mui/icons-material/EditNote';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';

import { GRAY_COLOR, INDICATOR_GREEN_COLOR, INDICATOR_RED_COLOR } from '../../../../../assets/colors';
import PositionGroupDialog from '../../../../../components/dialogs/PositionGroupDialog';
import GroupScheduleButton from '../../../../../components/ui/GroupScheduleButton';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { updatePositionGroupAsync } from '../../../../../store/position-group/service';
import { PositionGroup } from '../../../../../types/entities';
import { setThousenSeparator } from '../../../../../utils/currency-utils';

interface Props {
  group: PositionGroup;
}

const PositionGroupHeader: FunctionComponent<Props> = ({ group }) => {
  const { positions } = useAppSelector((gs) => gs.positionState);

  const positionsInGroup = positions.filter((x) => x.groupId === group.id && !x.completed);

  const getActivePositionsInGroup = () => {
    return positionsInGroup.filter((x) => x.size != 0).length;
  };

  const getMarketValueGroupAmount = () => {
    const filteredList = positionsInGroup.filter((x) => x.secType !== 'FUT' && x.secType !== 'STK' && x.size !== 0);
    if (filteredList.length === 0) {
      return 0;
    }
    return filteredList
      .map((x) => x.marketValue)
      .reduce((total: number, marketValue: number) => {
        return total + marketValue;
      });
  };

  const getUnrealizedGroupAmount = () => {
    return positionsInGroup
      .map((x) => x.unrealizedPnl)
      .reduce((total: number, unrealizedPnl: number) => {
        return total + unrealizedPnl;
      });
  };

  const getRealizedGroupAmount = () => {
    return positionsInGroup
      .map((x) => x.realizedPnl)
      .reduce((total: number, realizedPnl: number) => {
        return total + realizedPnl;
      });
  };

  const marketValueGroupAmount = positionsInGroup && positionsInGroup.length > 0 ? getMarketValueGroupAmount() : 0;
  const marketValueGroupAmountFormatted = `$${setThousenSeparator(marketValueGroupAmount.toFixed(0))}`;
  const unrealizedGroupAmount = positionsInGroup && positionsInGroup.length > 0 ? getUnrealizedGroupAmount() : 0;
  const unrealizedGroupAmountFormatted = `$${setThousenSeparator(unrealizedGroupAmount.toFixed(0))}`;
  const realizedGroupAmount = positionsInGroup && positionsInGroup.length > 0 ? getRealizedGroupAmount() : 0;
  const realizedGroupAmountFormatted = `$${setThousenSeparator(realizedGroupAmount.toFixed(0))}`;

  const dispatch = useAppDispatch();

  const handleSortOrderBtnClick = (sortOrder: number) => {
    const gr: PositionGroup = { ...group, sortOrder: group.sortOrder + sortOrder };
    dispatch(updatePositionGroupAsync(gr));
  };

  const [showPositionGroupDialog, setShowPositionGroupDialog] = useState(false);
  const handleEditBtnClick = () => setShowPositionGroupDialog(true);
  const closePositionGroupDialog = () => setShowPositionGroupDialog(false);

  return (
    <>
      <Box sx={{ mx: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'stretch', gap: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="caption">{getActivePositionsInGroup()}</Typography>
          <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
          <Typography variant="caption">
            UNRLZ{' '}
            <span
              style={{
                color: unrealizedGroupAmount > 0 ? INDICATOR_GREEN_COLOR : unrealizedGroupAmount < 0 ? INDICATOR_RED_COLOR : 'inherit'
              }}
            >
              {unrealizedGroupAmountFormatted}
            </span>
          </Typography>
          <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
          <Typography variant="caption">
            RLZ{' '}
            <span
              style={{ color: realizedGroupAmount > 0 ? INDICATOR_GREEN_COLOR : realizedGroupAmount < 0 ? INDICATOR_RED_COLOR : 'inherit' }}
            >
              {realizedGroupAmountFormatted}
            </span>
          </Typography>
          <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
          <Typography variant="caption">
            <span style={{ color: GRAY_COLOR }}>MKTV {marketValueGroupAmountFormatted}</span>
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexShrink: 0 }}>
          <GroupScheduleButton group={group} groupPositions={positionsInGroup} size="small" />
          <Divider orientation="vertical" flexItem sx={{ mx: 0.5 }} />
          <Tooltip title="Move Up" placement="top">
            <IconButton size="small" onClick={() => handleSortOrderBtnClick(1)} sx={{ color: (theme) => theme.palette.grey[500] }}>
              <KeyboardArrowUpIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Move Down" placement="top">
            <IconButton size="small" onClick={() => handleSortOrderBtnClick(-1)} sx={{ color: (theme) => theme.palette.grey[500] }}>
              <KeyboardArrowDownIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit" placement="top">
            <IconButton size="small" onClick={handleEditBtnClick} sx={{ color: (theme) => theme.palette.grey[500] }}>
              <EditNoteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <PositionGroupDialog isOpen={showPositionGroupDialog} onClose={closePositionGroupDialog} group={group} />
    </>
  );
};

export default PositionGroupHeader;
