import { toast } from 'react-toastify';

import { AppDispatch } from '..';
import { fetchOptionChain } from '../../api';
import { OptionChainParams } from '../../types/entities';
import { Logger } from '../../utils/Logger';

import { loading, success, fail } from './reducer';

export const fetchOptionChainAsync = (request: OptionChainParams) => {
  return (dispatch: AppDispatch) => {
    const conId = request.conId;
    const lastTradeDate = request.tradingClassLastTradeDate;
    const { right } = request;
    dispatch(loading({ conId, right, lastTradeDate, loading: true }));
    fetchOptionChain(request)
      .then((response) => {
        // eslint-disable-next-line no-console
        Logger.log(response.data);
        const options = response.data;
        dispatch(success({ conId, right, lastTradeDate, options }));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail({ conId, right, lastTradeDate, error: msg }));
        toast.error(msg);
      });
  };
};
