import { FunctionComponent, useState } from 'react';

import ShowChartIcon from '@mui/icons-material/ShowChart';
import { IconButton, Tooltip } from '@mui/material';

import { Position } from '../../types/entities';
import PositionGraphDialog from '../dialogs/PositionGraphDialog';

interface Props {
  position: Position;
}

const ShowGraphIconButton: FunctionComponent<Props> = ({ position }: Props) => {
  const [showGraphDialog, setShowGraphDialog] = useState(false);
  const handleGraphBtnClick = () => setShowGraphDialog(true);
  const closeGraphDialog = () => setShowGraphDialog(false);

  return (
    <>
      <Tooltip title="Barchart" placement="top">
        <IconButton size="small" onClick={handleGraphBtnClick} sx={{ color: (theme) => theme.palette.grey[200] }}>
          <ShowChartIcon />
        </IconButton>
      </Tooltip>
      {showGraphDialog && <PositionGraphDialog isOpen={showGraphDialog} onClose={closeGraphDialog} position={position} />}
    </>
  );
};

export default ShowGraphIconButton;
