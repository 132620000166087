import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PositionGroup } from '../../types/entities';

import { PositionGroupState, initialState } from './state';

const positionGroupSlice = createSlice({
  name: 'position-group',
  initialState,
  reducers: {
    reset: () => initialState,
    loading: (state: PositionGroupState, action: PayloadAction<boolean>) => {
      state.error = undefined;
      state.loading = action.payload;
      state.loaded = !action.payload;
    },
    success: (state: PositionGroupState, action: PayloadAction<PositionGroup[]>) => {
      state.error = undefined;
      state.loading = false;
      state.loaded = true;
      state.groups = action.payload;
    },
    fail: (state: PositionGroupState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.loaded = true;
      state.groups = [];
    },
    add: (state: PositionGroupState, action: PayloadAction<PositionGroup>) => {
      state.groups.push(action.payload);
      state.loading = false;
      state.loaded = true;
    },
    update: (state: PositionGroupState, action: PayloadAction<PositionGroup>) => {
      const dbEntry = state.groups.find((x) => x.id === action.payload.id);
      if (!!dbEntry) {
        const index = state.groups.indexOf(dbEntry);
        state.groups[index] = action.payload;
      }
      state.loading = false;
      state.loaded = true;
    },
    remove: (state: PositionGroupState, action: PayloadAction<PositionGroup>) => {
      const dbEntry = state.groups.find((x) => x.id === action.payload.id);
      if (!!dbEntry) {
        const index = state.groups.indexOf(dbEntry);
        state.groups.splice(index, 1);
      }
      state.loading = false;
      state.loaded = true;
    }
  }
});

const { actions, reducer } = positionGroupSlice;
export const { reset, loading, success, fail, add, update, remove } = actions;
export default reducer;
