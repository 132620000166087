import { FunctionComponent, useState } from 'react';

// import FavoriteIcon from '@mui/icons-material/Favorite';
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AddCommentIcon from '@mui/icons-material/AddComment';
import SendIcon from '@mui/icons-material/Send';
import { Box, IconButton, Paper, TextField, Tooltip, Typography } from '@mui/material';

import { useAppDispatch } from '../../../store/hooks';
import { createQuickNoteAsync } from '../../../store/quick-note/service';
import { ContractDetails, QuickNote, Security } from '../../../types/entities';
import { SecuritySector } from '../../../types/enums';

import QuickNotePanel from './QuickNotePanel';

interface Props {
  security: Security;
  details: ContractDetails;
}

const WatchBoardHeader: FunctionComponent<Props> = ({ security, details }) => {
  const { contract } = details;
  const title = `${security.name} - ${contract.localSymbol}`;
  const subTitle = `${SecuritySector[security.sector]}`;

  const [quickNote, setQuickNote] = useState('');
  const [showQuickNote, setShowQuickNote] = useState(false);
  const toggleShowQuickNote = () => {
    setShowQuickNote(!showQuickNote);
  };

  const dispatch = useAppDispatch();

  function handleSendQuickNoteBtnClick(): void {
    const payload: QuickNote = {
      id: '',
      created: new Date(),
      symbol: security.symbol,
      note: quickNote
    };
    dispatch(createQuickNoteAsync(payload));
    setShowQuickNote(false);
    setQuickNote('');
  }

  return (
    <Box>
      <Paper>
        <Box sx={{ pt: 2, px: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="caption">{subTitle}</Typography>
          </Box>
          <Tooltip title="Add Quick Note" placement="left-start">
            <IconButton sx={{ mt: 0.5 }} size="small" color="primary" onClick={toggleShowQuickNote}>
              <AddCommentIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
        </Box>
        <QuickNotePanel symbol={security.symbol} />
        {showQuickNote && (
          <Box sx={{ pt: 2, px: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <TextField
              name="Quick Note"
              size="small"
              label="Quick Note"
              variant="outlined"
              type="text"
              value={quickNote}
              onChange={(e) => setQuickNote(e.target.value)}
              fullWidth
              sx={{ pr: 1 }}
            />
            <IconButton size="small" color="primary" onClick={handleSendQuickNoteBtnClick}>
              <SendIcon />
            </IconButton>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default WatchBoardHeader;
