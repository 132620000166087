import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TrendLineSuccessAction, TrendLineFailedAction, TrendLineLoadingAction, TrendLineAction } from './actions';
import { TrendLineState, initialTrendLineState, initialTrendLineStateByConId } from './state';

const TrendLineSlice = createSlice({
  name: 'trend-line',
  initialState: initialTrendLineState,
  reducers: {
    reset: () => initialTrendLineState,
    loading: (state: TrendLineState, action: PayloadAction<TrendLineLoadingAction>) => {
      state[action.payload.conId] = {
        error: undefined,
        loading: action.payload.loading,
        loaded: !action.payload.loading,
        trendLines: state[action.payload.conId]?.trendLines ?? []
      };
    },
    success: (state: TrendLineState, action: PayloadAction<TrendLineSuccessAction>) => {
      state[action.payload.conId] = {
        error: undefined,
        loading: false,
        loaded: true,
        trendLines: action.payload.trendLines
      };
    },
    fail: (state: TrendLineState, action: PayloadAction<TrendLineFailedAction>) => {
      state[action.payload.conId] = {
        error: action.payload.error,
        loading: false,
        loaded: true,
        trendLines: state[action.payload.conId]?.trendLines ?? []
      };
    },
    add: (state: TrendLineState, action: PayloadAction<TrendLineAction>) => {
      const current = state[action.payload.conId] ?? { ...initialTrendLineStateByConId, conId: action.payload.conId };
      current.trendLines.push(action.payload.trendLine);
      current.loaded = true;
      current.loading = false;
      current.error = undefined;
    },
    update: (state: TrendLineState, action: PayloadAction<TrendLineAction>) => {
      const current = state[action.payload.conId] ?? { ...initialTrendLineStateByConId, conId: action.payload.conId };
      const tradeLine = action.payload.trendLine;
      const item = current.trendLines.find((x) => x.id === tradeLine.id);
      const index = item ? current.trendLines.indexOf(item) : -1;
      current.trendLines[index] = { ...tradeLine };
      current.loaded = true;
      current.loading = false;
      current.error = undefined;
    },
    remove: (state: TrendLineState, action: PayloadAction<TrendLineAction>) => {
      const current = state[action.payload.conId] ?? { ...initialTrendLineStateByConId, conId: action.payload.conId };
      const tradeLine = action.payload.trendLine;
      const item = current.trendLines.find((x) => x.id === tradeLine.id);
      const index = item ? current.trendLines.indexOf(item) : -1;
      current.trendLines.splice(index, 1);
      current.loaded = true;
      current.loading = false;
      current.error = undefined;
    }
  }
});

const { actions, reducer } = TrendLineSlice;
export const { reset, loading, success, fail, add, update, remove } = actions;
export default reducer;
