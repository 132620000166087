import { useMemo } from 'react';

import { useAppSelector } from '../../store/hooks';
import { FavoriteContract, Security } from '../../types/entities';

const useFrontRunners = () => {
  const { securities } = useAppSelector((gs) => gs.securityState);
  const { favorites } = useAppSelector((gs) => gs.favoriteContractState);

  const getSecurity = (fav: FavoriteContract) => {
    const symbol = fav.details.contract.symbol;
    return securities.find((x) => x.symbol === symbol);
  };

  const getFavoritesWithSecurity = () => {
    const list: { fav: FavoriteContract; sec: Security | undefined }[] = [];
    for (let i = 0; i < favorites.length; i++) {
      list.push({
        fav: favorites[i],
        sec: getSecurity(favorites[i])
      });
    }
    return list;
  };

  const sortByName = (a: FavoriteContract, b: FavoriteContract) => (a.securityName > b.securityName ? 1 : -1);

  const filteredFavorites = useMemo(() => {
    const list = getFavoritesWithSecurity();
    const result = list.filter((x) => x.sec?.secType === 'FUT');
    return result.map((x) => x.fav).sort(sortByName);
  }, [sortByName]);

  return filteredFavorites;
};

export default useFrontRunners;
