export interface FoundationDataState {
  loading: boolean;
  loaded: boolean;
  error?: string;
}

export const initialState: FoundationDataState = {
  loading: false,
  loaded: false,
  error: undefined
};
