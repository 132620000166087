import { FunctionComponent } from 'react';

import { Box } from '@mui/material';

import { useAppSelector } from '../../../../store/hooks';
import { ContractDetails } from '../../../../types/entities';

import OrderTypePanel from './OrderTypePanel';
import WatchBoard from '../../../../components/panels/Watchboard';

const OrderTypeStep: FunctionComponent = () => {
  const orderUnderlyingState = useAppSelector((gs) => gs.orderUnderlyingState);
  const contractDetails = orderUnderlyingState.contractDetails as ContractDetails;
  const { entryPrice, orderAction } = orderUnderlyingState;

  return (
    <Box>
      <WatchBoard details={contractDetails} entryPrice={entryPrice} action={orderAction} />
      <OrderTypePanel />
    </Box>
  );
};

export default OrderTypeStep;
