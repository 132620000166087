import { Security } from '../../types/entities';

export interface SecurityState {
  loading: boolean;
  loaded: boolean;
  error?: string | undefined;
  securities: Security[];
}

export const initialState: SecurityState = {
  loading: false,
  loaded: false,
  securities: []
};
