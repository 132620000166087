import { FunctionComponent, useEffect, useMemo } from 'react';

import { Tab, Tabs, tabsClasses } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setSelectedPortfolio } from '../../store/user-settings/reducer';
import { Portfolio } from '../../types/entities';

const PortfolioTabs: FunctionComponent = () => {
  const { portfolios } = useAppSelector((gs) => gs.portfolioState);
  const { selectedPortfolio } = useAppSelector((gs) => gs.userSettingsState);
  const portfolio = portfolios.find((x) => x.id === selectedPortfolio?.id);

  const sortByName = (a: Portfolio, b: Portfolio) => (a.name > b.name ? 1 : -1);
  const sortedPorfolios = useMemo(() => {
    return portfolios.slice().sort(sortByName);
  }, [portfolios]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectedPortfolio) {
      if (sortedPorfolios.length > 0) {
        dispatch(setSelectedPortfolio(sortedPorfolios[0]));
      }
    }
  }, [sortedPorfolios, selectedPortfolio]);

  const handleTabChange = (_event: React.SyntheticEvent, portfolioId: string) => {
    const p = portfolios.find((x) => x.id === portfolioId);
    dispatch(setSelectedPortfolio(p));
  };

  const showTabs = portfolio && portfolios.length > 0;

  const portfolioItems = useMemo(() => {
    return sortedPorfolios.map((p) => <Tab key={p.id} label={p.name} value={p.id} />);
  }, [sortedPorfolios]);

  return showTabs ? (
    <Tabs
      value={portfolio.id || ''}
      onChange={handleTabChange}
      variant="scrollable"
      scrollButtons="auto"
      sx={{
        [`& .${tabsClasses.scrollButtons}`]: {
          '&.Mui-disabled': { opacity: 0.3 }
        }
      }}
    >
      {portfolioItems}
    </Tabs>
  ) : null;
};

export default PortfolioTabs;
