import { ContractDetails } from '../types/entities';

export const mapToDto = (details: ContractDetails) => ({
  ...details,
  underSymbol: details.underSymbol || '',
  underSecType: details.underSecType || '',
  contractMonth: details.contractMonth || '',
  lastTradeTime: details.lastTradeTime || '',
  realExpirationDate: details.realExpirationDate || '',
  timeZoneId: details.timeZoneId || '',
  liquidHours: details.liquidHours || '',
  tradingHours: details.tradingHours || '',
  contract: {
    ...details.contract,
    multiplier: details.contract.multiplier || '',
    lastTradeDateOrContractMonth: details.contract.lastTradeDateOrContractMonth || ''
  }
});
