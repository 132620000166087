import { QuickNote } from '../../types/entities';

import { QuickNoteState, QuickNoteStateBySymbol, initialQuickNoteStateBySymbol } from './state';

export const getQuickNoteBySymbol = (QuickNoteState: QuickNoteState, symbol: string): QuickNoteStateBySymbol => {
  return QuickNoteState[symbol] || { ...initialQuickNoteStateBySymbol, symbol };
};

export const getQuickNotes = (QuickNoteState: QuickNoteState, symbol: string): QuickNote[] => {
  return getQuickNoteBySymbol(QuickNoteState, symbol).notes;
};

export const isLoaded = (QuickNoteState: QuickNoteState, symbol: string): boolean => {
  return getQuickNoteBySymbol(QuickNoteState, symbol).loaded;
};
