import { FunctionComponent, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Badge, BadgeProps, Box, Chip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { FundamentalNote, Security } from '../../../../types/entities';
import { FundamentalTrend, FundamentalType, PeriodMonthPart } from '../../../../types/enums';
import { getMonthName } from '../../../../utils/month-utils';
import FundamentalNoteDialog from '../../../BackOffice/FundamentalsTab/FundamentalNoteDialog';

interface Props {
  securities: Security[];
  notes: FundamentalNote[];
}

const SeasonalAccordion: FunctionComponent<Props> = ({ securities, notes }: Props) => {
  const now = new Date();
  const oneBasedMonth = now.getMonth() + 1;
  const day = now.getDate();
  const MONTH_BEGINNING_TRESHOLD = 10;
  const MONTH_END_TRESHOLD = 20;

  const [selectedNote, setSelectedNote] = useState<FundamentalNote | undefined>();
  const [selectedSecurity, setSelectedSecurity] = useState<Security | undefined>();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const closeDialog = () => setShowEditDialog(false);
  const openDialog = (note?: FundamentalNote) => {
    setSelectedNote(note);
    setSelectedSecurity(securities.find((x) => x.symbol === note?.symbol));
    setShowEditDialog(true);
  };

  const getCurrentPeriod = () => {
    const monthName = getMonthName(oneBasedMonth).toUpperCase();
    let partName = '';
    if (day < MONTH_BEGINNING_TRESHOLD) {
      partName = 'BEGINNING OF ';
    } else if (day > MONTH_END_TRESHOLD) {
      partName = 'END OF ';
    }
    return `${partName}${monthName}`;
  };

  type ChipColor = 'info' | 'success' | 'warning' | 'error';
  const getChipColor = (trend: FundamentalTrend): ChipColor => {
    switch (trend) {
      case FundamentalTrend.Volatile:
        return 'warning';
      case FundamentalTrend.Bullish:
        return 'success';
      case FundamentalTrend.Bearish:
        return 'error';
      default:
        return 'info';
    }
  };

  const currentPeriodNotes = notes
    .filter((x) => x.periodMonth === oneBasedMonth && x.type === FundamentalType.Period)
    .filter((x) => (day < MONTH_BEGINNING_TRESHOLD ? x.periodMonthPart !== PeriodMonthPart.End : x))
    .filter((x) => (day > MONTH_END_TRESHOLD ? x.periodMonthPart !== PeriodMonthPart.Beginning : x));

  const periodChips = currentPeriodNotes.map((x) => {
    const securityName = securities.find((y) => y.symbol === x.symbol)?.name || '';
    const monthPart = PeriodMonthPart[x.periodMonthPart];
    const label = `${securityName} - ${monthPart}`;
    return <Chip key={x.id} label={label} color={getChipColor(x.trend)} onClick={() => openDialog(x)} />;
  });

  const currentEventNotes = notes
    .filter((x) => x.periodMonth === oneBasedMonth && x.type === FundamentalType.Event && x.eventDate >= day)
    .sort((a, b) => (a.eventDate < b.eventDate ? -1 : 1));

  const eventChips = currentEventNotes.map((x) => {
    const securityName = securities.find((y) => y.symbol === x.symbol)?.name || '';
    const label = `${securityName} - ${x.eventDate}.`;
    return <Chip key={x.id} label={label} color={getChipColor(x.trend)} onClick={() => openDialog(x)} />;
  });

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -14,
      top: 9,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px'
    }
  }));

  const numberOfFundamentals = periodChips.length + eventChips.length;

  const initialExpanded: boolean = numberOfFundamentals > 0;
  const [expanded, setExpanded] = useState(initialExpanded);

  return (
    <>
      <Accordion disableGutters={true} expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Box>
            <Typography variant="h6">Historical Good Months</Typography>
            <Typography variant="caption" component="div">
              <StyledBadge badgeContent={numberOfFundamentals} color="primary">
                <Box sx={{ color: (theme) => theme.palette.primary.main, display: 'inline-block' }}>{getCurrentPeriod()}</Box>
              </StyledBadge>
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            {periodChips.length > 0 && (
              <Box>
                <Typography sx={{ display: 'inline-block', mb: 1 }} variant="caption">
                  PERIOD NOTES
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>{periodChips}</Box>
              </Box>
            )}
            {eventChips.length > 0 && (
              <Box>
                <Typography sx={{ display: 'inline-block', mb: 1 }} variant="caption">
                  EVENT NOTES
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>{eventChips}</Box>
              </Box>
            )}
            {numberOfFundamentals === 0 && <Typography fontSize="smaller">No notes for current period..</Typography>}
          </Box>
        </AccordionDetails>
      </Accordion>
      {selectedSecurity && (
        <FundamentalNoteDialog security={selectedSecurity} isOpen={showEditDialog} onClose={closeDialog} note={selectedNote} />
      )}
    </>
  );
};

export default SeasonalAccordion;
