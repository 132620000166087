import { FunctionComponent, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Card, Chip, Divider, Tooltip, Typography } from '@mui/material';

import Spinner from '../../../../components/ui/Spinner';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { removePortfolioContractAsync } from '../../../../store/portfolio/service';
import { ContractDetails, Portfolio } from '../../../../types/entities';

import AddPortfolioContractDialog from './AddPortfolioContractDialog';

interface Props {
  portfolio: Portfolio;
}

const PortfolioContractsPanel: FunctionComponent<Props> = ({ portfolio }: Props) => {
  const { securities } = useAppSelector((gs) => gs.securityState);
  const { loading } = useAppSelector((gs) => gs.portfolioState);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const closeDialog = () => setShowEditDialog(false);

  const dispatch = useAppDispatch();
  const handleRemoveContract = (details: ContractDetails) => {
    dispatch(removePortfolioContractAsync(portfolio, details));
  };

  const getLabel = (details: ContractDetails) => {
    const security = securities.find((x) => x.symbol === details.contract.symbol);
    return `${security?.name || details.contract.symbol} - ${details.contract.localSymbol}`;
  };

  const portfolioContracts = useMemo(() => {
    return portfolio.contracts.map((x) => <Chip key={x.contract.conId} label={getLabel(x)} onDelete={() => handleRemoveContract(x)} />);
  }, [portfolio, loading]);

  return (
    <>
      <Card>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', m: 2 }}>
          <Box>
            <Typography variant="h6" component="div">
              {portfolio.name}
            </Typography>
            <Typography variant="body2">Contracts</Typography>
          </Box>

          <Tooltip title="Add Contract" placement="left-start">
            <Button size="small" color="primary" variant="outlined" onClick={() => setShowEditDialog(true)}>
              <AddIcon />
            </Button>
          </Tooltip>
        </Box>
        <Divider />
        <Box sx={{ display: 'flex', gap: 2, m: 2, flexWrap: 'wrap' }}>
          {portfolio.contracts.length === 0 && (
            <Typography fontSize="smaller" component="div">
              No contracts added..
            </Typography>
          )}
          {portfolioContracts}
        </Box>
      </Card>
      <Spinner loading={loading} />
      {showEditDialog && <AddPortfolioContractDialog portfolio={portfolio} isOpen={showEditDialog} onClose={closeDialog} />}
    </>
  );
};

export default PortfolioContractsPanel;
