import { Position } from '../../types/entities';

export interface PositionState {
  loading: boolean;
  loaded: boolean;
  error?: string;
  positions: Position[];
}

export const initialPositionState: PositionState = {
  loading: false,
  loaded: false,
  error: undefined,
  positions: []
};
