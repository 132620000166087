import { AccountInfo } from '../../types/entities';

export interface AccountInfoState {
  loading: boolean;
  loaded: boolean;
  error?: string;
  accountInfo: AccountInfo | undefined;
}

export const initialAccountInfoState: AccountInfoState = {
  loading: false,
  loaded: false,
  error: undefined,
  accountInfo: undefined
};
