import { FunctionComponent } from 'react';

import EqualizerIcon from '@mui/icons-material/Equalizer';
import { IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Position } from '../../types/entities';

interface Props {
  position: Position;
}

const GotoWatchBoardIconButton: FunctionComponent<Props> = ({ position }: Props) => {
  const isUnderlying = position.secType === 'FUT' || position.secType === 'STK';
  const conId = isUnderlying ? position.conId : position.underConId;

  const navigate = useNavigate();

  const handleGotoWatchBoardClick = () => {
    navigate(`/?tab=browse&symbol=${position.symbol}&conid=${conId}`);
  };

  return (
    <Tooltip title="Watchboard" placement="top">
      <IconButton size="small" onClick={handleGotoWatchBoardClick} sx={{ color: (theme) => theme.palette.grey[200] }}>
        <EqualizerIcon />
      </IconButton>
    </Tooltip>
  );
};

export default GotoWatchBoardIconButton;
