import { ContractDetails } from '../../types/entities';

import { SecurityContractState, ContractsByUnderlyingSymbolState, initialContractsByUnderlyingSymbolState } from './state';

export const getContractsByUnderlyingSymbolState = (
  securityContractState: SecurityContractState,
  symbol: string
): ContractsByUnderlyingSymbolState => {
  return securityContractState[symbol] || { ...initialContractsByUnderlyingSymbolState, symbol };
};

export const getSecurityContracts = (securityContractState: SecurityContractState, symbol: string): ContractDetails[] => {
  return getContractsByUnderlyingSymbolState(securityContractState, symbol).contracts;
};

export const isSecurityContractsLoaded = (securityContractState: SecurityContractState, symbol: string | undefined): boolean => {
  if (!symbol) {
    return true;
  }
  return getContractsByUnderlyingSymbolState(securityContractState, symbol).loaded;
};

export const isSecurityContractsLoading = (securityContractState: SecurityContractState, symbol: string | undefined): boolean => {
  if (!symbol) {
    return false;
  }
  return getContractsByUnderlyingSymbolState(securityContractState, symbol).loading;
};

export const getSecurityContractError = (securityContractState: SecurityContractState, symbol: string | undefined): string | undefined => {
  if (!symbol) {
    return undefined;
  }
  return getContractsByUnderlyingSymbolState(securityContractState, symbol).error;
};
