import { Contract } from '../../types/entities';
import { useAppSelector } from '../hooks';

import { getOrderSummaryBySymbolState } from './selectors';

export const useOrderSummaryStore = (contract: Contract) => {
  const orderSummaryState = useAppSelector((x) => x.orderSummaryState);
  const { orders } = getOrderSummaryBySymbolState(orderSummaryState, contract.symbol);
  const orderExists = orders.find((x) => x.conId === contract.conId) !== undefined;

  return {
    orders,
    orderExists
  };
};
