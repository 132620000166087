import { FunctionComponent } from 'react';

import { Box, Divider, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import EditPositionIconButton from '../../../../../components/ui/EditPositionIconButton';
import GotoWatchBoardIconButton from '../../../../../components/ui/GotoWatchBoardIconButton';
import ShowGraphIconButton from '../../../../../components/ui/ShowGraphIconButton';
import VerticalSortIconButton from '../../../../../components/ui/VerticalSortIconButton';
import { Position } from '../../../../../types/entities';

import { getDetails } from './utils';

interface Props {
  position: Position;
  isPositionClosed: boolean;
}

const PositionHeader: FunctionComponent<Props> = ({ position, isPositionClosed }) => {
  const details = getDetails(position, isPositionClosed);

  return (
    <>
      <Box sx={{ mx: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'stretch', gap: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Link style={{ textDecoration: 'none' }} to={`/order-summary/${position.symbol}?origin=dashboard`}>
            <Typography variant="caption" sx={{ color: (theme) => theme.palette.info.light }} component="div">
              {position.symbol}
            </Typography>
          </Link>
          <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
          <Typography variant="caption">{position.secType}</Typography>
          <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
          {details}
          <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
          <Typography fontSize={12} fontFamily="monospace">
            {position.size > 0 ? '+' : ''}
            {position.size}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <ShowGraphIconButton position={position} />
          <GotoWatchBoardIconButton position={position} />
          <Divider sx={{ mx: 0.5 }} orientation="vertical" flexItem />
          <VerticalSortIconButton position={position} direction="up" />
          <VerticalSortIconButton position={position} direction="down" />
          <EditPositionIconButton position={position} />
        </Box>
      </Box>
    </>
  );
};

export default PositionHeader;
