import { FunctionComponent } from 'react';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { useAppSelector } from '../../../../store/hooks';
import { ContractDetails } from '../../../../types/entities';
import { formatNum } from '../../../../utils/currency-utils';
import { getExpirationDate } from '../../../../utils/datetime-utils';

const OptionOrderTable: FunctionComponent = () => {
  const orderUnderlyingState = useAppSelector((gs) => gs.orderUnderlyingState);
  const { marketPrice, entryPrice, orderSize, orderType, orderAction, entryOrderReport: placeOrderReport } = orderUnderlyingState;
  const contractDetails = orderUnderlyingState.contractDetails as ContractDetails;
  const contract = contractDetails.contract;

  const contractMonth = contractDetails.contractMonth || 'N/A';
  const expiresDate = getExpirationDate(contractDetails);
  const formattedExpiresDate = expiresDate ? expiresDate.toLocaleDateString() : 'N/A';

  const realizedOrderSize = placeOrderReport ? `${placeOrderReport.filled}` : '-';

  return (
    <Box>
      <Typography variant="caption">CONTRACT</Typography>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Symbol</TableCell>
              <TableCell>Local Symbol</TableCell>
              <TableCell align="center">Sec Type</TableCell>
              <TableCell align="right">Multiplier</TableCell>
              <TableCell align="right">Contract Month</TableCell>
              <TableCell align="right">Expires</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{contract.symbol}</TableCell>
              <TableCell>{contract.localSymbol}</TableCell>
              <TableCell align="center">{contract.secType}</TableCell>
              <TableCell align="right">{contract.multiplier}</TableCell>
              <TableCell align="right">{contractMonth}</TableCell>
              <TableCell align="right">{formattedExpiresDate}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer sx={{ mt: 3 }}>
        <Typography variant="caption">ORDER</Typography>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Action</TableCell>
              <TableCell align="center">Order Type</TableCell>
              <TableCell align="right">Market Price</TableCell>
              <TableCell align="right">Entry Price</TableCell>
              <TableCell align="right">Order Size</TableCell>
              <TableCell align="right">Filled Size</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ whiteSpace: 'nowrap' }}>
              <TableCell>{orderAction}</TableCell>
              <TableCell align="center">{orderType}</TableCell>
              <TableCell align="right">${formatNum(marketPrice)}</TableCell>
              <TableCell align="right">${formatNum(entryPrice)}</TableCell>
              <TableCell align="right">{orderSize}</TableCell>
              <TableCell align="right">{realizedOrderSize}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OptionOrderTable;
