import { toast } from 'react-toastify';

import { AppDispatch } from '..';
import { fetchSecurityContracts } from '../../api';
import { Security } from '../../types/entities';
import { Logger } from '../../utils/Logger';

import { success, fail, loading } from './reducer';

export const getSecurityContractsAsync = (params: Security) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading({ symbol: params.symbol, loading: true }));
    fetchSecurityContracts(params)
      .then((response) => {
        const detailsList = response.data;
        Logger.log(detailsList);
        dispatch(success({ symbol: params.symbol, futures: detailsList }));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail({ symbol: params.symbol, error: msg }));
        toast.error(msg);
      });
  };
};
