import { rest } from 'msw';

import optionChainResult from './option-chain.json';
import underlyingContractResult from './underlying-contract.json';

/// <summary>
/// <param name="req">an information about a matching request</param>
/// <param name="res">a functional utility to create the mocked response</param>
/// <param name="ctx">a group of functions that help to set a status code, headers, body, etc. of the mocked response.</param>
/// </summary>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const postUnderlyingContractHandler = async (_req: any, res: any, ctx: any) => {
  return res(ctx.status(200), ctx.json(underlyingContractResult));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const postOptionChainHandler = async (_req: any, res: any, ctx: any) => {
  return res(ctx.status(200), ctx.json(optionChainResult));
};

export const optionChainHandlers = [
  rest.post(`${process.env.REACT_APP_SERVER_URL}/api/broker/underlying-contract`, postUnderlyingContractHandler),
  rest.post(`${process.env.REACT_APP_SERVER_URL}/api/broker/option-chain`, postOptionChainHandler)
];
