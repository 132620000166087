import { ContractDetails, Position } from '../../types/entities';

export interface OrderState {
  loading: boolean;
  loaded: boolean;
  error?: string;
  step: number;
  direction?: 'FORWARD' | 'BACKWARD';
  details: ContractDetails | undefined;
  orders: Position[];
}

export const initialOrderState: OrderState = {
  loading: false,
  loaded: false,
  error: undefined,
  step: 0,
  details: undefined,
  orders: []
};
