import { HistoricalDataState, HistoricalDataStateByKey, initialHistoricalDataStateByKey } from './state';
import { getKey } from './utils';

export const getHistoricalDataStateByKey = (
  barChartId: number,
  conId: number,
  historicalDataState: HistoricalDataState
): HistoricalDataStateByKey => {
  const key = getKey({ id: barChartId, conId });
  return historicalDataState[key] || initialHistoricalDataStateByKey;
};
