import React, { FunctionComponent } from 'react';

import bearRacerIcon from '../../../assets/svg/bear-racer2.svg';
import bullRacerIcon from '../../../assets/svg/bull-racer2.svg';
import raceCarGrayIcon from '../../../assets/svg/race-car-gray.svg';
import raceCarGreenIcon from '../../../assets/svg/race-car-green.svg';
import raceCarRedIcon from '../../../assets/svg/race-car-red.svg';
import raceCarYellowIcon from '../../../assets/svg/race-car-yellow.svg';
import startIcon from '../../../assets/svg/start.svg';
import trophyIcon from '../../../assets/svg/trophy.svg';
import { Logger } from '../../../utils/Logger';

import './styles.scss';

export interface RaceSetup {
  lossAreaPct: number;
  durationPct?: number;
  slippageStartPct?: number;
  slippageEndPct?: number;
  stopLossPct?: number;
}

export type RaceDriverIcon = 'bull' | 'bear' | 'car-red' | 'car-green' | 'car-yellow' | 'car-gray';

export interface RaceDriver {
  name: string;
  icon: RaceDriverIcon;
  progressPct: number;
}

interface Props {
  setup: RaceSetup;
  drivers: RaceDriver[];
  onClick?: (id: string) => void;
}

const RaceTrack: FunctionComponent<Props> = ({ setup, drivers, onClick }: Props) => {
  const handleVehicleClick = (driver: RaceDriver) => {
    Logger.log(driver.name);
    if (onClick) {
      onClick(driver.name);
    }
  };

  const getRaceDriverIcon = (driver: RaceDriver) => {
    switch (driver.icon) {
      case 'bull':
        return bullRacerIcon;
      case 'bear':
        return bearRacerIcon;
      case 'car-green':
        return raceCarGreenIcon;
      case 'car-red':
        return raceCarRedIcon;
      case 'car-yellow':
        return raceCarYellowIcon;
      case 'car-gray':
        return raceCarGrayIcon;
    }
  };

  const vehicles = drivers.map((driver, i) => {
    return (
      <div key={driver.name} className="racer" style={{ marginTop: i * 2, marginLeft: '-32px', left: driver.progressPct + '%' }}>
        <img
          src={getRaceDriverIcon(driver)}
          width="32px"
          alt={driver.icon}
          title={driver.name}
          onClick={() => handleVehicleClick(driver)}
        />
      </div>
    );
  });

  const hasSlippage = !!setup.slippageStartPct && !!setup.slippageEndPct;

  return (
    <div className="racetrack-container">
      <div className="body">
        <div className="inner-body">
          <div className="start" style={{ paddingLeft: setup.lossAreaPct + '%' }}>
            <img src={startIcon} width="28px" alt="start" />
          </div>

          <div className="finish">
            <img src={trophyIcon} width="28px" alt="finish" />
          </div>

          {vehicles}

          {setup.durationPct && <div className="timer" style={{ width: setup.durationPct + '%' }}></div>}

          <div className="profit" style={{ marginLeft: setup.lossAreaPct + '%', width: 100 - setup.lossAreaPct + '%' }}></div>

          {hasSlippage && (
            <div className="slippage" style={{ marginLeft: setup.slippageStartPct + '%', width: setup.slippageEndPct + '%' }}></div>
          )}

          <div className="loss"></div>

          {setup.stopLossPct !== undefined && <div className="stop-loss" style={{ width: setup.stopLossPct + '%' }}></div>}
        </div>
      </div>
    </div>
  );
};

export default RaceTrack;
