import React from 'react';

import { Box } from '@mui/material';

interface TabPanelProps {
  sx?: object;
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function CustomTabPanel(props: TabPanelProps) {
  const { sx, children, value, index, ...other } = props;

  return (
    <Box
      sx={sx}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Box>
  );
}
