import React, { FunctionComponent } from 'react';

import { Box, Tab, Tabs } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CustomTabPanel } from '../../components/ui/CustomTabPanel';
import ResponsiveContainer from '../../components/ui/ResponsiveContainer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setSelectedDashboardTab } from '../../store/user-settings/reducer';

import BrowseTab from './BrowseTab';
import PortfoliosTab from './PortfoliosTab';
import PositionsTab from './PositionsTab';

const Dashboard: FunctionComponent = () => {
  const toTabIndex = (tabValue: string) => {
    switch (tabValue.toLowerCase()) {
      case 'browse':
        return 1;
      case 'portfolios':
        return 2;
      default:
        return 0;
    }
  };

  const fromTabIndex = (index: number) => {
    switch (index) {
      case 1:
        return 'browse';
      case 2:
        return 'portfolios';
      default:
        return 'positions';
    }
  };

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selectedParamsTab = searchParams.get('tab') ?? '';
  const { selectedDashboardTab: selectedStateTab } = useAppSelector((gs) => gs.userSettingsState);
  const selectedTab = selectedParamsTab || selectedStateTab;
  const tabIndex = toTabIndex(selectedTab);

  const dispatch = useAppDispatch();
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    const tabValue = fromTabIndex(newValue);
    dispatch(setSelectedDashboardTab(tabValue));
    navigate(`?tab=${tabValue}`);
  };

  return (
    <Box sx={{ mt: 2 }}>
      {/* <Container>
        <Typography variant="h4" sx={{ my: 3 }}>
          Dashboard
        </Typography>
      </Container> */}
      <ResponsiveContainer>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabIndex}
              onChange={handleChange}
              sx={{
                '& .MuiTabs-flexContainer': {
                  flexWrap: 'wrap'
                }
              }}
            >
              <Tab label="Positions" />
              <Tab label="Browse" />
              <Tab label="Portfolios" />
            </Tabs>
          </Box>
        </Box>
      </ResponsiveContainer>
      <ResponsiveContainer>
        <CustomTabPanel value={tabIndex} index={0}>
          <PositionsTab />
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={1}>
          <BrowseTab />
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={2}>
          <PortfoliosTab />
        </CustomTabPanel>
      </ResponsiveContainer>
    </Box>
  );
};

export default Dashboard;
