import { PositionGroup } from '../../../types/entities';
import { Logger } from '../../../utils/Logger';

/* eslint-disable no-console */
const getRandomPercent = () => {
  return Math.floor(Math.random() * 101); // Generates a number between 0 and 100 (inclusive)
};

const getPriceAction = (price: number, biasPct: number, volatilityPct: number, stockPriceChangePct: number, useBiasRange: boolean) => {
  // meaning: change in stock price will normalize the bias pct
  if (useBiasRange) {
    biasPct -= stockPriceChangePct;
  }

  let fluctuationAmount = (price * volatilityPct) / 100;

  const priceActionDirectionPct = getRandomPercent();
  const isIncreasing = priceActionDirectionPct < biasPct;
  if (!isIncreasing) {
    fluctuationAmount *= -1;
  }
  price += fluctuationAmount;
  // console.log('>>> ', biasPct, stockPriceChangePct, price);
  return price;
};

export interface AvgPosition {
  entryPrice: number;
  exitPrice?: number | undefined;
  pnl: number;
  startTurn: number;
  endTurn: number;
  turns: number;
  multiplier: number;
}

export interface AveragingResult {
  positions: AvgPosition[];
  sumPnl: number;
  numAvgUp: number;
  numAvgDown: number;
  totalSize: number;
  turns: number;
}

export const testAveraging = (group: PositionGroup, useSimpleAvg: boolean, useBiasRange: boolean): AveragingResult => {
  const {
    initialMarketPrice,
    initialSize,
    maxSize,
    trendBiasPct,
    volatilityPct,
    avgUpChangePct,
    avgDownChangePct,
    avgSizeChange,
    avgMultiplier,
    avgMaxTurns
  } = group;

  let turns = 0;
  let numAvgUp = 0;
  let numAvgDown = 0;
  let priceAction = initialMarketPrice;
  const positions: AvgPosition[] = [];
  const simpleAvgArrPrices: number[] = [];

  if (initialSize > 0) {
    for (let i = 0; i < initialSize; i++) {
      positions.push({
        entryPrice: initialMarketPrice,
        pnl: 0,
        startTurn: 0,
        endTurn: 0,
        turns: 0,
        multiplier: avgMultiplier
      });
    }
  }

  simpleAvgArrPrices.push(initialMarketPrice);

  const ONE_BILLION_TURNS = 1000000;
  while (true) {
    turns++;
    if (turns >= ONE_BILLION_TURNS) {
      Logger.log('Too many turns: ' + turns);
      break;
    }

    const numOpenPositions = positions.filter((x) => !x.exitPrice).length;
    if (numOpenPositions === 0) {
      if (avgMaxTurns > 0) {
        if (turns >= avgMaxTurns) {
          break;
        }
      } else {
        break;
      }
    }

    const stockPriceChangePct = ((priceAction - initialMarketPrice) / initialMarketPrice) * 100;
    priceAction = getPriceAction(priceAction, trendBiasPct, volatilityPct, stockPriceChangePct, useBiasRange);
    if (priceAction <= 0) {
      break;
    }

    const entryPrices = positions.filter((x) => !x.exitPrice).map((x) => x.entryPrice);
    const exitPrices = positions.filter((x) => !!x.exitPrice).map((x) => x.exitPrice as number);
    const arr = useSimpleAvg ? simpleAvgArrPrices : entryPrices.concat(exitPrices);
    const average = arr.reduce((a, b) => a + b) / arr.length;

    const diff = priceAction - average;
    const diffPct = (diff / average) * 100;
    if (diffPct >= avgUpChangePct) {
      for (let i = 0; i < avgSizeChange; i++) {
        numAvgUp++;
        const openPosArr = positions.filter((x) => !x.exitPrice);
        const minEntryPriceValue = Math.min(...openPosArr.map((x) => x.entryPrice));
        const pos = openPosArr.find((x) => x.entryPrice === minEntryPriceValue);
        if (pos) {
          pos.endTurn = turns;
          pos.turns = turns - pos.startTurn;
          pos.exitPrice = priceAction;
          pos.pnl = (priceAction - pos.entryPrice) * pos.multiplier;

          // console.log('*** CLOSE ***', 'diff-pct: ' + diffPct.toFixed(1));
          // console.log(pos);
          simpleAvgArrPrices.push(priceAction);
        }
      }
    } else if (diffPct <= avgDownChangePct * -1 && numOpenPositions < maxSize) {
      for (let i = 0; i < avgSizeChange; i++) {
        numAvgDown++;
        const pos = {
          entryPrice: priceAction,
          pnl: 0,
          startTurn: turns,
          endTurn: 0,
          turns: 0,
          multiplier: avgMultiplier
        };
        positions.push(pos);

        // console.log('*** OPEN ***', 'diff-pct: ' + diffPct.toFixed(1));
        // console.log(pos);
        simpleAvgArrPrices.push(priceAction);
      }
    }
  }

  const sum = positions.map((x) => x.pnl).reduce((a, b) => a + b, 0);
  const sumTrimmed = Math.round(sum);

  return {
    positions,
    sumPnl: sumTrimmed,
    numAvgUp,
    numAvgDown,
    totalSize: positions.length,
    turns
  };
};
