import { ContractMarketData } from '../../types/entities';

export interface MarketDataStateByConId {
  loading: boolean;
  loaded: boolean;
  error?: string;
  marketData: ContractMarketData | undefined;
}

export const initialMarketDataByConId: MarketDataStateByConId = {
  loading: false,
  loaded: false,
  error: undefined,
  marketData: undefined
};

export interface MarketDataState {
  [conId: number]: MarketDataStateByConId;
}

export const initialMarketDataState: MarketDataState = {};
