import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AuthToken } from '../../types/entities';

import { AuthState, initialAuthState } from './state';

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    reset: () => {
      return {
        ...initialAuthState
      };
    },
    setLoading: (state: AuthState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
      state.loaded = !action.payload;
    },
    success: (state: AuthState, action: PayloadAction<AuthToken>) => {
      state.error = undefined;
      state.loading = false;
      state.loaded = true;
      state.attempts = 0;
      state.token = action.payload.accessToken;
      state.accountId = Number(action.payload.userId);
      state.userName = action.payload.userName;
      state.firstName = action.payload.firstName;
      state.expires = action.payload.expires;
    },
    fail: (state: AuthState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.loaded = true;
      state.attempts = state.attempts + 1;
      state.token = undefined;
      state.accountId = undefined;
      state.userName = undefined;
      state.firstName = undefined;
    }
  }
});

const { actions, reducer } = authSlice;
export const { reset, success, fail, setLoading } = actions;
export default reducer;
