import { FunctionComponent, MouseEvent, useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Button, Card, Skeleton, Tooltip, Typography } from '@mui/material';

import PortfolioDialog from '../../../components/dialogs/PortfolioDialog';
import WatchBoard from '../../../components/panels/Watchboard';
import GutterBox from '../../../components/ui/GutterBox';
import PortfolioTabs from '../../../components/ui/PortfolioTabs';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { removePortfolioContractAsync } from '../../../store/portfolio/service';
import { setSelectedContractDetails } from '../../../store/user-settings/reducer';
import { ContractDetails } from '../../../types/entities';

const PortfoliosTab: FunctionComponent = () => {
  const { securities } = useAppSelector((gs) => gs.securityState);
  const { selectedPortfolio, selectedContractDetails } = useAppSelector((gs) => gs.userSettingsState);

  const [showEditDialog, setShowEditDialog] = useState(false);
  const closeDialog = () => setShowEditDialog(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSelectedContractDetails(undefined));
  }, [selectedPortfolio]);

  const handleSelectionChanged = (details: ContractDetails | undefined) => {
    dispatch(setSelectedContractDetails(details));
  };

  const selectedConId = selectedContractDetails?.contract?.conId;

  const { initialLoaded, portfolios } = useAppSelector((gs) => gs.portfolioState);
  const portfolio = portfolios.find((x) => x.id === selectedPortfolio?.id);

  const getLabel = (details: ContractDetails) => {
    const security = securities.find((x) => x.symbol === details.contract.symbol);
    return `${security?.name || details.contract.symbol} - ${details.contract.localSymbol}`;
  };

  const handleRemoveContract = (e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>, details: ContractDetails) => {
    e.stopPropagation();
    if (selectedPortfolio) {
      dispatch(removePortfolioContractAsync(selectedPortfolio, details));
    }
  };

  const handleAddNewPortfolioBtnClick = () => {
    setShowEditDialog(true);
  };

  const portfolioItems = useMemo(() => {
    if (!portfolio) {
      return undefined;
    }
    return portfolio.contracts.map((x) => {
      const conId = x.contract.conId;
      return (
        <Button
          key={conId}
          sx={{ pr: 1 }}
          onClick={() => handleSelectionChanged(x)}
          variant={conId === selectedConId ? 'contained' : 'outlined'}
        >
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}>
            {getLabel(x)} <CancelIcon onClick={(e) => handleRemoveContract(e, x)} />
          </Box>
        </Button>
      );
    });
  }, [portfolio, selectedConId]);

  return (
    <Box>
      <GutterBox sx={{ my: 3 }}>
        {/* <Typography variant="h5">Portfolios</Typography> */}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h5">Portfolios</Typography>
          <Tooltip title="Add News Portfolio" placement="left-start">
            <Button size="small" color="primary" variant="outlined" onClick={handleAddNewPortfolioBtnClick}>
              <AddIcon />
            </Button>
          </Tooltip>
        </Box>
      </GutterBox>
      <PortfolioTabs />
      <Card sx={{ p: 2 }}>
        {!initialLoaded ? (
          <Skeleton variant="rounded" animation="wave" height={80} />
        ) : (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {portfolios.length === 0 && (
              <Typography fontSize="smaller" component="div">
                No portfolios exists..
              </Typography>
            )}
            {portfolio?.contracts.length === 0 && (
              <Typography fontSize="smaller" component="div">
                No contracts added..
              </Typography>
            )}
            {portfolioItems}
          </Box>
        )}
      </Card>
      {selectedContractDetails && (
        <Box sx={{ mt: 2 }}>
          <WatchBoard details={selectedContractDetails} />
        </Box>
      )}
      {showEditDialog && <PortfolioDialog isOpen={showEditDialog} onClose={closeDialog} />}
    </Box>
  );
};

export default PortfoliosTab;
