import { TableContainer } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { INDICATOR_GREEN_COLOR, INDICATOR_RED_COLOR } from '../../../assets/colors';
import { IndicatorData } from '../../../types/entities';

interface Props {
  dayIndicators: IndicatorData;
  hourIndicators: IndicatorData;
}

export default function VolatilityTable({ dayIndicators, hourIndicators }: Props) {
  const {
    relativeStrengthIndexLongPeriod: rsi30days,
    relativeStrengthIndexShortPeriod: rsi8days,
    bollingerBandsLongPeriod: bb30days,
    bollingerBandsShortPeriod: bb8days,
    averageTrueRangeRatio: dayAtrRatio
  } = dayIndicators;
  const {
    relativeStrengthIndexLongPeriod: hourRsi,
    bollingerBandsLongPeriod: hourBb,
    averageTrueRangeRatio: hourAtrRatio
  } = hourIndicators;

  const rsi30daysColor = rsi30days >= 60 ? INDICATOR_GREEN_COLOR : rsi30days <= 40 ? INDICATOR_RED_COLOR : 'inherit';
  const rsi8daysColor = rsi8days >= 60 ? INDICATOR_GREEN_COLOR : rsi8days <= 40 ? INDICATOR_RED_COLOR : 'inherit';

  const bb30daysColor = bb30days >= 1.0 ? INDICATOR_GREEN_COLOR : bb30days <= -1.0 ? INDICATOR_RED_COLOR : 'inherit';
  const bb8daysColor = bb8days >= 1.0 ? INDICATOR_GREEN_COLOR : bb8days <= -1.0 ? INDICATOR_RED_COLOR : 'inherit';

  const dayMomentumRatio = !!dayAtrRatio ? dayAtrRatio.toFixed(2) : 'N/A';

  const hourRsiColor = hourRsi >= 60 ? INDICATOR_GREEN_COLOR : hourRsi <= 40 ? INDICATOR_RED_COLOR : 'inherit';
  const hourBbColor = hourBb >= 1.0 ? INDICATOR_GREEN_COLOR : hourBb <= -1.0 ? INDICATOR_RED_COLOR : 'inherit';
  const hourMomentumRatio = !!hourAtrRatio ? hourAtrRatio.toFixed(2) : 'N/A';

  return (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Indicator</TableCell>
            <TableCell align="right">Period</TableCell>
            <TableCell align="right">Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>ATR Ratio</TableCell>
            <TableCell align="right">30/8 Days</TableCell>
            <TableCell align="right">{dayMomentumRatio}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>RSI</TableCell>
            <TableCell align="right">30 Days</TableCell>
            <TableCell align="right" sx={{ color: rsi30daysColor }}>
              {rsi30days.toFixed(0)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>RSI</TableCell>
            <TableCell align="right">8 Days</TableCell>
            <TableCell align="right" sx={{ color: rsi8daysColor }}>
              {rsi8days.toFixed(0)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Bollinger</TableCell>
            <TableCell align="right">30 Days</TableCell>
            <TableCell align="right" sx={{ color: bb30daysColor }}>
              {bb30days.toFixed(2)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Bollinger</TableCell>
            <TableCell align="right">8 Days</TableCell>
            <TableCell align="right" sx={{ color: bb8daysColor }}>
              {bb8days.toFixed(2)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ATR Ratio</TableCell>
            <TableCell align="right" sx={{ color: 'lightblue' }}>
              30/8 Hours
            </TableCell>
            <TableCell align="right">{hourMomentumRatio}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>RSI</TableCell>
            <TableCell align="right" sx={{ color: 'lightblue' }}>
              30 Hours
            </TableCell>
            <TableCell align="right" sx={{ color: hourRsiColor }}>
              {hourRsi.toFixed(0)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Bollinger</TableCell>
            <TableCell align="right" sx={{ color: 'lightblue' }}>
              30 Hours
            </TableCell>
            <TableCell align="right" sx={{ color: hourBbColor }}>
              {hourBb.toFixed(2)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
