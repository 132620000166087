import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OptionChainFailedAction, OptionChainLoadingAction, OptionChainSuccessAction } from './actions';
import { OptionChainState, initialOptionChainState } from './state';
import { getOptionChainId } from './utils';

const optionChainSlice = createSlice({
  name: 'option-chain',
  initialState: initialOptionChainState,
  reducers: {
    reset: () => initialOptionChainState,
    loading: (state: OptionChainState, action: PayloadAction<OptionChainLoadingAction>) => {
      const id = getOptionChainId(action.payload);
      state[id] = {
        error: undefined,
        loading: action.payload.loading,
        loaded: !action.payload.loading,
        options: state[id]?.options || []
      };
    },
    success: (state: OptionChainState, action: PayloadAction<OptionChainSuccessAction>) => {
      const id = getOptionChainId(action.payload);
      state[id] = {
        error: undefined,
        loading: false,
        loaded: true,
        options: action.payload.options
      };
    },
    fail: (state: OptionChainState, action: PayloadAction<OptionChainFailedAction>) => {
      const id = getOptionChainId(action.payload);
      state[id] = {
        error: action.payload.error,
        loading: false,
        loaded: true,
        options: []
      };
    }
  }
});

const { actions, reducer } = optionChainSlice;
export const { reset, loading, success, fail } = actions;
export default reducer;
